import React, { useEffect, useRef, useState } from 'react';
import { appAlert } from '@utils/globalConstant';
import { InfoNotification } from '../InfoNotification';
import { Icons } from '../Utils/Icons';
import './FormDropdown.scss';

export function FormDropdown({
  id,
  type,
  label,
  startIcon,
  hint,
  invalid,
  invalidMessage,
  value: valueProp,
  validMessage,
  valid,
  placeholder,
  options = [
    {
      id: 0,
      label: 'Date'
    }
  ],

  onChange,
  onBlur = (() => { }),
  ...restProps
}) {
  // State & Refs
  const dropMenu = useRef(null);
  const [value, setValue] = useState(valueProp);

  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isOutsideClick, setIsOutsideClick] = useState(false);
  // Functions
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const triggerChange = (event) => {
    if (onChange) {
      onChange(event);
      onBlur({
        type: 'blur',
        target: {
          id: id,
          name: id,
          value: isBlur
        }
      });
    }
    setValue(event.target.value);
    setIsTouched(true);
    setIsBlur(true);
  };

  const placeholderText = () => {
    if (value) {
      const findValue = options.find((option) => option.id === value);
      if (findValue) {
        return findValue.label;
      }
      return placeholder;
    } else {
      return placeholder;
    }
  };

  const checkOutSideClick = (e) => {
    setIsOutsideClick(dropMenu.current && !dropMenu.current.contains(e.target));
  };

  // Hooks
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  useEffect(() => {
    if (isBlur || (isOutsideClick && isOpen)) {
      onBlur({
        type: 'blur',
        target: {
          id: id,
          name: id,
          value: isBlur
        }
      });
    }

    if (isOutsideClick && isOpen) {
      setIsOpen(false);
    }
  }, [isBlur, isOutsideClick, isOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', checkOutSideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutSideClick);
    };
  }, []);

  return (
    <div className="mb-3" ref={dropMenu}>
      {label && (
        <label htmlFor={id} className="form-label ps-5 ms-1">
          {label}
        </label>
      )}
      <div
        className={`
        input-group input-group-dropdown 
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}
        onClick={toggleOpen}
      >
        {startIcon && (
          <span className="input-group-text ps-6 pe-0">
            <Icons name={startIcon} />
          </span>
        )}
        <div className="form-control form-control-placeholder custom-placehoder-pl" style={{ color: value ? "#074D52" : "#83A6A8" }}>{placeholderText()}</div>
        <span className="input-group-text ps-0 px-6">
          <Icons name={isOpen ? 'arrowupmint' : 'arrowdownmint'} />
        </span>
      </div>
      {isOpen && (
        <div className="bg-white px-5 pt-3 pb-2 rounded-4 my-1">
          <div className="input-group-dropdown-list">
            {options.map((option, index) => (
              <div
                className={`input-group-dropdown-item py-2 px-4 my-2 me-5 ${value === option.id && 'bg-mint-100 rounded-pill text-white'
                  }`}
                key={index}
                onClick={() => {
                  triggerChange({
                    target: {
                      id: id,
                      name: id,
                      value: option.id
                    }
                  });
                  toggleOpen();
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
      {hint && !valid && !invalid && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification message={hint} />
        </div>
      )}
      {invalid && invalidMessage && (
        <div className="form-text mt-6px  ps-5">
          <InfoNotification type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
}

export default FormDropdown;
