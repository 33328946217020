import React from 'react';
import Icon from '@core/Utils/Icons';
import { appInfoBoxType } from '@utils/globalConstant';
import './InfoBox.scss';

export function InfoBox({ label, rightIcon, leftIcon, type = appInfoBoxType.DARK, onInfoClick }) {
  return (
    <div
      className={`d-flex align-items-center px-3 py-2 ${type} infobox-arrow shadow-xs`}
      role="button"
      aria-hidden
      onClick={onInfoClick}
    >
      <div>
        {leftIcon && <Icon className="info-support-icon" name={leftIcon} height={24} width={24} />}
      </div>
      <div className="px-3 flex-grow-1">{label && <div className="info-label">{label}</div>}</div>
      <div>
        {rightIcon && <Icon className="info-icon" name={rightIcon} height={24} width={24} />}
      </div>
    </div>
  );
}

export default InfoBox;
