/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
// import { Alert } from '@core/Utils';
// import { Loader } from '@utils/Icons/Loader';
// import { Header } from './Header';
// import { Footer } from './Footer';
import { Meta } from '@core/Meta';

export function withLayout(WrappedComponent) {
  // Wrap all of the components into one layout.

  function Layout(props) {
    const location = useLocation();
    const { t } = useStaticContent();

    if (
      location.pathname === '/activation' ||
      location.pathname === '/force-password-change' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/'
    ) {
      return (
        <main>
          <Meta title={t('nc_global_page_title')} />
          {/* <Loader /> */}
          {/* <Alert /> */}
          <WrappedComponent {...props} />
        </main>
      );
    }

    return (
      <>
        <Meta title={t('nc_global_page_title')} />
        {/* <Header /> */}
        {/* <main className="dashboard-bg">
          <Loader />
          <Alert /> */}
        <WrappedComponent {...props} />
        {/* </main> */}

        {/* <Footer /> */}
      </>
    );
  }

  return Layout;
}

export default withLayout;
