/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { appAlert } from '@utils/globalConstant';
import { Icons, Tooltip } from '@core/Utils/index';
import { InfoNotification } from '../InfoNotification';
import './FormInput.scss';

export function FormInput({
  id,
  type,
  label,
  startIcon,
  hint,
  invalid,
  invalidMessage,
  validMessage,
  valid,
  rightIcon,
  customClass = '',
  onChange,
  onBlur,
  showTooltip = 'false',
  tooltipTitle,
  disabled = false,
  ...restProps
}) {
  // State
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  // Functions
  const viewPassword = () => {
    setInputType('text');
    setShowPassword(true);
  };

  const hidePassword = () => {
    setInputType('password');
    setShowPassword(false);
  };

  const triggerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setIsTouched(true);
  };

  const triggerBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    // setIsFocus(false);

    setIsBlur(true);
    setIsFocus(false);
    setIsTouched(false);
  };
  // Hooks

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label ps-5 ms-1">
          {label}
        </label>
      )}
      <div
        className={`
        input-group
        ${valid ? 'valid' : ''}
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''}
        ${isFocus ? 'focus' : ''}
        ${isBlur ? 'blur' : ''}
        ${isTouched ? 'touched' : ''}
        ${disabled ? 'disabled' : ''}
        ${customClass}
      `}>
        {startIcon && (
          <span className={`input-group-text start-icon pe-0 ps-6 ${disabled ? 'bg-transparent' : ''}`}>
            <Icons name={startIcon} />
          </span>
        )}
        <input
          className={`form-control custom-placehoder-pl
            ${isFocus ? 'focus' : ''}
            ${isBlur ? 'blur' : ''}
            ${isTouched ? 'touched' : ''}`}
          {...{ id, type: inputType, ...restProps }}
          onBlur={triggerBlur}
          onFocus={() => setIsFocus(true)}
          onChange={triggerChange}
          disabled={disabled}
        />{' '}
        {rightIcon && (
          <>
            {showTooltip ? (
              <span className="input-group-text end-icon ps-0 pe-6">
                <Tooltip title={tooltipTitle}>
                  <Icons name={rightIcon} />
                </Tooltip>
              </span>
            ) : (
              <span className="input-group-text end-icon ps-0 pe-6">
                <Icons name={rightIcon} />
              </span>
            )}
          </>
        )}
        {type === 'password' && (
          <span
            className={`input-group-text end-icon ps-0 pe-6 ${showPassword ? 'd-block' : 'd-none'}`}
            role="button"
            aria-hidden
            onClick={hidePassword}>
            <Icons name="hide" />
          </span>
        )}
        {type === 'password' && (
          <span
            className={`input-group-text end-icon ps-0 pe-6 ${!showPassword ? 'd-block' : 'd-none'}`}
            role="button"
            aria-hidden
            onClick={viewPassword}>
            <Icons name="show" />
          </span>
        )}
      </div>
      {hint && !valid && !invalid && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification message={hint} />
        </div>
      )}
      {invalid && invalidMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
}

export default FormInput;
