import React from 'react';
import PropTypes from 'prop-types';

import BootstrapCard from 'react-bootstrap/Card';

export function CardLink({ sx, children }) {
  return <BootstrapCard.Link {...{ style: sx }}>{children}</BootstrapCard.Link>;
}

CardLink.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardLink.defaultProps = {
  sx: {}
};

export default CardLink;
