import React from 'react';
import './CounterButton.scss';

export function CounterButton({
  id,
  label,
  customClass = '',
  buttonType = '',
  onSelect,
  children,
  // ...restProps
}) {
  // Functions
  // const onClick = () => {
  //   if (onClickProp) {
  //     onClickProp(value);
  //   }
  // };

  return (
    <button type='button' className={`${buttonType} ${customClass} px-3`} onClick={() => onSelect(id)}>
      {label && <div className="btn-label py-5 px-0 px-sm-0">{label}</div>}
      {children && <div className="btn-children">{children}</div>}
    </button>
  );
}

export default CounterButton;
