import React from 'react';
import PropTypes from 'prop-types';
import errorIcon from '@theme/assets/img/error.svg';

export function InputFeedback({ id, isValid, isInvalid, feedBackMessage }) {
  const validClassName = isValid ? 'valid-feedback' : false;
  const inValidClassName = isInvalid ? 'invalid-feedback' : false;
  return (
    <div
      id={id}
      className={`d-block ${
        validClassName || inValidClassName ? validClassName || inValidClassName : 'feedback'
      }`}
    >
      {isInvalid && <img className="error-icon" src={errorIcon} alt="error_icon" />}
      {/* {isValid && <img className="error-icon" src={errorIcon} alt="error_icon" />} */}
      {feedBackMessage}
    </div>
  );
}

Text.propTypes = {
  id: PropTypes.string,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  feedBackMessage: PropTypes.string,
  isErrorIcon: PropTypes.bool
};

Text.defaultProps = {
  id: '',
  isValid: false,
  isInvalid: false,
  feedBackMessage: '',
  isErrorIcon: false
};

export default InputFeedback;
