import React from 'react';
import PropTypes from 'prop-types';

import BootstrapCard from 'react-bootstrap/Card';

export function CardFooter({ sx, children }) {
  return <BootstrapCard.Footer {...{ style: sx }}>{children}</BootstrapCard.Footer>;
}

CardFooter.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardFooter.defaultProps = {
  sx: {}
};

export default CardFooter;
