/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export function Radio({
  label,
  type,
  id,
  name,
  onChange,
  onClick,
  onBlur,
  value,
  isValid,
  isInvalid,
  isDisabled,
  isDefaultChecked,
  error
}) {
  return (
    <div className="form-check" onClick={onClick}>
      <input
        className={`form-check-input ${isValid ? 'is-valid' : ''} ${isInvalid ? 'is-invalid' : ''}`}
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onClick={onClick}
        checked={isDefaultChecked}
        disabled={isDisabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
      {isInvalid && error && <div className="d-block invalid-feedback">{error}</div>}
    </div>
  );
}
// <Form.Group as={Col} controlId={id} className="mb-3">
//   <Form.Check
//     {...{
//       type,
//       label,
//       name,
//       value,
//       onChange,
//       onClick,
//       onBlur,
//       isInvalid
//     }}
//     defaultChecked={isDefaultChecked}
//     disabled={isDisabled}
//   />
// </Form.Group>

Radio.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([undefined, null])
  ]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDefaultChecked: PropTypes.bool
};

Radio.defaultProps = {
  label: '',
  type: 'radio',
  id: '',
  name: '',
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  isValid: null,
  isInvalid: null,
  value: '',
  isDisabled: null,
  isDefaultChecked: null
};

export default Radio;
