/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BContainer from 'react-bootstrap/Container';
import BRow from 'react-bootstrap/Row';
import BCol from 'react-bootstrap/Col';

export function Container(props) {
  return <BContainer {...props} />;
}

export function Row(props) {
  return <BRow {...props} />;
}

export function Col(props) {
  return <BCol {...props} />;
}

export default Container;
