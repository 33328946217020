// Main App
// Initialize App with default landing page.
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Buffer } from 'buffer';

import { AppRoutes } from '@config/AppRoutes';
import { ContextManager } from '@config/ContextManager';
import { DebugLogs } from '@utils/log';

import { WebThemeProvider } from './theme';

// @ts-ignore
window.Buffer = Buffer;

function App() {
  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    // console.log(process.env.REACT_APP_DISABLE_LOGS);
    if (process.env.REACT_APP_DISABLE_LOGS === "true" || process.env.REACT_APP_DISABLE_LOGS === true) {
      DebugLogs(false);
    } else {
      DebugLogs(true);
    }
  }, []);

  return (
    <BrowserRouter>
      {/* <ThemeProvider theme={appTheme}> */}
      <WebThemeProvider>
        <ContextManager>
          <AppRoutes />
        </ContextManager>
      </WebThemeProvider>
      {/* </ThemeProvider> */}
    </BrowserRouter>
  );
}

export default App;
