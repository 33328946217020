/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export function DropDown({
  id,
  label,
  apiData,
  selectedItem,
  setSelectedItem,
  value,
  variant,
  name,
  size,
  onChange,
  error,
  isDisabled
}) {
  return (
    <>
      <div className="dropdown custom-dropdown">
        <button
          className={`btn dropdown-toggle w-100${isDisabled ? ' disabled' : ''}`}
          type="button"
          id={id}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ color: selectedItem.id ? 'black' : 'grey' }}
        >
          {selectedItem.id ? selectedItem.value : label}
        </button>{' '}
        <ul className="dropdown-menu" aria-labelledby={id}>
          {apiData.map((item) => (
            <li
              style={item.id === selectedItem.id ? { background: 'grey' } : { background: 'white' }}
              key={`${id}-dropdown-item-${item.id}`}
              // eventKey={item.id + 1}
              onClick={() => {
                setSelectedItem(item);
              }}
              value={item.id}
            >
              <div
                className="dropdown-item"
                style={item.id === selectedItem.id ? { color: 'white' } : { color: 'black' }}
              >
                {item.value}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <span className="text-danger">{error}</span>
    </>
  );
}
// <>
//   {label && <Form.Label>{label}</Form.Label>}
//   <Dropdown>
//     <Dropdown.Toggle
//       variant={variant}
//       value={value === undefined || value === null ? '' : selectedItem.id}
//       id={id}
//       size={size}
//       {...{
//         name,
//         onChange
//       }}
//       style={{ minWidth: '150px' }}>
//       {selectedItem.value}
//     </Dropdown.Toggle>

//     <Dropdown.Menu style={{ maxHeight: '150px', overflowY: 'scroll' }}>
//       {apiData.map((item) => (
//         <Dropdown.Item
//           style={
//             item.id === selectedItem.id
//               ? { background: 'grey', color: 'black' }
//               : { background: 'white', color: 'black' }
//           }
//           key={item.id}
//           eventKey={item.id + 1}
//           onClick={() => {
//             setSelectedItem(item);
//           }}
//           value={item.id}>
//           {item.value}
//         </Dropdown.Item>
//       ))}
//     </Dropdown.Menu>
//   </Dropdown>
//   {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
// </>

DropDown.propTypes = {
  label: PropTypes.string,
  apiData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.any,
    value: PropTypes.string
  }),
  setSelectedItem: PropTypes.func,
  value: PropTypes.shape({}),
  variant: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isDisabled: PropTypes.bool
};

DropDown.defaultProps = {
  label: '',
  selectedItem: {},
  setSelectedItem: () => {},
  value: {},
  variant: 'secondary',
  id: ``,
  name: '',
  error: '',
  isDisabled: '',
  size: 'sm',
  onChange: () => {}
};

export default DropDown;
