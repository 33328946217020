// import React from 'react';
import React from 'react';
import Icon from '@core/Utils/Icons';
import { appButtonTypes } from '@utils/globalConstant';
import { Modal } from '../Utils/Modal';
import { ButtonPrimary } from '../ButtonPrimary';
import Image from '@core/Utils/Image';

export function PopupSupport({
  icon,
  iconHeight = 64,
  iconWidth = 64,
  isOpen,
  title,
  leftButtonLabel,
  rightButtonLabel,
  onClose,
  bodyText,
  onRightClick,
  onLeftClick,
  isCloseButton = true,
  children,
  imgAlt,
  imgRef,
  resImgRefs,
  // imgDefaultStyle,
  // imgMobileStyle,
  // imgClassName
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCloseButton={isCloseButton}
      modalClass="popup-support">
      <div className="container-sm">
        <div className="row">
          <div className="mx-auto col-lg-8 px-6 px-sm-3">
            <div className="modal-main-content mx-auto">
              {icon && (
                <div className="modal-icon pt-8">
                  <Icon
                    className="modal-close-icon"
                    name={icon}
                    height={iconHeight}
                    width={iconWidth}
                  />
                </div>
              )}

              {/* TODO refacor after Image component is built */}
              {/* {imgSrc && <img src={imgSrc} width={imgWidth} height={imgHeight} alt={imgAlt} />} */}
              {imgRef && (
                <div className="pt-8">
                  <Image
                    refs={imgRef}
                    resRefs={resImgRefs}
                    alt={imgAlt}
                    // className={imgClassName}
                    // defaultStyle={imgDefaultStyle}
                    // mobileStyle={imgMobileStyle}
                  />
                </div>
              )}

              <div className="modal-header border-0">
                {title && (
                  <h3
                    className="mt-4 pt-4 modal-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}
              </div>
              <div className="modal-body pt-4">
                {bodyText && <p dangerouslySetInnerHTML={{ __html: bodyText }} />}
                {children}
              </div>
              {(leftButtonLabel || rightButtonLabel) && (
                <div className="d-flex justify-content-center modal-button mt-4 pt-4">
                  {leftButtonLabel && (
                    <div className="mx-4">
                      <ButtonPrimary
                        buttonType={appButtonTypes.PRIMARY.MINT}
                        label={leftButtonLabel || 'Chat'}
                        onClick={onLeftClick}
                      />
                    </div>
                  )}
                  {rightButtonLabel && (
                    <div className="mx-4">
                      <ButtonPrimary
                        buttonType={appButtonTypes.PRIMARY.DEFAULT}
                        label={rightButtonLabel || 'Button'}
                        onClick={onRightClick}
                        shadow={false}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopupSupport;
