import React, { useEffect, useState } from 'react';

import { useDocument } from '@context/MobileOne';
import Icon from '@core/Utils/Icons';

import './Player.scss';
import { useLayout } from '@context/Utils';

export function Player({ video }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showCloseAnimation, setShowCloseAnimation] = useState(false);

  // Contexts
  const { getVideoPreview } = useDocument();
  const { setShowFooter, setShowHeader } = useLayout();
  // Functions

  // Hooks
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setShowFooter(false);
      setShowHeader(false);
    } else {
      document.body.style.overflow = 'unset';
      setShowFooter(true);
      setShowHeader(true);
    }
    return () => {
      document.body.style.overflow = 'unset';
      setShowFooter(true);
      setShowHeader(true);
    };
  }, [isOpen]);

  useEffect(() => {
    if (showCloseAnimation) {
      setTimeout(() => {
        setIsOpen(false);
        setShowCloseAnimation(false);
      }, 500);
    }
  }, [showCloseAnimation]);

  return (
    <>
      <div className="bg-white rounded-2 h-100">
        <div className="d-flex p-3 w-100">
          <div className="w-auto text-start video-bg position-relative">
            <img
              className="ps-0 preview-image z-0 rounded-1"
              src={getVideoPreview(video?.previewImageRef)}
              alt=""
              srcSet=""
            />
            <div
              className="position-absolute z-1 w-100 h-100 d-flex align-items-center justify-content-center rounded-1"
              style={{
                background: 'rgba(7, 77, 82, 0.5)'
              }}>
              <button
                className="play-icon bg-transparent border-0"
                type="button"
                onClick={() => setIsOpen(true)}
                aria-label="play-icon">
                <Icon name="playwhite" />
              </button>
            </div>
          </div>
          <div className="text-gray-100 w-100 ps-3 d-flex align-content-between flex-wrap">
            <div className="">
              <p className="nc-realtextpro-footnote mb-0">{video?.videoCopy}</p>
            </div>
            <div className="w-100 d-flex justify-content-between align-items-end">
              <p className="nc-doomsday-footnote mb-0">{video?.name}</p>
              <p className="nc-doomsday-footnote mb-0">{video?.videoDuration}</p>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className={`min-vh-100 min-vw-100 bg-black position-fixed overflow-hidden start-0 top-0 animate__animated ${showCloseAnimation ? 'animate__fadeOutDown' : 'animate__fadeInUp z-3'
            }`}>
          {video && (
            <div className="vw-100 vh-100 my-auto d-flex flex-column align-items-center justify-content-center p-0">
              <div className="container-md container-page1 overflow-hidden">
                <div className="row">
                  <div className="col-lg-8 col-sm-12 col-sm-12 col-xs-12 mx-auto px-md-3 px-sm-0 px-0">
                    <div className="player-close-wrap d-flex justify-content-end w-100">
                      <button
                        className="player-close-icon px-6 border-0"
                        type="button"
                        onClick={() => setShowCloseAnimation(true)}
                        aria-label="close-icon">
                        <Icon name="close" />
                      </button>
                    </div>
                    <div className="video-player">
                      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                      <video
                        className="w-100 video-landscape"
                        controls
                        // controlsList="nofullscreen"
                        loop
                        autoPlay
                        playsInline // CHECK should only work on iOS and Safari // prevents fullscreen playback of videos
                        >
                        <source src={video?.media_url} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Player;
