/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@core/Utils/Tooltip';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import { InputFeedback } from './InputFeedback';
import './style.scss';

export function Text({
  label,
  labelCustomClass,
  inputCustomClass,
  toolTip,
  type,
  id,
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  isValid,
  isInvalid,
  isDisabled,
  error,
  isErrorIcon,
  help,
  maxLength,
  isEuroIcon,
  validMsg
}) {
  // State
  const [showPassword, setShowPassword] = useState(false);

  const inptutControl = (
    <div className={`mb-2 input-mb${isEuroIcon ? ' relative' : ''}`}>
      {label && (
        <label htmlFor={id} className={`form-label ${labelCustomClass}`}>
          {label}{' '}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
            </Tooltip>
          ) : (
            <></>
          )}{' '}
        </label>
      )}
      <input
        className={`form-control ${inputCustomClass}${isInvalid ? ' is-invalid' : ''}${
          isValid ? ' is-valid' : ''
        }${isEuroIcon ? ' text-right' : ''}`}
        type={type}
        aria-describedby={`${id}Block`}
        {...{
          name,
          placeholder,
          onChange,
          value: value === undefined || value === null ? '' : value,
          isValid,
          isInvalid,
          onBlur,
          maxLength
        }}
        disabled={isDisabled}
      />
      {isEuroIcon && (
        <span className="input-group-text">
          <i className="bi bi-currency-euro" />
        </span>
      )}
      {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}
      {error && (
        <InputFeedback id={id} isErrorIcon={isErrorIcon} isInvalid feedBackMessage={error} />
      )}
      {isValid && <InputFeedback isValid feedBackMessage={validMsg} />}
    </div>
  );

  const inputWithIcon = (
    <>
      {label && (
        <label className={`${labelCustomClass} form-label`} htmlFor={id}>
          {label}{' '}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
            </Tooltip>
          ) : (
            <></>
          )}{' '}
        </label>
      )}
      <div className="input-group mb-3">
        <input
          id={id}
          className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
            isValid ? 'is-valid' : ''
          } ${inputCustomClass}`}
          type={showPassword ? 'text' : type}
          aria-describedby={`${id}Block`}
          {...{
            name,
            placeholder,
            onChange,
            value: value === undefined || value === null ? '' : value,
            isValid,
            isInvalid,
            label,
            onBlur
          }}
          disabled={isDisabled}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye-fill" />}
        </span>
        {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}
        {error && (
          <InputFeedback id={id} isErrorIcon={isErrorIcon} isInvalid feedBackMessage={error} />
        )}
        {isValid && <InputFeedback isValid feedBackMessage={validMsg} />}
      </div>
    </>
  );

  if (type === 'password') {
    return inputWithIcon;
  }
  return inptutControl;
}

Text.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  labelCustomClass: PropTypes.string,
  inputCustomClass: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  validMsg: PropTypes.string,
  isErrorIcon: PropTypes.bool,
  isEuroIcon: PropTypes.bool,
  help: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number
};

Text.defaultProps = {
  label: '',
  type: 'text',
  labelCustomClass: '',
  inputCustomClass: '',
  id: '',
  placeholder: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  maxLength: '',
  isValid: null,
  isInvalid: null,
  isDisabled: null,
  error: '',
  validMsg: '',
  isErrorIcon: false,
  isEuroIcon: false,
  help: '',
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12
};

export default Text;
