import React from 'react';
import Icon from '@core/Utils/Icons';
import { appInfoBoxType } from '@utils/globalConstant';
import './InfoBoxToggle.scss';
import { ButtonToggle } from '../ButtonToggle';

export function InfoBoxToggle({
  labelTitle,
  label,
  leftIcon,
  type = appInfoBoxType.TOGGLE,
  onInfoClick
}) {
  return (
    <div
      className={`d-flex align-items-center px-3 py-2 ${type} infobox-arrow shadow-xs`}
      role="button"
      aria-hidden
      onClick={onInfoClick}>
      <div>
        {leftIcon && <Icon className="info-support-icon" name={leftIcon} height={24} width={24} />}
      </div>

      <div className="px-3 flex-grow-1">
        {labelTitle && label && (
          <div className="info-label">
            <b>{labelTitle}:</b> {label}
          </div>
        )}
        {!labelTitle && label && <div className="info-label">{label}</div>}
      </div>

      <div>
        <ButtonToggle />
      </div>
    </div>
  );
}

export default InfoBoxToggle;
