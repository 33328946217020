import React from 'react';
import { appImages } from '@utils/globalConstant';

export const Logo = ({ primaryLogo, maxHeight = '56px' }) => {
  return (
    <>
      <img
        style={{ maxWidth: '100%', maxHeight: maxHeight }}
        src={primaryLogo ? appImages.logoYellow : appImages.logoMint}
        alt="Logo"
      />
    </>
  );
};

export default Logo;
