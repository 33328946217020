import React from 'react';
import PropTypes from 'prop-types';

import BootstrapCard from 'react-bootstrap/Card';

export function CardTitle({ sx, children }) {
  return <BootstrapCard.Title {...{ style: sx }}>{children}</BootstrapCard.Title>;
}

CardTitle.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node
};

CardTitle.defaultProps = {
  sx: {},
  children: ''
};

export default CardTitle;
