import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { Col } from '@core/Layout/Container';

export function Checkbox({
  label,
  type,
  id,
  name,
  onChange,
  onBlur,
  value,
  isInvalid,
  isDisabled,
  isDefaultChecked
}) {
  return (
    <Form.Group as={Col} controlId={id} className="mb-3">
      <Form.Check
        {...{
          type,
          label,
          name,
          value,
          onChange,
          onBlur,
          isInvalid
        }}
        defaultChecked={isDefaultChecked}
        disabled={isDisabled}
      />
    </Form.Group>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDefaultChecked: PropTypes.bool
};

Checkbox.defaultProps = {
  label: '',
  type: 'checkbox',
  id: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  isInvalid: null,
  isDisabled: null,
  isDefaultChecked: null
};

export default Checkbox;
