import React, { useEffect, useState } from 'react';
import './ButtonToggle.scss';

export function ButtonToggle({
  id,
  label,
  value = false,
  shadow = true,
  onChange = () => { }
}) {
  // State
  const [isActive, setIsActive] = useState(value);

  // Hooks
  useEffect(() => {
    if (isActive !== value) {
      onChange(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (value !== isActive) {
      setIsActive(value);
    }
  }, [value]);

  return (
    <div className="toggle-switch d-flex align-items-center justify-content-center">
      <div className="toggle-switch-button">
        <button
          type="button"
          className={`btn btn-toggle ${isActive ? 'active' : 'inactive'} ${shadow ? 'shadow' : ''
            }}`}
          onClick={() => setIsActive(!isActive)} aria-label={id}>
          <div className="handle" />
        </button>
      </div>
      {label && <div className="toggle-switch-label ps-3 pt-2">{label}</div>}
    </div>
  );
}

export default ButtonToggle;
