import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@core/Utils/Icons';
import './style.scss';

export function Modal({ isOpen, isCloseButton = true, onClose: onCloseHandler, children, modalClass = '' }) {
  const modalRef = useRef(null);

  const onClose = () => {
    if (modalRef) {
      modalRef.current.classList.add('animate__animated', 'animate__fadeOutDown', 'animate__fast');
    }
    setTimeout(() => {
      onCloseHandler();
    }, 250);
  };

  // Hooks
  useEffect(() => {
    if (isOpen) {
      if (modalRef) {
        modalRef.current.classList.add('animate__animated', 'animate__fadeInUp', 'animate__fast');
      }
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return isOpen ? (
    <div className={`modal d-block ${modalClass} z-3`}>
      <div className="modal-dialog modal-dialog-scrollable modal-fullscreen" ref={modalRef}>
        <div className="modal-content rounded-top position-fixed bottom-0 pt-4 pb-10">
          <div className="modal-line mx-auto" />
          {isCloseButton &&
            <button
              type="button"
              className="btn close pb-2 position-absolute border-0"
              aria-label="Close"
              onClick={onClose}
            >
              <Icon className="modal-close-icon" name="closecolor" height={24} width={24} />
            </button>
          }
          {children}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  modalClass: PropTypes.string,
  onClose: PropTypes.func
};

Modal.defaultProps = {
  isOpen: false,
  modalClass: '',
  onClose: () => { }
};

export default Modal;
