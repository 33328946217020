import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import moment from 'moment';

import {
  simLockStatus,
  simLockStatusTitle,
  StatusCodes
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import {
  appAlert,
  appRegex,
  appRoute,
  appStaticContentConfig,
  appStorage,
  formValidation,
  generateUUID,
  inputValidation,
  validateDOB,
  // validateDate,
  validateLowerCase,
  validateNumber,
  validateSpecial,
  validateStringLength,
  validateUpperCase
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { deleteAllCookies } from '@utils/cookies';
import { Logout } from '@pages/Account/Logout';
// import { AppDownloadAlert } from '@part/AppDownloadAlert';
// import { LogoutAlert } from '@part/LogoutAlert';
import { useCustomer } from '../Customer';

export const AccountContext = createContext({});

export function AccountContextProvider({ children, config: { storage } }) {
  // Constants

  // Login initial value
  const loginInitialValue = {
    username: '',
    password: ''
  };

  const changePasswordInitialValue = {
    newPassword: '',
    confirmPassword: ''
  };

  const forgotPasswordInitialValue = {
    number: '',
    // puk: ''
    birthDate: ''
  };

  const cancelContractOptionalInitValue = {
    asap: '',
    tillDate: '',
    validUntil: '',
    extraOrdinary: '',
    terminationReason: ''
  };

  // API Data Storage States
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassRes, setForgotPassRes] = useState('');
  const [email, setEmail] = useState('');
  // const [domWithProduct, setDomWithProduct] = useState(null);
  const [lockStatus, setLockStatus] = useState();
  const [loginForm, setLoginFrom] = useState();
  const [changePasswordForm, setChangePasswordForm] = useState(changePasswordInitialValue);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(forgotPasswordInitialValue);
  const [welcomePopup, setWelcomePopup] = useState('');
  const [contactSuccessModal, setContactSuccessModal] = useState(false);
  const [hotlineSuccessModal, setHotlineSuccessModal] = useState(false);
  const [cancelContractForm, setCancelContractForm] = useState({});
  const [cancelContractRes, setCancelContractRes] = useState({ downloadLink: false });
  const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState('');
  const [forcePasswordSuccessModal, setForcePasswordSuccessModal] = useState(false);
  const [emailUniqueId, setEmailUniqueId] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [emailChangeStep, setEmailChangeStep] = useState(0);
  const [emailCodeError, setEmailCodeError] = useState(false);
  const [emailCodeErrorMsg, setEmailCodeErrorMsg] = useState(null);
  const [isCodeResend, setIsCodeResend] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [isHotlineChangeSuccess, setIsHotlineChangeSuccess] = useState(false);
  const [isEmailChangeSuccess, setIsEmailChangeSuccess] = useState(false);
  const [isContactChangeSuccess, setIsContactChangeSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [preferenceSuccessModal, setPreferenceSuccessModal] = useState(false);
  const [preferenceAlert, setPreferenceAlert] = useState(false);
  const [isLoginSubmit, setIsLoginSubmit] = useState(false);
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);
  const [isForgetPassClicked, setIsForgetPassClicked] = useState(false);
  const [pdfDownloadSuccess, setPdfDownloadSuccess] = useState(false);

  // Context
  const navigate = useNavigate();
  const { showAlert, setIsGenericError } = useAlert();
  const {
    onChangeCustomerDataCall,
    onChangePasswordCall,
    onSimLock,
    onUnSimLock,
    onCustomerFogotPasswordCall,
    onTwoFactorAuthPinCall,
    onTwoFactorAuthVerificationCall,
    onChangeOptinFlagsCall,
    domWithProductData
  } = useMobileOne();
  const {
    customerData,
    personalData: { alternatePhoneNumber = {} },
    personalData,
    customerProducts,
    getCustomerData,
    // onDOMWithProduct,
    logoutPopupImage
  } = useCustomer();
  const { onPasswordLogin, setUserLogin, onLogout, isUserLoggedIn } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const { t, onContractTerminationCall } = useStaticContent();

  const hotlinePasswordInitialValue = {
    pswrdForHtLine: customerData ? customerData?.hotlinePassword : ''
  };

  const changeEmailInitvalue = {
    email: '',
    // isEmailDiff: 'NO',
    // confirmEmail: '',
    // emailAddress: '',
    emailCode: ''
  };

  const contactNumberInitValue = {
    alternateNumberPrefix: alternatePhoneNumber?.prefix || '',
    alternateNumber: alternatePhoneNumber?.number || ''
  };

  const preferencesInitValue = {
    brandPartnerCustomMarketing:
      domWithProductData?.personalData?.flags?.brandPartnerCustomMarketing || false,
    marketingMultibrand: domWithProductData?.personalData?.flags?.marketingMultibrand || false,
    thirdParty: domWithProductData?.personalData?.flags?.thirdParty || false
  };

  const [changeEmailForm, setChangeEmailForm] = useState(changeEmailInitvalue);
  const [hotlinePasswordForm, setHotlinePasswordForm] = useState(hotlinePasswordInitialValue);

  // VALIDATION

  const cancelContractValidation = yup.lazy((values) => {
    if (values.asap === 'false' && values.extraOrdinary === 'YES') {
      return yup.object().shape({
        validUntil: yup.date().required(t('nc_serv_cnl_contract_input_err')),
        // .max(validateDate(values.validUntil), t('nc_serv_cnl_contract_input_err')),
        asap: formValidation({
          required: t('please_select_option')
        }),
        extraOrdinary: formValidation({
          required: t('please_select_option')
        }),
        terminationReason: formValidation({
          required: t('please_enter_reason')
        })
      });
    }
    if (values.asap === 'false') {
      return yup.object().shape({
        validUntil: yup.date().required(t('nc_serv_cnl_contract_input_err')),
        // .max(validateDate(values.validUntil), t('nc_serv_cnl_contract_input_err')),
        asap: formValidation({
          required: t('please_select_option')
        })
      });
    }
    if (values.extraOrdinary === 'YES') {
      return yup.object().shape({
        extraOrdinary: formValidation({
          required: t('please_select_option')
        }),
        terminationReason: formValidation({
          required: t('please_enter_reason')
        })
      });
    }
    return yup.object().shape({
      asap: formValidation({
        required: t('please_select_option')
      }),
      extraOrdinary: formValidation({
        required: t('please_select_option')
      })
    });
  });

  const validateContactNumber = yup.object().shape({
    alternateNumberPrefix: formValidation({
      required: t('nc_acc_data_chcontact_ input_prefix_err'),
      regex: /^.{1,6}$/,
      validErrorMessage: t('nc_acc_data_chcontact_ input_prefix_err')
    }),
    alternateNumber: formValidation({
      required: t('nc_acc_data_chcontact_ input_number_err'),
      regex: /^[0-9]{4,15}$/,
      validErrorMessage: t('nc_acc_data_chcontact_ input_number_err')
    })
  });

  const validateEmail = yup.object().shape({
    email: formValidation({
      required: t('nc_acc_data_chmail_ input_mail_err'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('nc_acc_data_chmail_ input_mail_err')
    })
  });

  const hotlinePasswordValidation = yup.object().shape({
    pswrdForHtLine: formValidation({
      required: t('nc_acc_data_chpin_input_pin_err'),
      regex: /^[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}$/,
      validErrorMessage: t('nc_acc_data_chpin_input_pin_err')
    })
  });

  const changePasswordValidation = yup.object().shape({
    oldPassword: formValidation({
      required: t('ek_change-pw_h1_error-msg')
    })
  });

  const validatePwd = (password) => {
    const pattern =
      t('nc_password_regex') !== 'nc_password_regex'
        ? new RegExp(`${t('nc_password_regex')}`)
        : appRegex.validatePwd;
    return pattern.test(password);
  };

  const validatePwdStringLength = (password) => {
    const pattern =
      t('nc_password_string_length_regex') !== 'nc_password_string_length_regex'
        ? new RegExp(`${t('nc_password_string_length_regex')}`)
        : appRegex.validatePwdStringLength;
    return pattern.test(password);
  };
  const validatePwdUpperOrLowerCase = (password) => {
    const pattern =
      t('nc_password_upper_or_lower_case_regex') !== 'nc_password_upper_or_lower_case_regex'
        ? new RegExp(`${t('nc_password_upper_or_lower_case_regex')}`)
        : appRegex.validatePwdUpperOrLowerCase;
    return pattern.test(password);
  };
  const validatePwdNumbers = (password) => {
    const pattern =
      t('nc_password_numbers_regex') !== 'nc_password_numbers_regex'
        ? new RegExp(`${t('nc_password_numbers_regex')}`)
        : appRegex.validatePwdNumbers;
    return pattern.test(password);
  };
  const validatePwdSpecialChar = (password) => {
    const pattern =
      t('nc_password_special_chars_regex') !== 'nc_password_special_chars_regex'
        ? new RegExp(`${t('nc_password_special_chars_regex')}`)
        : appRegex.validatePwdSpecialChar;
    return pattern.test(password);
  };

  // Password Validation
  const validatePasswordWithKeys = (values, firstPasswordKey, confirmPasswordKey) => {
    // string, controlName, password

    if (!values[firstPasswordKey] && !values[confirmPasswordKey]) return {};

    const errors = Object.keys(values).map((controlName) => {
      const string = values[controlName];
      if (!string) {
        return {
          [controlName]: t('nc_acc_data_chpwd_require5') // t('ek_ap_password_pw1_error-msg')
        };
      }

      const hasVerified = !validatePwd(values[firstPasswordKey]);
      const hasLength = !validatePwdStringLength(values[firstPasswordKey]);
      const hasUpperCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasLowerCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasNumber = !validatePwdNumbers(values[firstPasswordKey]);
      const hasSpecial = !validatePwdSpecialChar(values[firstPasswordKey]);

      let errorMsg = {};

      if (
        // hasVerified ||
        hasLength ||
        hasUpperCase ||
        hasLowerCase ||
        hasNumber ||
        hasSpecial ||
        values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = {
          [confirmPasswordKey]: t('nc_password_error_invalid-char'),
          otherErrors: {
            // [inputValidation.INVALID]: hasVerified,
            [inputValidation.MIN_MAX_ERROR]: hasLength,
            [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
            [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
            [inputValidation.NUMBER_ERROR]: hasNumber,
            [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
            [inputValidation.MATCH]: values[firstPasswordKey] !== values[confirmPasswordKey]
          }
        };
      }

      if (
        values[firstPasswordKey] &&
        values[confirmPasswordKey] &&
        values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = { ...errorMsg, [confirmPasswordKey]: t('nc_acc_data_chpwd_require5') };
      }

      return errorMsg;
    });
    return Object.assign(...errors);
  };

  const forgotPasswordValidation = yup.object().shape({
    number: formValidation({
      required: t('nc_login_pass_modal_input_phone_err_msg'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('nc_login_pass_modal_input_phone_err_msg')
    }),
    birthDate: yup
      .date()
      .required(t('nc_login_pass_modal_input_birthday_err_msg'))
      .max(validateDOB(), t('nc_login_pass_modal_input_birthday_err_msg'))
    // puk: formValidation({
    //   required: t('nc_login_pass_modal_input_puk_err_msg'),
    //   regex: /^\d{4,8}$/,
    //   validErrorMessage: t('nc_login_pass_modal_input_puk_err_msg')
    // })
  });

  const customPasswordSchema = (values) => {
    const errors = {};
    if (!values.oldPassword && !values.newPassword && !values.confirmPassword) {
      errors.oldPassword = 'please_enter_old_password';
      errors.newPassword = 'please_enter_new_password';
      errors.confirmPassword = 'please_enter_confirm_password';
    } else if (values.oldPassword === values.newPassword) {
      errors.newPassword = 'old_password_and_new_password_must_not_be_same';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm_Password_new_password_must_be_same';
    }
    return errors;
  };

  // Phone number and password validation
  const loginFormValidations = yup.object().shape({
    username: formValidation({
      required: t('nc_login_input_number_err'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('nc_login_input_number_err')
    }),
    password: formValidation({
      required: t('nc_login_input_password_err')
      // regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      // validErrorMessage: t('ek_login_password_error-msg')
    })
  });

  const validationSchemaCode = yup.object().shape({
    emailCode: yup
      .number()
      .typeError(t('nc_2fa_step2_code_error'))
      .required(t('nc_2fa_step2_code_error'))
      .test('code', t('nc_2fa_step2_code_error'), (hp) => /^\d{4}$/.test(hp))
  });

  // Functions
  // Personal Data -----------------------------
  // eslint-disable-next-line no-unused-vars
  const loadPersonalData = () => {
    try {
      setIsLoading(true);
      const { email: { emailAddress = '' } = {} } = personalData;
      if (emailAddress) setEmail(emailAddress);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Customer Products -------------------------
  // eslint-disable-next-line no-unused-vars
  const loadCustomerProducts = () => {
    try {
      setIsLoading(true);

      const findFirstProduct = customerProducts.find((a) => a.status && a.status.name);
      const {
        status: { name: simStatus, name = false }
      } = findFirstProduct;
      if (name) {
        if (simStatus === simLockStatus.ACTIVE) {
          setLockStatus(simLockStatus.ACTIVE);
        } else if (simStatus === simLockStatus.SIM_LOCK_INPROGRESS) {
          setLockStatus(simLockStatus.SIM_LOCK_INPROGRESS);
        } else if (simStatus === simLockStatus.SIM_LOCKED) {
          setLockStatus(simLockStatus.SIM_LOCKED);
        } else if (simStatus === simLockStatus.SIM_UNLOCK_REQUESTED) {
          setLockStatus(simLockStatus.SIM_UNLOCK_REQUESTED);
        } else if (simStatus === simLockStatus.ACTIVATION_INPROGRESS) {
          setLockStatus(simLockStatus.ACTIVATION_INPROGRESS);
        } else {
          setLockStatus(simStatus);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // change EMAIl--------------------
  const onChangeEmailSubmit = async (values) => {
    try {
      setIsLoading(true);
      const { success, data } = await onChangeCustomerDataCall({ emailAddress: values.email });
      if (success && data) {
        setIsEmailChangeSuccess(true);
        setIsLoading(false);
      } else {
        setIsEmailChangeSuccess(false);
        setIsLoading(false);
      }
      await getCustomerData();
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const onChangeContactNumberSubmit = async (values) => {
    try {
      setIsLoading(true);
      const inputparams = {
        alternatePhoneNumber: {
          number: values.alternateNumber,
          prefix: values.alternateNumberPrefix
        },
        emailAddress: personalData?.email?.emailAddress
        // hotlinePassword: customerData?.hotlinePassword
      };
      console.log('inputparams', inputparams);
      const { success, data } = await onChangeCustomerDataCall(inputparams);
      if (success && data) {
        setIsContactChangeSuccess(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      await getCustomerData();
      return data;
    } catch (error) {
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      console.log(error);
      return false;
    }
  };

  const verifyEmail = async (values) => {
    try {
      setIsLoading(true);
      const uniqueIdEmail = generateUUID();
      setEmailUniqueId(uniqueIdEmail);
      const { data, success } = await onTwoFactorAuthPinCall({
        email: values.email,
        uniqueId: uniqueIdEmail
      });
      if (data && success) {
        setChangeEmailForm({ ...changeEmailForm, emailAddress: values.email });
        setEmailChangeStep(1);
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const verifyEmailCode = async (values) => {
    try {
      const { emailCode } = values;
      setIsLoading(true);

      const { data, success } = await onTwoFactorAuthVerificationCall({
        pin: Number(emailCode),
        uniqueId: emailUniqueId
      });

      // eslint-disable-next-line prefer-const
      let inputparams = {
       
        emailAddress: changeEmailForm.emailAddress
      };

      if (data && success) {
        const response = await onChangeCustomerDataCall(inputparams);
        if (response.success) {
          await getCustomerData();
          setContactSuccessModal(!contactSuccessModal);
          setIsEmailChangeSuccess(true);
          setIsLoading(false);
          setEmailChangeStep(0);
        }
      }
      setIsLoading(false);
      setEmailCodeErrorMsg(null);
      return data;
    } catch (error) {
      setIsLoading(false);
      setEmailCodeError(true);
      setEmailCodeErrorMsg(error?.error[0]?.messageBody);
      console.log(error);
      return false;
    }
  };

  // Resend otp code
  const onResendCode = async () => {
    try {
      setIsLoading(true);

      const resendUniqueId = generateUUID();
      setEmailUniqueId(resendUniqueId);
      const { data, success } = await onTwoFactorAuthPinCall({
        email: changeEmailForm.emailAddress,
        uniqueId: resendUniqueId
      });
      if (data && success) {
        setIsLoading(false);
        setIsCodeResend(true);
      }
      return true;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      return false;
    }
  };

  // change hotline password--------------------
  const onHotlinePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);

      const { data, success } = await onChangeCustomerDataCall({
        hotlinePassword: values.pswrdForHtLine
      });
      if (success) {
        setIsHotlineChangeSuccess(true);
        await getCustomerData();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
        });
      }
      return false;
    }
  };

  // change password--------------------
  const onChangePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      if (!validatePwd(values.newPassword)) {
        setChangePasswordErrorMsg(t('nc_password_error_invalid-char'));
        setIsLoading(false);
        return false;
      }
      const { data, success } = await onChangePasswordCall({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (data && success) {
        setPasswordSuccess(true);
        console.log(data);
        const { access_token: accessToken, refresh_token: refreshToken } = data;
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, refreshToken);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      setChangePasswordErrorMsg(t('nc_generic_err_txt'));
      return false;
    }
  };

  const onForceChangePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);

      const { data, success } = await onChangePasswordCall({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (success) {
        setIsLoading(false);

        setPasswordSuccess(!passwordSuccess);
      } else {
        setIsLoading(false);

        // showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      setChangePasswordErrorMsg(t('nc_generic_err_txt'));
      return false;
    }
  };

  // Login grant type password-------------------
  const onLoginSubmit = async (values) => {
    try {
      setIsLoading(true);

      const { data, success } = await onPasswordLogin({
        username: values.username,
        password: values.password
      });
      if (success) {
        setTimeout(() => {
          setUserLogin(true);
          navigate(appRoute.DASHBOARD);
          // setWelcomePopup('welcome_popup');
          setIsLoginSubmit(true);
          setIsLoading(false);
        }, 100);
      } else {
        setIsLoading(false);
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
      // eslint-disable-next-line max-len
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.status === StatusCodes.BAD_REQUEST ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.BAD_REQUEST
        )
      ) {
        console.log(error);
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      } else {
        console.log(error);
        // setIsGenericError(true);
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
        setLoginError(true);
      }
      return false;
    }
  };

  const onLogoutPress = async () => {
    setIsLoading(true);
    const authToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
    const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
    try {
      const response = await onLogout(refreshToken, authToken);
      if (response.status === 200) {
        // console.log("success response--------", response);
        setUserLogin(false);
        localStorage.clear();
        sessionStorage.clear();

        setIsLoading(false);
        window.location.href = '/';
      }
    } catch (error) {
      setIsLoading(false);

      console.log('Logout error', error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
      window.location.href = '/';
    }
  };

  // Forgot password
  const onForgotPasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      const updatedDate = moment(values.birthDate, 'DD.MM.YYYY').format('DD.MM.YYYY');

      const { data, success } = await onCustomerFogotPasswordCall({
        birthdate: updatedDate,
        username: values.number
      });
      if (success) {
        setIsLoading(false);

        setIsForgetPassClicked(false);
        setForgotPassRes('success');
      }

      setIsLoading(false);

      return data;
      // return true;
    } catch (error) {
      setIsLoading(false);
      setIsForgetPassClicked(false);
      setForgotPassRes('error');

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  const onChangePreferencesSubmit = async (values) => {
    try {
      // console.log('values------------', values);
      setIsLoading(true);

      const { data, success } = await onChangeOptinFlagsCall(values);
      if (data && success) {
        setPreferenceSuccessModal(true);
        const currentTime = new Date().getTime();
        const flagShownTime = currentTime + 300000;
        localStorage.setItem(appStorage.CONSENT_SHOW_TIME, flagShownTime);
        setIsLoading(false);
      }
      // await onDOMWithProduct();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
        });
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  // Lock  SIM--------------------
  const lockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onSimLock();
      if (success) {
        // showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        // showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  // unlock SIM--------------------
  const unLockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onUnSimLock();
      if (success) {
        // showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        // showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  // handle LockTitleStatus
  const handleLockStatus = () => {
    switch (lockStatus) {
      case simLockStatus.ACTIVE:
        return simLockStatusTitle.ACTIVE;
      case simLockStatus.SIM_LOCK_INPROGRESS:
        return simLockStatusTitle.SIM_LOCK_INPROGRESS;
      case simLockStatus.SIM_LOCKED:
        return simLockStatusTitle.SIM_LOCKED;
      case simLockStatus.SIM_UNLOCK_REQUESTED:
        return simLockStatusTitle.SIM_UNLOCK_REQUESTED;
      default:
        return simLockStatusTitle.DEFAULT;
    }
  };

  // handle LockTitleStatus
  const onSimLockSubmit = () => {
    if (lockStatus !== simLockStatus.ACTIVATION_INPROGRESS) {
      if (handleLockStatus(lockStatus) === simLockStatusTitle.ACTIVE) {
        lockSimCard();
      } else if (handleLockStatus(lockStatus) === simLockStatusTitle.SIM_LOCKED) {
        unLockSimCard();
      }
    }
  };

  const onCancelTerminationCall = async (values) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line no-unneeded-ternary
      const ordinary = values.extraOrdinary === 'NO' ? true : false;
      // eslint-disable-next-line no-unneeded-ternary
      const extraOrdinary = values.extraOrdinary === 'YES' ? true : false;
      // eslint-disable-next-line no-unneeded-ternary
      const possibleDate = values.asap === 'true' ? true : false;
      const date = values.asap === 'false' ? values.validUntil : '';

      const inputParams = JSON.stringify({
        client: appStaticContentConfig.client,
        'first name': cancelContractForm.userFirstName,
        'last name': cancelContractForm.userLastName,
        street: cancelContractForm.cancelStreet,
        'street number': cancelContractForm.cancelHouseNumber,
        zipcode: cancelContractForm.cancelZip,
        city: cancelContractForm.cancelCity,
        'email address': cancelContractForm.email,
        'customer ID': cancelContractForm.userCustomerNumber,
        'mobile phone number': cancelContractForm.telefonNummer,
        'date of termination': date,
        'terminate on next possible': possibleDate,
        'ordinary termination': ordinary,
        'extraordinary termination': extraOrdinary,
        'reason for extraordinary termination': values.terminationReason
      });
      // console.log('input--------', JSON.parse(inputParams));
      // setCancelContractRes({ downloadLink: 'https://norma-v2-be.spreadspace.de/sites/default/files/pdf/11ed7d6e/K%C3%BCndigung_1234567890.pdf' });
      const response = await onContractTerminationCall(inputParams);
      // console.log('terminationAfterCalldata------------', response);
      if (response.status === 200) {
        setCancelContractRes({ downloadLink: response.data.url });
      }
      setIsLoading(false);

      return response;
      // return true;
    } catch (error) {
      setIsLoading(false);
      console.log('error======', error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t('nc_generic_err_txt')
      });
      return false;
    }
  };

  const downloadPDF = () => {
    window.open(cancelContractRes.downloadLink, '_blank');
    setCancelContractRes({ downloadLink: false });
    setCancelContractForm({});
  };

  const onLoad = async () => {
    try {
      setIsLoading(true);
      await getCustomerData();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Hooks
  useEffect(() => {
    if (customerData.msisdn) {
      if (customerData.forcePasswordReset) {
        setTimeout(() => {
          setIsLoading(false);

          // navigate(appRoute.FORCE_PASSWORD_CHANGE);
        }, 1000);
      } else if (isLoginSubmit) {
        setIsLoginSubmit(false);
        setTimeout(() => {
          // navigate(appRoute.DASHBOARD);
          setIsLoading(false);
        }, 1500);
      }
    }
  }, [customerData.msisdn]);

  useEffect(() => {
    if (domWithProductData) {
      if (domWithProductData.customerData) {
        const flagShownTime = parseInt(localStorage.getItem(appStorage.PREFERENCE_SHOW_TIME), 10);
        const msisdn = localStorage.getItem(appStorage.MSISDN);
        const currentTime = new Date().getTime();
        setPreferenceAlert(
          currentTime < flagShownTime && domWithProductData.customerData.msisdn === msisdn
        );
        if (currentTime > flagShownTime && domWithProductData.customerData.msisdn === msisdn) {
          onLoad();
          localStorage.removeItem(appStorage.PREFERENCE_SHOW_TIME);
          localStorage.removeItem(appStorage.MSISDN);
        }
      }
    }
  }, [domWithProductData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginFrom,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      onForgotPasswordSubmit,
      forgotPassRes,
      setForgotPassRes,
      welcomePopup,
      setWelcomePopup,
      cancelContractForm,
      setCancelContractForm,
      cancelContractRes,
      setCancelContractRes,
      contactSuccessModal,
      setContactSuccessModal,
      passwordSuccess,
      setPasswordSuccess,
      hotlineSuccessModal,
      setHotlineSuccessModal,
      changePasswordErrorMsg,
      setChangePasswordErrorMsg,
      forcePasswordSuccessModal,
      setForcePasswordSuccessModal,
      emailModal,
      setEmailModal,
      emailCodeError,
      setEmailCodeError,
      emailCodeErrorMsg,
      setEmailCodeErrorMsg,
      loginError,
      setLoginError,
      preferenceSuccessModal,
      setPreferenceSuccessModal,
      preferenceAlert,
      setPreferenceAlert,
      isLogoutClicked,
      setIsLogoutClicked,
      isLoginSubmit,
      setIsLoginSubmit,
      isForgetPassClicked,
      setIsForgetPassClicked,
      isHotlineChangeSuccess,
      setIsHotlineChangeSuccess,
      isEmailChangeSuccess,
      setIsEmailChangeSuccess,
      isContactChangeSuccess,
      setIsContactChangeSuccess,
      emailChangeStep,
      setEmailChangeStep,
      isCodeResend,
      setIsCodeResend,
      pdfDownloadSuccess,
      setPdfDownloadSuccess,

      // API calls
      onChangeEmailSubmit,
      onChangeContactNumberSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      onLoginSubmit,
      onForceChangePasswordSubmit,

      // Form Initial States & Validations
      changeEmailInitvalue,
      validateContactNumber,
      contactNumberInitValue,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,
      changePasswordInitialValue,
      changePasswordValidation,
      validatePasswordWithKeys,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      cancelContractValidation,
      cancelContractOptionalInitValue,
      validateEmail,
      verifyEmail,
      verifyEmailCode,
      onResendCode,
      validationSchemaCode,
      preferencesInitValue,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onLogoutPress,
      onCancelTerminationCall,
      onChangePreferencesSubmit,
      downloadPDF
    }),
    [
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginFrom,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      onForgotPasswordSubmit,
      forgotPassRes,
      setForgotPassRes,
      welcomePopup,
      setWelcomePopup,
      cancelContractForm,
      setCancelContractForm,
      cancelContractRes,
      setCancelContractRes,
      contactSuccessModal,
      setContactSuccessModal,
      passwordSuccess,
      setPasswordSuccess,
      hotlineSuccessModal,
      setHotlineSuccessModal,
      changePasswordErrorMsg,
      setChangePasswordErrorMsg,
      forcePasswordSuccessModal,
      setForcePasswordSuccessModal,
      emailModal,
      setEmailModal,
      emailCodeError,
      setEmailCodeError,
      emailCodeErrorMsg,
      setEmailCodeErrorMsg,
      loginError,
      setLoginError,
      preferenceSuccessModal,
      setPreferenceSuccessModal,
      preferenceAlert,
      setPreferenceAlert,
      isLogoutClicked,
      setIsLogoutClicked,
      isLoginSubmit,
      setIsLoginSubmit,
      isForgetPassClicked,
      setIsForgetPassClicked,
      isHotlineChangeSuccess,
      setIsHotlineChangeSuccess,
      isEmailChangeSuccess,
      setIsEmailChangeSuccess,
      isContactChangeSuccess,
      setIsContactChangeSuccess,
      emailChangeStep,
      setEmailChangeStep,
      isCodeResend,
      setIsCodeResend,
      pdfDownloadSuccess,
      setPdfDownloadSuccess,

      // API calls
      onChangeEmailSubmit,
      onChangeContactNumberSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      onLoginSubmit,
      onForceChangePasswordSubmit,

      // Form Initial States & Validations
      changeEmailInitvalue,
      validateContactNumber,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,

      changePasswordInitialValue,
      changePasswordValidation,
      contactNumberInitValue,
      validatePasswordWithKeys,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      cancelContractValidation,
      cancelContractOptionalInitValue,
      validateEmail,
      verifyEmail,
      verifyEmailCode,
      onResendCode,
      validationSchemaCode,
      preferencesInitValue,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onLogoutPress,
      onCancelTerminationCall,
      onChangePreferencesSubmit,
      downloadPDF
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <AccountContext.Provider value={contextPayload}>
      {isUserLoggedIn && (
        <Logout
          isLogoutClicked={isLogoutClicked}
          setIsLogoutClicked={setIsLogoutClicked}
          onLogoutPress={onLogoutPress}
          logoutImage={logoutPopupImage}
        />
      )}
      {children}
    </AccountContext.Provider>
  );
}

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAccount = () => useContext(AccountContext);

export default AccountContext;
