import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@core/Utils/Tooltip';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import { appAlert } from '@utils/globalConstant';
import { InfoNotification } from '../InfoNotification';
import './FormTextArea.scss';

export function FormTextArea({
  label,
  labelCustomClass,
  inputCustomClass,
  toolTip,
  id,
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  isValid,
  isInvalid,
  isDisabled,
  invalidMessage,
  hint,
  maxLength,
  rows,
  cols,
  invalid,
  valid,
  validMessage
  // iconDivClass,
}) {
  // State
  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  // Functions
  const triggerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setIsTouched(true);
  };

  const triggerBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    // setIsFocus(false);

    setIsBlur(true);
    setIsFocus(false);
    setIsTouched(false);
  };

  const inptutControl = (
    <div className="mb-2 input-mb">
      {label && (
        <label htmlFor={id} className={`form-label ${labelCustomClass}`}>
          {label}{' '}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
            </Tooltip>
          ) : (
            <></>
          )}{' '}
        </label>
      )}
      <div
        className={`
        ${valid ? 'valid' : ''}
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''}
        ${isFocus ? 'focus' : ''}
        ${isBlur ? 'blur' : ''}
        ${isTouched ? 'touched' : ''}
        ${isDisabled ? 'disabled' : ''}
        ${inputCustomClass}
      `}>
        <textarea
          className={`form-control form-textarea
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''}
        ${valid ? ' valid' : ''}
        ${isFocus ? 'focus' : ''}
        ${isBlur ? 'blur' : ''}
        ${isTouched ? 'touched' : ''}
        ${isDisabled ? 'disabled' : ''}
        ${inputCustomClass}`}
          aria-describedby={`${id}Block`}
          {...{
            name,
            placeholder,
            value: value || '',
            isValid,
            isInvalid,
            onBlur,
            maxLength,
            rows,
            cols
          }}
          onChange={triggerChange}
          disabled={isDisabled}
          onFocus={() => setIsFocus(true)}
          onBlur={triggerBlur}
        />
      </div>
      {hint && !valid && !invalid && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification message={hint} />
        </div>
      )}
      {invalid && invalidMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
  return inptutControl;
}

FormTextArea.propTypes = {
  label: PropTypes.string,
  labelCustomClass: PropTypes.string,
  inputCustomClass: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  invalidMessage: PropTypes.string,
  validMessage: PropTypes.string,
  // isErrorIcon: PropTypes.bool,
  hint: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string
};

FormTextArea.defaultProps = {
  label: '',
  labelCustomClass: '',
  inputCustomClass: '',
  id: '',
  placeholder: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  maxLength: '',
  valid: null,
  invalid: null,
  isDisabled: null,
  invalidMessage: '',
  validMessage: '',
  // isErrorIcon: false,
  hint: '',
  rows: '',
  cols: ''
};

export default FormTextArea;
