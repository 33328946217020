/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { appAlert, appButtonTypes } from '@utils/globalConstant';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { Icons } from '@core/Utils/Icons';
import { useAlert } from '@context/Utils';

import './index.scss';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useActivation } from '@context/MobileOne';


function SuccessAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-success animate__animated animate__tada d-flex align-items-center"
      role="alert"
      key={`alert-success-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
        <use xlinkHref="#check-circle-fill" />
      </svg>
      <h4 className="nc-realheadpro-h4 ps-2">{message}</h4>
    </div>
  );
}

function ErrorAlert({ message = '', type = '' }) {
  const { t } = useStaticContent();
  return (
    <div className="container-md container-page1">
      <div className="row animate__animated animate__fadeIn">
        <div className={`text-center ${type}`}>
          <Icons name="errorcolor" />

          <h3 className="nc-doomsday-h3 py-8 m-0" dangerouslySetInnerHTML={{ __html: t('nc_generic_err_hdl') }} />
          <p className="pb-4" dangerouslySetInnerHTML={{ __html: message }} />
          <div className="button-div pt-4">
            <ButtonPrimary
              type="button"
              buttonType={appButtonTypes.PRIMARY.DEFAULT}
              label={t('nc_generic_err_btn') || 'Button'}
            // onClick={onButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function WarningAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-warning animate__animated animate__tada d-flex align-items-center"
      role="alert"
      key={`alert-warning-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Warning:">
        <use xlinkHref="#exclamation-triangle-fill" />
      </svg>
      <h4 className="nc-realheadpro-h4 ps-2">{message}</h4>
    </div>
  );
}

function InfoAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-primary animate__animated animate__tada d-flex align-items-center"
      role="alert"
      key={`alert-primary-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Info:">
        <use xlinkHref="#info-fill" />
      </svg>
      <h4 className="nc-realheadpro-h4 ps-2">{message}</h4>
    </div>
  );
}

export function Alert() {
  const { alert, setAlert, setIsGenericError } = useAlert();
  const { currentStep: activationStep } = useActivation();

  const { t } = useStaticContent();
  const location = useLocation();
  const navigate = useNavigate();

  // Functions
  const showAlerts = () => {

    let defaultAlert = <></>;
    switch (alert.type) {
      case appAlert.SUCCESS:
        defaultAlert = SuccessAlert(alert);
        break;
      case appAlert.ERROR:
        defaultAlert = ErrorAlert(alert);
        break;
      case appAlert.WARNING:
        defaultAlert = WarningAlert(alert);
        break;

      default:
        // appAlert.INFO
        defaultAlert = <></>;
        break;
    }
    return defaultAlert;
  };

  const onButtonClick = () => {
    // setAlert({});
    console.log(activationStep);
    if (activationStep >= 5 || activationStep === 0) {
      setIsGenericError(false);
      setAlert({});
    } else {
      navigate(-1);
    }
  }

  useEffect(() => {
    if (setIsGenericError) {
      setIsGenericError(false);
      setAlert({});
    }
  }, [location.pathname])

  if (!alert || Object.keys(alert).length === 0) {
    return <></>;
  }

  return (
    <div className="container-md container-page1">
      <div className="row animate__animated animate__fadeIn">
        <div className={`text-center ${alert?.type}`}>
          <Icons name="errorcolor" />

          <h3 className="nc-doomsday-h3 py-8 m-0" dangerouslySetInnerHTML={{ __html: t('nc_generic_err_hdl') }} />
          <p className="pb-4" dangerouslySetInnerHTML={{ __html: alert?.message }} />
          <div className="button-div  pt-4">
            <ButtonPrimary
              // icon="errorcolor"
              type="button"
              buttonType={appButtonTypes.PRIMARY.DEFAULT}
              label={t('nc_generic_err_btn') || 'Button'}
              onClick={onButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alert;
