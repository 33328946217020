import React from 'react';
import './BubbleTabs.scss';
import { Icons } from '@core/Utils/Icons';

export function BubbleTabs({
  bubbleColor,
  bubbleIcon,
  index, // navigation tab index
  // strokeColor,
  // icon,
  text,
  ...props
}) {
  let justifyContent;
  if (index === 0) {
    justifyContent = 'start';
  } else if (index === 2) {
    justifyContent = 'end';
  } else {
    justifyContent = 'center';
  }

  // const transform = (() => {
  //   switch (index) {
  //     case 0:
  //       return 'matrix(1, 0, 0, 1, -5, 4)';
  //     case 2:
  //       return 'matrix(1, 0, 0, 1, 5, 4)';
  //     default:
  //       return 'matrix(1, 0, 0, 1, 0, 4)';
  //   }
  // })();

  const bubbleBodyStyles = { backgroundColor: bubbleColor };
  const bubbleTipStyles = { borderColor: `${bubbleColor} transparent` };

  return (
    <div className="bubble-tab">
      <div
        className="bubble d-flex align-items-center justify-content-center"
        style={bubbleBodyStyles}>
        {bubbleIcon && (
          <span className="pe-2 bubble-icon">
            <Icons name={bubbleIcon} width={20} />
          </span>
        )}
        <span className="nc-doomsday-copy text-white m-0">{text}</span>
        <div
          className={`bubble-tip bubble-tip${index === 0 ? '-left' : '-right'}`}
          style={bubbleTipStyles}></div>
      </div>
    </div>

    // <div
    //   className={`bubble-tab d-flex align-items-center justify-content-${justifyContent} animate__animated animate__flipInY`}>
    //   <div className={`bg-accent bubble bubble${index === 0 ? '-l' : '-r'}`} >
    //     <p className="nc-doomsday-copy text-white m-0">TEST {text}</p>
    //   </div>
    // </div>

    // <div
    //   className={`d-flex align-items-center justify-content-${justifyContent} animate__animated animate__flipInY`}>
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     width={105}
    //     height={38}
    //     viewBox="0 0 105 62"
    //     fill="none"
    //     style={{ transform, scale: '1.5', ...props?.style }}
    //     {...props}>
    //     <g filter="url(#filter0_d_4432_259)">
    //       <path
    //         d={
    //           index === 0
    //             ? 'M59.2297 54V43.0169H38.8086L59.2297 54Z'
    //             : 'M45.7703 54V43.0169H66.1914L45.7703 54Z'
    //         }
    //         fill={strokeColor}
    //       />
    //       <path
    //         d="M4 22.8814C4 10.2443 14.3896 0 27.2057 0H77.7943C90.6104 0 101 10.2443 101 22.8814C101 35.5184 90.6104 45.7627 77.7943 45.7627H27.2057C14.3896 45.7627 4 35.5184 4 22.8814Z"
    //         fill={strokeColor}
    //       />
    //     </g>
    //     <defs>
    //       <filter
    //         id="filter0_d_4432_259"
    //         x={0}
    //         y={0}
    //         width={105}
    //         height={62}
    //         filterUnits="userSpaceOnUse"
    //         colorInterpolationFilters="sRGB">
    //         <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //         <feColorMatrix
    //           in="SourceAlpha"
    //           type="matrix"
    //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //           result="hardAlpha"
    //         />
    //         <feOffset dy={4} />
    //         <feGaussianBlur stdDeviation={2} />
    //         <feComposite in2="hardAlpha" operator="out" />
    //         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
    //         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4432_259" />
    //         <feBlend
    //           mode="normal"
    //           in="SourceGraphic"
    //           in2="effect1_dropShadow_4432_259"
    //           result="shape"
    //         />
    //       </filter>
    //     </defs>
    //     <image xlinkHref={icon} x="20" y="14" height="20px" width="20px" />

    //     <text
    //       x="65"
    //       y="25"
    //       className="nc-doomsday-copy text-white"
    //       dominantBaseline="middle"
    //       textAnchor="middle"
    //       fill="white">
    //       {text}
    //     </text>
    //   </svg>
    // </div>
  );
}

export default BubbleTabs;
