import React from 'react';
import { ButtonPrimary } from '@core/ButtonPrimary';
import { Icons } from '@core/Utils';
import { appButtonTypes } from '@utils/globalConstant';

export function FeedbackScreen({
  type = '',
  icon,
  heading,
  children,
  buttonLabel,
  onButtonClick = () => {},
  buttonIcon
}) {
  return (
    <div className="row animate__animated animate__fadeIn">
      <div className={`text-center ${type}`}>
        {icon && <Icons name={icon} />}
        {heading && (
          <h3 className="nc-doomsday-h3 py-8 m-0" dangerouslySetInnerHTML={{ __html: heading }} />
        )}
        {children}
        <div className="button-div py-4">
          <ButtonPrimary
            icon={buttonIcon}
            type="button"
            buttonType={appButtonTypes.PRIMARY.DEFAULT}
            label={buttonLabel || 'Button'}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </div>
  );
}

export default FeedbackScreen;
