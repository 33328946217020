/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import './style.scss';

export function Link(props) {
  const { to = '/404', ...rest } = props;
  return <RLink to={to} {...rest} />;
}

export default Link;
