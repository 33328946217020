/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable no-global-assign */
/* eslint-disable import/prefer-default-export */
// Disable console logs for production build

/**
 * @param {boolean} debugOn
 * @param {boolean} suppressAll
 */
export const DebugLogs = (function () {
  var savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function (debugOn) {
    console.log({ debugOn });
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    } else {
      console = savedConsole;
    }
  };
})();
