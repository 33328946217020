import React from 'react';
import Icon from '@core/Utils/Icons';
import { appInfoBoxType } from '@utils/globalConstant';

import './InfoBoxClose.scss';

export function InfoBoxClose({
  label,
  rightIcon,
  leftIcon,
  type = appInfoBoxType.DARK,
  onInfoClick
}) {
  return (
    <div className={`d-flex px-3 py-2 ${type} infobox-close`}>
      <div className="align-self-center">
        {leftIcon && <Icon className="info-support-icon" name={leftIcon} height={24} width={24} />}
      </div>
      <div className="px-3 flex-grow-1">
        {label && <div className="info-label d-flex align-items-center">{label}</div>}
      </div>
      <div className="" role="button" aria-hidden onClick={onInfoClick}>
        {rightIcon && <Icon className="info-icon" name={rightIcon} height={18} width={18} />}
      </div>
    </div>
  );
}

export default InfoBoxClose;
