import React from 'react';
import { Icons } from '@core/Utils/Icons';
import { ButtonRadio } from '../ButtonRadio';
import './PaymentFields.scss';

export function PaymentFields({
  icon,
  label,
  value,
  onChange: onChangeProps,
  isDefaultChecked
}) {
  // Functions
  const onChange = (isTrue) => {
    if (onChangeProps) {
      onChangeProps(isTrue);
    }
  };

  return (
    <div className="payment-field d-flex align-items-center shadow-xs">
      <div className="">
        {icon && <Icons name={icon} width={64} height={32} />}
      </div>
      <div className="flex-grow-1">{label && <div className="m-0 ps-3 nc-doomsday-copy label">{label}</div>}</div>
      <div className="">
        <ButtonRadio
          value={value}
          onChange={onChange}
          isDefaultChecked={isDefaultChecked}
        />
      </div>
    </div>
  );
}

export default PaymentFields;
