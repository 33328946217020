import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icons } from '@core/Utils';
import './Accordion.scss';
import { useDocument } from '@context/MobileOne';

export function Accordion({ items }) {
  // Context
  const { agbPdfs } = useDocument();

  // State
  const [openItem, setOpenItem] = useState(-1);

  // Functions
  const toggle = (index) => {
    if (openItem === index) {
      setOpenItem(-1);
    } else {
      setOpenItem(index);
    }
  };

  useEffect(() => {
    if (items.length > 0) {
      setOpenItem(-1);
    }
  }, [items]);

  return (
    <div className="accordion accordion-flush pt-6 mb-0" id="accordionFlushExample">
      {items.map((block, blockIndex) => (
        <div className={`accordion-item border-0 bg-transparent py-3 `} key={`${blockIndex + 1}`}>
          <div id={`flush-heading${blockIndex}`}>
            <button
              className={`d-flex justify-content-between align-items-center accordion-button bg-transparent border-0 px-0 py-3 ${blockIndex === openItem ? '' : 'collapsed'
                }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#accordion-${blockIndex + 1}`}
              aria-expanded={blockIndex === openItem}
              aria-controls={`${blockIndex}-accordion`}
              onClick={() => toggle(blockIndex)}>
              <h4 className="nc-doomsday-h4 m-0 faq-question d-flex align-items-center">
                {block.question}
              </h4>
              <Icons
                containerClass="ps-2"
                name={blockIndex === openItem ? 'minusdarkgreen' : 'plusdarkgreen'}
              />
            </button>
          </div>
          <div
            id={`accordion-${blockIndex + 1}`}
            className={`accordion-collapse collapse ${blockIndex === openItem ? 'show' : ''}`}
            aria-labelledby={`flush-heading${blockIndex}`}
            data-bs-parent="#accordionFlushExample">
            {block.answer && (
              <div
                className="accordion-body border-0 p-0 animate__animated animate__fadeIn"
                dangerouslySetInnerHTML={{ __html: `${block.answer}`.replace("{{agb_url}}", agbPdfs.redirectionURL) }} // {{agb_url}}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.node.isRequired
    })
  ).isRequired
};

Accordion.defaultProps = {
  // items: []
};

export default Accordion;
