import React from 'react';
import { Helmet } from 'react-helmet';
// import { appImages } from '@utils/globalConstant';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function Meta({ title = 'NORMA connect', description, keywords }) {
  const { t } = useStaticContent();

  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />

      <title>{title}</title>
      <meta name="description" content={t('nc_global_meta_description')} />
      {/* {keywords && <meta name="keywords" content={keywords} />} */}
      
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#33b6ae" />
      <meta name="msapplication-TileColor" content="#33b6ae" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
}

export default Meta;
