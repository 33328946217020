import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { PopupImage } from '@core/PopupImage';

export function Logout({ isLogoutClicked, setIsLogoutClicked, onLogoutPress, logoutImage }) {
  // States
  // Contexts
  const { t } = useStaticContent();

  // Hooks
  

  return (
    <>
      <PopupImage
        isOpen={isLogoutClicked}
        title={t("nc_acc_modal_hdl")}
        buttonLabel={t("nc_acc_modal_btn")}
        onButtonClick={() => {
          setIsLogoutClicked(false);
          onLogoutPress();
        }}
        image={logoutImage?.media_url_display}
        onClose={() => setIsLogoutClicked(false)}
      />
    </>
  );
};

export default Logout;