import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export function Card({ sx, children, customClass }) {
  return (
    <div className={`card ${customClass}`} {...{ style: sx }}>
      {children}
    </div>
  );
}

Card.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string
};

Card.defaultProps = {
  sx: {},
  customClass: ''
};

export default Card;

export * from './CardBody';
export * from './CardFooter';
export * from './CardHeader';
export * from './CardImg';
export * from './CardImgOverlay';
export * from './CardLink';
export * from './CardSubtitle';
export * from './CardText';
export * from './CardTitle';
