import React from 'react';
import { Form, Formik } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useAccount } from '@context/MobileOne';
import { appButtonTypes } from '@utils/globalConstant';
import { FormInput } from '@core/FormInput';
// eslint-disable-next-line import/no-cycle
import { FormDatePicker } from '@core/FormDatePicker';
import { Modal } from '../Utils/Modal';
import { ButtonPrimary } from '../ButtonPrimary';
// import './PopupPassword.scss';

export function PopupPassword({ isOpen, title, bodyText, buttonLabel, onButtonClick, onClose }) {
  // Contexts
  const { t } = useStaticContent();
  const { forgotPasswordInitialValue, forgotPasswordValidation, onForgotPasswordSubmit } =
    useAccount();

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalClass="popup-support">
      <div className="container-md">
        <div className="row">
          <div className="mx-auto col-lg-8 col-md-8 col-sm-7 col-xs-12 px-6 px-sm-0 px-md-3">
            <div className="modal-main-content mx-auto">
              <div className="modal-header border-0 mt-4 pt-4">
                <h3 className="modal-title" dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              {bodyText && (
                <div className="modal-body pt-4">
                  <p dangerouslySetInnerHTML={{ __html: bodyText }} />
                </div>
              )}
              <Formik
                initialValues={forgotPasswordInitialValue}
                validationSchema={forgotPasswordValidation}
                onSubmit={onForgotPasswordSubmit}>
                {({
                  values: { number, birthDate },
                  handleBlur,
                  handleChange,
                  errors,
                  touched,
                  handleSubmit
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row form-fields pt-4 mt-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-12 mb-3 mb-xxl-0 mb-xl-0 mb-lg-3 mb-md-3 mb-sm-3">
                        <FormInput
                          placeholder={t('nc_login_pass_modal_input_phone')}
                          autoComplete="off"
                          startIcon="phonecolor"
                          id="number"
                          name="number"
                          value={number}
                          valid={touched.number && !errors.number}
                          invalid={!!touched.number && !!errors.number}
                          invalidMessage={touched.number && errors.number ? errors.number : null}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                id: 'number',
                                name: 'number',
                                value: e.target.value
                              }
                            });
                          }}
                          customClass="gray-border"
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-12">
                        <FormDatePicker
                          // date={testDate}
                          // onDateChange={setTestDate}
                          // label="TITLE"
                          popUpPicker
                          id="birthDate"
                          customClass="gray-border"
                          startIcon="dateGreen"
                          placeholder={t('nc_login_pass_modal_input_birthday')}
                          value={birthDate}
                          onChange={(date) => {
                            handleChange({
                              target: {
                                name: 'birthDate',
                                value: date
                              }
                            });
                            // setTestDate(date);
                          }}
                          onBlur={handleBlur}
                          // autoComplete="off"
                          // valid={touched.birthDate && !errors.birthDate}
                          invalid={!!touched.birthDate && !!errors.birthDate}
                          invalidMessage={
                            touched.birthDate && errors.birthDate ? errors.birthDate : null
                          }
                        />
                        {/* <FormInput
                          placeholder={t('nc_login_pass_modal_input_puk')}
                          autoComplete="off"
                          startIcon="keycolor"
                          value={puk}
                          invalid={!!touched.puk && !!errors.puk}
                          valid={touched.puk && !errors.puk}
                          onBlur={handleBlur}
                          id="puk"
                          name="puk"
                          invalidMessage={touched.puk && errors.puk ? errors.puk : null}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                id: 'puk',
                                name: 'puk',
                                value: e.target.value
                              }
                            });
                          }}
                          customClass="gray-border"
                          rightIcon="infomint"
                          showTooltip
                          tooltipTitle={t('nc_reg_step1_info_puk')}
                        /> */}
                      </div>
                    </div>
                    <div className="modal-button mt-4 pt-4">
                      <ButtonPrimary
                        type="submit"
                        buttonType={appButtonTypes.PRIMARY.DEFAULT}
                        label={buttonLabel || 'Button'}
                        onClick={onButtonClick}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopupPassword;
