import React from 'react';
import { ChargeHistoryProvider } from './ChargeHistory';
import { OnlineTopUpProvider } from './OnlineTopUp';
import { AlphaVoucherProvider } from './Voucher';

export function AlphaCommProvider({ children, config, env }) {
  return (
    <>
      {/* OnlineTopUp Context Provider */}
      <OnlineTopUpProvider {...{ config: { ...config, env } }}>
        {/* Charge History Context Provider */}
        <ChargeHistoryProvider {...{ config: { ...config, env } }}>
          {/* Voucher Provider */}
          <AlphaVoucherProvider {...{ config: { ...config, env } }}>
            {children}
          </AlphaVoucherProvider>
        </ChargeHistoryProvider>
      </OnlineTopUpProvider>
    </>
  );
}

export default AlphaCommProvider;
