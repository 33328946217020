import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export function Switch({
  label,
  type,
  id,
  name,
  onChange,
  onBlur,
  value,
  isInvalid,
  isValid,
  isDisabled,
  // eslint-disable-next-line no-unused-vars
  isDefaultChecked,
  error,
  checked
}) {
  return (
    <div className="form-check form-switch custom-switch">
      <input
        className={`form-check-input ${isValid ? 'is-valid' : ''} ${isInvalid ? 'is-invalid' : ''}`}
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="switch"
        checked={checked}
        disabled={isDisabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
      {isInvalid && error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

// <Form.Group as={Col} controlId={id} className="mb-3">
//   <Form.Check
//     {...{
//       type,
//       label,
//       name,
//       value,
//       onChange,
//       onBlur,
//       isInvalid
//     }}
//     defaultChecked={isDefaultChecked}
//     disabled={isDisabled}
//   />
// </Form.Group>
Switch.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDefaultChecked: PropTypes.bool,
  error: PropTypes.string,
  checked: PropTypes.bool
};

Switch.defaultProps = {
  label: '',
  type: 'checkbox',
  id: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  isValid: null,
  isInvalid: null,
  isDisabled: null,
  isDefaultChecked: null,
  error: '',
  checked: null
};

export default Switch;
