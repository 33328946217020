/* eslint-disable no-unneeded-ternary */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  DEFAULT_FIELD_VALIDATION_TYPE,
  generateParamsForAddToCart,
  getTopUpWithoutAmount,
  useAppConfig
} from '@dom-digital-online-media/dom-app-config-sdk';
// import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';

import {
  appAlert,
  // appAutoTopUpPeriodType,
  appPaymentProductType,
  formValidation,
  appStorage,
  appTopUpTo,
  appTopUpType,
  appAutoTopUpType,
  gettingNumberForGerman,
  appAutoTopUpPeriodType,
  appPaymentMethods,
  appRoute
} from '@utils/globalConstant';
// getLowBalanceTopUp,
import { useAlert } from '@context/Utils/Alert';
import { useCustomer } from '@context/MobileOne/Customer';

export const OnlineTopUpContext = createContext();

export const OnlineTopUpProvider = function ({ children, config }) {
  // Constant
  const topUpFormInitialValue = {
    isDirectProduct: 'NO',
    type: appTopUpType.AUTOMATIC, // appTopUpType // AUTOMATIC || DIRECT
    periodType: '', // appAutoTopUpPeriodType.LOW_BALANCE, // appAutoTopUpPeriodType.LOW_BALANCE, // LOW_BALANCE | ONCE_PER_MONTH | RATE
    // periodDay: '', // Only for  ONCE_PER_MONTH,
    chargeTo: appTopUpTo.SELF, // appTopUpTo.SELF,
    phone: '',
    // confirmPhone: '',
    productId: '',
    productType: '',
    productAmount: '', // Only for DIRECT,
    paymentMethodName: '', // appPaymentMethods
    terms: false
  };

  // Context
  const { storage } = config;
  const { t } = useStaticContent();
  // const { isUserLoggedIn } = useAuth();
  const { showAlert, setIsGenericError } = useAlert();
  const [queryParams] = useSearchParams();
  const {
    onSsoPaymentToken,
    onPaymentProduct,
    onPaymentAddToCart,
    onPaymentMethod,
    onPaymentCheckoutAddress,
    onCheckoutPaymentStart,
    onPaymentToken,
    onPaymentCommitOrder,
    onPaymentTokenDelete,
    onPaymentAutoTopupDelete,
    onPaymentAutoTopupQuery
  } = useAlphaComm();
  const { env } = useAppConfig();
  const { customerData, personalData, getCustomerData } = useCustomer();
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentProductLoading, setIsPaymentProductLoading] = useState(false);
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [topUpForm, setTopUpForm] = useState(topUpFormInitialValue);

  const [paymentProducts, setPaymentProducts] = useState([]);

  const [autoTopUpQueryList, setAutoTopUpQueryList] = useState([]);

  const [cartName, setCartName] = useState(null); // Cart ID / Name when added to card.

  const [isAutoLowEnabled, setIsAutoLowEnabled] = useState(false);

  const [paymentAuthData, setPaymentAuthData] = useState({});

  const [alphaComToken, setAlphaComToken] = useState('');

  const [paymentMethod, setPaymentMethod] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [ip, setIp] = useState('');

  const [personalInfoForm, setPersonalInfoForm] = useState({
    cartName: '',
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    phoneNumber: '',
    ipAddress: '203.88.141.230',
    countryCode: 'DE',
    language: 'de'
  });

  const [paymentTokens, setPaymentTokens] = useState({});

  // Validation
  const stepTwoValidations = Yup.lazy((values) => {
    let validationSchema = {};
    if (values.chargeTo === appTopUpTo.OTHER) {
      validationSchema = {
        ...validationSchema,
        phone: formValidation({
          required: 'nc_topup_nr_input_code_err',
          regex: /^\d{10,12}$/,
          validErrorMessage: 'nc_topup_nr_input_code_err'
        })
      };
    }
    if (values.isDirectProduct === 'YES') {
      validationSchema = {
        ...validationSchema,
        productAmount: formValidation({ required: t('nc_topup_amount_err') })
      };
    }
    validationSchema = {
      ...validationSchema,
      paymentMethodName: formValidation({ required: t('nc_topup_method_err') }),
      terms: formValidation({
        type: DEFAULT_FIELD_VALIDATION_TYPE.BOOLEAN,
        required: t('nc_topup_terms_err')
      })
    };
    return Yup.object().shape(validationSchema);
  });

  // Function
  // Stepper Functions
  const jumpStep = (step) => setCurrentStep(step);
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  // get Ip Address
  const getIp = async () => {
    const ipResponse = await axios.get(`${env.REACT_APP_SERVER}api/ip/address`);
    if (ipResponse) {
      setIp(ipResponse.data.ip);
    }
  };

  const getOrderStatus = async () => {
    try {
      const queryParamsData = {
        paymentToken: queryParams.get('paymentTokenId'),
        orderNumber: queryParams.get('orderNumber'),
        cartName: queryParams.get('cartName')
      };

      if (queryParamsData.paymentToken || queryParamsData.orderNumber || queryParamsData.cartName) {
        const { data = {}, success } = await onPaymentCommitOrder(queryParamsData);
        if (data && success) {
          // showAlert({ type: appAlert.SUCCESS, message: 'Payment Successfull..' });
          await storage.removeItem(appStorage.CART_NAME);
          await storage.removeItem(appStorage.ORDER_NUMBER);
        } else {
          // showAlert({ type: appAlert.DANGER, message: 'Payment UnSuccessfull..' });
          await storage.removeItem(appStorage.CART_NAME);
          await storage.removeItem(appStorage.ORDER_NUMBER);
        }
      }
      return true;
    } catch (error) {
      console.log(error);
      await storage.removeItem(appStorage.CART_NAME);
      await storage.removeItem(appStorage.ORDER_NUMBER);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  // Payment Products
  const getPaymentProducts = async () => {
    try {
      // setIsPaymentProductLoading(true);
      const {
        data: { items = [] }
      } = await onPaymentProduct();
      if (items.length > 0) {
        const allProducts = [];
        const fixedProducts = items.filter((product) => product.minAmount === product.maxAmount);
        const directProducts = items.filter((product) => product.minAmount !== product.maxAmount);
        if (env.REACT_APP_T_PAY_DIRECT_ID) {
          allProducts.push({
            id: Number(env.REACT_APP_T_PAY_DIRECT_ID),
            parentId: 887187,
            objectType: 'object',
            productType: 'PREPAID',
            optionType: 'autoTopup',
            minAmount: 0.01,
            maxAmount: 0.01,
            currency: 'EUR',
            amount: 0.01
          });
        }
        if (env.REACT_APP_T_PAY_AUTO_LOW_ID) {
          allProducts.push({
            id: Number(env.REACT_APP_T_PAY_AUTO_LOW_ID),
            parentId: 887187,
            objectType: 'object',
            productType: 'LOW_BALANCE',
            optionType: 'autoTopup',
            minAmount: 0.01,
            maxAmount: 0.01,
            currency: 'EUR',
            amount: 0.01
          });
        }
        allProducts.push(...fixedProducts, ...directProducts);

        setPaymentProducts(allProducts);
        setIsPaymentProductLoading(false);
        return allProducts;
      }
      // setIsLoading(false);
      return items;
    } catch (error) {
      console.log(error);
      setIsPaymentProductLoading(false);
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const getPaymentMethod = async () => {
    try {
      // setIsPaymentMethodLoading(true);
      const {
        data: { paymentMethods = {} }
      } = await onPaymentMethod();
      // eslint-disable-next-line prefer-const
      let payments = [];
      if (paymentMethods) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(paymentMethods)) {
          payments.push({ name: key, ...value });
          if (key === appPaymentMethods.CREDIT_CARD) {
            payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
          }
        }
        setPaymentMethod(payments);
        setIsPaymentMethodLoading(false);
      }
      return payments;
    } catch (error) {
      console.log(error);
      setIsPaymentMethodLoading(false);
      setIsLoading(false);
      return false;
    }
  };

  const getAutoTopUpData = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onPaymentToken();

      if (success && data) {
        // setPaymentTokens(data?.paymentTokens.reverse());
        // setPaymentTokens([].reverse());
        setPaymentTokens(
          data?.paymentTokens.filter((item) => item.status === 'confirmed').reverse()
        );
      } else {
        setPaymentTokens([]);
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // setIsGenericError(true);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onAutoTopupQuerycall = async () => {
    try {
      // setIsLoading(true);
      const { data, success } = await onPaymentAutoTopupQuery({
        autoTopupTypes: [appPaymentProductType.LOW_BALANCE, appPaymentProductType.RECURRING],
        statuses: ['ENABLED']
      });
      if (data && success) {
        if (data?.rows?.length > 0) {
          setAutoTopUpQueryList(data?.rows);
        } else {
          setAutoTopUpQueryList([]);
        }
      } else {
        setAutoTopUpQueryList([]);
      }
      // setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  // Payment Authentication
  const ssoPaymentToken = async ({ signupId, alphacommHash, msisdn }) => {
    try {
      const ssoPaymentTokenParams = {
        clientId: signupId,
        hashCode: alphacommHash,
        language: 'de',
        msisdn,
        pageId: 'home'
      };
      const { data = {}, success } = await onSsoPaymentToken(ssoPaymentTokenParams);
      if (data && success) {
        setAlphaComToken(data.token);
        setPaymentAuthData(ssoPaymentTokenParams);
        // await getAutoTopUpData();
        // await onAutoTopupQuerycall();
        // setTimeout(() => {
        //   getPaymentProducts();
        // }, 100);
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log('sso payment ---', error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const onDirectProductSubmit = async (values) => {
    try {
      // showLoader();
      // eslint-disable-next-line no-unused-vars
      const { otherAmount, productId } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      if (selectedProduct) {
        // let
      }
      return true;
    } catch (error) {
      console.log(error);
      // showAlert({
      //   type: appAlert.ERROR,
      //   message: t(error?.error[0]?.messageBody)
      // });
      return false;
    }
  };

  const onCheckoutTopup = async ({ values, cartNameRes, productType }) => {
    try {
      setIsLoading(true);
      const inputParams = {
        cartName: cartNameRes,
        deliveryAddress: {
          email: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          street: values.street,
          houseNumber: values.houseNum,
          zip: values.zip,
          city: values.city,
          countryCode: 'DE',
          phoneNumber: values.number,
          // ipAddress: ip ? ip : '0.0.0.0',
          ipAddress: '203.88.141.230',
          language: 'de'
        }
      };

      const { success } = await onPaymentCheckoutAddress(inputParams);

      if (success) {
        const {
          data: { paymentMethods = {} }
        } = await onPaymentMethod();
        // eslint-disable-next-line prefer-const
        let payments = [];
        if (paymentMethods) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(paymentMethods)) {
            // console.log({ productType, value });
            if (
              ((productType === appPaymentProductType.RECURRING ||
                productType === appPaymentProductType.LOW_BALANCE) &&
                value.paymentTokenEnabled) ||
              ((productType === appPaymentProductType.DIRECT ||
                productType === appPaymentProductType.PREPAID) &&
                value.onceOffEnabled)
            ) {
              payments.push({ name: key, ...value });
              if (key === appPaymentMethods.CREDIT_CARD) {
                payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
              }
            }
          }

          setPaymentMethod(payments);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: `Error in request.`
        // });
        setIsLoading(false);
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onSsoTokenCall = async () => {
    const { alphacommHash, msisdn, signupId } = customerData;
    if (alphacommHash && msisdn && signupId) {
      setIsLoading(true);
      const res = await ssoPaymentToken({ alphacommHash, msisdn, signupId });
      setIsLoading(false);
      return res;
    }
    return false;
  };

  const onSsoTokenCallWithQuery = async () => {
    const { alphacommHash, msisdn, signupId } = customerData;
    if (alphacommHash && msisdn && signupId) {
      setIsLoading(true);
      await ssoPaymentToken({ alphacommHash, msisdn, signupId });
      await onAutoTopupQuerycall();
      await getAutoTopUpData();
      setIsLoading(false);
    }
  };

  const onDeletePaymentToken = async (deleteAutoTopup) => {
    try {
      setIsLoading(true);
      const { success, data } = await onPaymentTokenDelete({
        id: deleteAutoTopup.id
      });

      if (success && data) {
        //   // hideLoader();
        //   setDeleteTopupSuccessModal(true);
      }
      await onSsoTokenCall();
      await getAutoTopUpData();
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onDeleteAutoTopUp = async (deleteAutoTopup) => {
    try {
      setIsLoading(true);
      const { success, data } = await onPaymentAutoTopupDelete({
        autoTopupType: deleteAutoTopup.autoTopupType,
        brand: deleteAutoTopup.brand,
        autoTopupId: deleteAutoTopup.autoTopupId
      });

      if (success && data) {
        // hideLoader();
      }
      // hideLoader();
      await onSsoTokenCallWithQuery();
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onAutoTopupSubmit = (values) => {
    console.log('AutoTopup Submit', values);
  };

  const setCustomerData = () => {
    const { msisdn } = customerData;
    const {
      email: { emailAddress = '' } = {},
      firstName,
      lastName,
      address: { street = '', houseNumber = '', city = '', zip = '' } = {}
    } = personalData;
    if (msisdn && emailAddress) {
      const phone = msisdn.charAt(0) === '0' ? msisdn.substring(1) : msisdn;
      setTopUpForm({
        ...topUpForm,
        phone: `+49${phone}`
        // confirmPhone: `+49${phone}`
      });
      setPersonalInfoForm({
        ...personalInfoForm,
        phoneNumber: `+49${phone}`,
        email: emailAddress,
        firstName,
        lastName,
        street,
        houseNumber,
        city,
        zip
      });
    }
  };

  const onLoad = async () => {
    try {
      // TODO: Exten it with api call to fetch all voucher history.
      // console.log('Loading AlphaComm Online TopUp');
      setIsLoading(true);
      const { msisdn = false } = customerData;
      if (!msisdn) {
        await getCustomerData();
      } else {
        setCustomerData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    // await onStepTwoSubmit(values);
  };

  const onAddToCart = async (values) => {
    try {
      const { chargeTo, phone, productAmount, productId, type, isDirectProduct } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      let option = {};

      if (type === appTopUpType.AUTOMATIC) {
        option = {
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn) // simActivationDetails?.activationData?.msisdn,
              : gettingNumberForGerman(phone),
          optionType: selectedProduct.optionType,
          amount: productAmount || selectedProduct.amount,
          lowBalanceOption: {
            lowBalanceLimit: 5,
            tariffEnabled: true
          }
        };
      }

      if (type === appTopUpType.DIRECT) {
        option = {
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn)
              : gettingNumberForGerman(phone),
          optionType: selectedProduct.optionType
        };

        if (isDirectProduct === 'YES') {
          option.amount = productAmount || selectedProduct.amount;
        }
      }

      const data = {
        items: [
          {
            productId: selectedProduct.id,
            option
          }
        ]
      };

      return await onPaymentAddToCart(data);
    } catch (error) {
      console.log('ADD_TO_CART_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutAddress = async (values, _cartName) => {
    try {
      const { chargeTo, phone, productAmount, productId, type } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      await storage.setItem(appStorage.TOPUP_AMOUNT, productAmount || selectedProduct.amount);
      await storage.setItem(appStorage.TOPUP_TYPE, type);
      // if (customerData && Object.keys(customerData).length > 0) {
      // Creating the object to pass in API
      // eslint-disable-next-line prefer-const

      await storage.setItem(appStorage.CART_NAME, _cartName);
      setTopUpForm({ ...topUpForm, cartName: _cartName });
      const checkoutAddressPayload = {
        cartName: _cartName,
        deliveryAddress: {
          email: personalData?.email?.emailAddress,
          firstname: personalData?.firstName,
          lastname: personalData?.lastName,
          street: personalData?.address?.street,
          houseNumber: personalData?.address?.houseNumber,
          zip: personalData?.address?.zip,
          city: personalData?.address?.city,
          countryCode: 'DE',
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn)
              : gettingNumberForGerman(phone),
          ipAddress: ip ? ip : '0.0.0.0',
          language: 'de'
        }
      };
      return await onPaymentCheckoutAddress(checkoutAddressPayload);
    } catch (error) {
      console.log('CHECKOUT_ADDRESS_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutStartPayment = async (values, _cartName) => {
    try {
      const { type, paymentMethodName } = values;

      const paymentName =
      paymentMethodName === appPaymentMethods.AMERICAN_EXPRESS
        ? appPaymentMethods.CREDIT_CARD
        : paymentMethodName;

      const inputParams = {
        cartName: _cartName,
        paymentMethod: paymentName,
        returnUrl: `${env.REACT_APP_SHOPPING_RETURN_URL}${appRoute.TOPUP_ONLINE_CALLBACK}`,
        savePayment: type === appTopUpType.AUTOMATIC
      };
      return await onCheckoutPaymentStart(inputParams);
    } catch (error) {
      console.log('START_PAYMENT_ERROR:', error);
      throw error;
    }
  };

  // Steps Functions
  const onStepOneSubmit = async () => {
    try {
      setPaymentMethod([]);
      setPaymentProducts([]);
      setIsLoading(true);
      setIsPaymentMethodLoading(true);
      setIsPaymentProductLoading(true);
      // console.log('Step One Submit', values);
      nextStep();
      const success = await onSsoTokenCall();
      if (success) {
        await getPaymentMethod();
        await getPaymentProducts();
        setIsLoading(false);
      } else {
        // TODO: Show Error
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
    }
  };

  const onStepTwoSubmit = async (values) => {
    try {
      setIsLoading(true);
      // console.log('Step Two Submit', values);
      setTopUpForm({
        ...topUpForm,
        ...values
      });
      const {
        data: { cartName: responseCartName = false },
        success: successAddToCart
      } = await onAddToCart(values);

      if (responseCartName && successAddToCart) {
        setCartName(responseCartName);
        const { success: successCheckoutAddress } = await onCheckoutAddress(
          values,
          responseCartName
        );
        if (successCheckoutAddress) {
          const {
            data: { orderNumber, payerUrl },
            success
          } = await onCheckoutStartPayment(values, responseCartName);
          if (payerUrl && success) {
            await storage.setItem(appStorage.ORDER_NUMBER, orderNumber);
            window.open(payerUrl, '_self');
            setIsLoading(false);
          }
        } else {
          // TODO: Show generic error
          setIsLoading(false);
          setIsGenericError(true);
          showAlert({
            type: appAlert.ERROR,
            message: t('nc_generic_err_txt')
          });
          setIsLoading(false);
        }
      } else {
        // TODO: Show generic error
        setIsLoading(false);
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t('nc_generic_err_txt')
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
    }
  };

  const onStepThreeSubmit = async (values) => {
    try {
      setIsLoading(true);
      // console.log('Step Three Submit', values);
      setTopUpForm({
        ...topUpForm,
        ...values
      });
      const success = await onSsoTokenCall();
      // await getPaymentProducts();
      if (success) {
        nextStep();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onStepFourSubmit = async (values) => {
    try {
      setIsLoading(true);
      setPaymentMethod([]);
      const { productAmount, productId, productType, type } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      if (selectedProduct) {
        // eslint-disable-next-line prefer-const
        let data = {
          productId: selectedProduct.id,
          option: {
            phoneNumber:
              values.chargeTo === appTopUpTo.SELF
                ? gettingNumberForGerman(customerData.msisdn) // simActivationDetails?.activationData?.msisdn,
                : gettingNumberForGerman(values.phone),
            optionType: selectedProduct.optionType,
            // amount: values.productAmount || selectedProduct.amount,
            lowBalanceOption: {
              lowBalanceLimit:
                values.periodType === appAutoTopUpPeriodType.LOW_BALANCE
                  ? 5
                  : getTopUpWithoutAmount(
                      type,
                      type === appTopUpType.AUTOMATIC ? values.periodType : '',
                      paymentProducts
                    ).minAmount,
              tariffEnabled: isAutoLowEnabled
            },
            recurringOption: {}
          }
        };

        // eslint-disable-next-line prefer-const
        let days = {
          value: ''
        };

        if (values.periodDay) {
          days.value = values.periodDay;
        }

        //   // TODO: Refactor with proper fix
        // const { type } = values;
        const inputParams = generateParamsForAddToCart(
          type,
          values.isDirectProduct === 'NO' ? '' : productAmount,
          data,
          type === appTopUpType.AUTOMATIC ? values.periodType : '',
          values.isDirectProduct === 'NO' ? productAmount : '',
          values.periodDay !== '' ? days : '',
          isAutoLowEnabled
        );

        const topupAmount = productAmount ? productAmount : selectedProduct.amount.toString();

        const {
          data: { cartName: responseCartName = false },
          success
        } = await onPaymentAddToCart(inputParams);
        if (responseCartName && success) {
          setCartName(responseCartName);
          if (type === appAutoTopUpType.UNIQUE) {
            sessionStorage.setItem(appStorage.TOPUP_AMOUNT, topupAmount);
            sessionStorage.setItem(appStorage.TOPUP_TYPE, appAutoTopUpType.UNIQUE);
          } else {
            sessionStorage.setItem(appStorage.TOPUP_TYPE, appAutoTopUpType.AUTOMATIC);
          }
          if (customerData && Object.keys(customerData).length > 0) {
            // Creating the object to pass in API
            // eslint-disable-next-line prefer-const
            let userData = {
              email: personalData?.email?.emailAddress,
              firstName: personalData?.firstName,
              lastName: personalData?.lastName,
              street: personalData?.address?.street,
              houseNum: personalData?.address?.houseNumber,
              zip: personalData?.address?.zip,
              city: personalData?.address?.city,
              country: personalData?.address?.countryName,
              number: gettingNumberForGerman(customerData.msisdn)
            };
            sessionStorage.setItem(appStorage.CART_NAME, responseCartName);
            // setTopUpForm({ ...topUpForm, cartName: responseCartName });
            setPersonalInfoForm({ ...personalInfoForm, cartName: responseCartName });
            nextStep();
            onCheckoutTopup({ values: userData, cartNameRes: responseCartName, productType });
          } else {
            // showAlert({
            //   type: appAlert.ERROR,
            //   message: 'Bitter request processed. Try again later.'
            // });
            setIsLoading(false);
          }
          // hideLoader();
        }
      }
      return values;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const onStepFiveSubmit = async (values) => {
    const paymentName =
      values.paymentMethodName === appPaymentMethods.AMERICAN_EXPRESS
        ? appPaymentMethods.CREDIT_CARD
        : values.paymentMethodName;
    // Process payment methods & store all details in the storage;
    try {
      setIsLoading(true);
      const inputParams = {
        cartName,
        paymentMethod: paymentName,
        returnUrl: `${env.REACT_APP_SHOPPING_RETURN_URL}${appRoute.TOPUP_ONLINE_CALLBACK}`,
        savePayment: sessionStorage.getItem(appStorage.TOPUP_TYPE) === appAutoTopUpType.AUTOMATIC
      };
      const {
        data: { orderNumber, payerUrl },
        success
      } = await onCheckoutPaymentStart(inputParams);
      if (payerUrl && success) {
        setIsLoading(false);
        sessionStorage.setItem(appStorage.ORDER_NUMBER, orderNumber);
        window.open(payerUrl, '_self');
      }
      return { payerUrl };
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });

      return false;
    }
  };

  // Hooks
  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    // setCustomerData();
  }, [customerData, personalData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // initial constants
      topUpFormInitialValue,
      // States
      isLoading,
      setIsLoading,
      isPaymentProductLoading,
      setIsPaymentProductLoading,
      isPaymentMethodLoading,
      setIsPaymentMethodLoading,
      currentStep,
      setCurrentStep,
      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,

      paymentProducts,
      setPaymentProducts,

      isAutoLowEnabled,
      setIsAutoLowEnabled,

      cartName,
      setCartName,
      paymentAuthData,
      setPaymentAuthData,

      paymentTokens,
      setPaymentTokens,

      autoTopUpQueryList,
      setAutoTopUpQueryList,
      alphaComToken,
      setAlphaComToken,

      paymentMethod,
      setPaymentMethod,

      // Validations
      stepTwoValidations,

      // Functions
      onAutoTopupSubmit,
      onDirectProductSubmit,
      onAutoTopupQuerycall,
      getAutoTopUpData,
      onLoad,
      getOrderStatus,
      onDeletePaymentToken,
      onDeleteAutoTopUp,
      onSubmit,
      nextStep,
      prevStep,
      jumpStep,
      getPaymentMethod,

      ssoPaymentToken,
      onSsoTokenCall,
      onSsoTokenCallWithQuery,

      onStepOneSubmit,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit,
      onStepFiveSubmit
    }),
    [
      // initial constants
      topUpFormInitialValue,
      // States
      isLoading,
      setIsLoading,
      isPaymentProductLoading,
      setIsPaymentProductLoading,
      isPaymentMethodLoading,
      setIsPaymentMethodLoading,
      currentStep,
      setCurrentStep,
      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,

      paymentProducts,
      setPaymentProducts,

      isAutoLowEnabled,
      setIsAutoLowEnabled,

      cartName,
      setCartName,
      paymentAuthData,
      setPaymentAuthData,

      paymentTokens,
      setPaymentTokens,

      autoTopUpQueryList,
      setAutoTopUpQueryList,
      alphaComToken,
      setAlphaComToken,

      paymentMethod,
      setPaymentMethod,

      // Validations
      stepTwoValidations,

      // Functions
      onAutoTopupSubmit,
      onDirectProductSubmit,
      onAutoTopupQuerycall,
      getAutoTopUpData,
      onLoad,
      getOrderStatus,
      onDeletePaymentToken,
      onDeleteAutoTopUp,
      onSubmit,
      nextStep,
      prevStep,
      jumpStep,
      getPaymentMethod,

      ssoPaymentToken,
      onSsoTokenCall,
      onSsoTokenCallWithQuery,

      onStepOneSubmit,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit,
      onStepFiveSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <OnlineTopUpContext.Provider value={contextPayload}>{children}</OnlineTopUpContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOnlineTopUp = () => useContext(OnlineTopUpContext);

export default OnlineTopUpProvider;
