import React from 'react';
import './TariffBadge.scss';

export function TariffBadge({ bgColor, tariffName }) {
  return (
    <>
      <div className="tariff-badge nc-doomsday-footnote" style={{ backgroundColor: bgColor }}>
        {tariffName}
      </div>
    </>
  );
}

export default TariffBadge;
