/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import Icon from '@core/Utils/Icons';
import './ButtonPrimary.scss';
import { appButtonTypes } from '@utils/globalConstant';

export function ButtonPrimary({
  // customClass = '',
  children,
  icon,
  iconHover = false,
  label,
  type,
  buttonType = appButtonTypes.PRIMARY.DEFAULT,
  shadow = true,
  onClick: onClickProp,
  isLoading,
  ...restProps
}) {
  // State & Refs
  const btnRef = useRef(null);
  const [isHover, setIsHover] = useState(false);

  // Functions
  const onClick = (event) => {
    if (onClickProp && !isLoading) {
      onClickProp(event);
    }
    // Add animation class to button
    // btnRef.current.classList.add('animate__animated', 'animate__rubberBand', 'animate__slow');
    // Remove animation class after animation ends
    // btnRef.current.addEventListener('animationend', () => {
    //   btnRef.current.classList.remove('animate__animated', 'animate__rubberBand');
    // });
  };

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={`${buttonType} ${shadow ? 'shadow-sm' : ''} position-relative`}
      ref={btnRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...restProps}>
      {isLoading && (
        <div className="position-absolute top-4 z-1 w-100 ms-n7 px-2">
          <div className="btn-loader w-100">
            <div
              style={{ width: '1rem', height: '1rem' }}
              className="spinner-border"
              role="status"
            />
          </div>
        </div>
      )}
      <>
        {label && (
          <div
            className={`btn-label ${isLoading ? 'invisible' : ''}`}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {children && (
          <div className={`btn-children ${isLoading ? 'invisible' : ''}`}>{children}</div>
        )}
        {icon && (
          <Icon
            className={`btn-icon ${isLoading ? 'invisible' : ''}`}
            name={isHover && iconHover ? iconHover : icon}
            height={24}
          />
        )}
      </>
    </button>
  );
}

export default ButtonPrimary;
