import React from 'react';
import PropTypes from 'prop-types';

// import './style.scss';

export function FullScreenModal({ isOpen, children }) {
  return isOpen ? (
    <div className="modal d-block fade show">
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content">
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

FullScreenModal.propTypes = {
  // sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool
};

FullScreenModal.defaultProps = {
  // sx: {},
  isOpen: false
};

export default FullScreenModal;
