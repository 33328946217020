import React from 'react';

import { Icons } from '@core/Utils/Icons';
import './NumbersCircle.scss';

export function NumbersCircle({ step, currentStep, onClick, isActive, isCompleted }) {
  return (
    <div className="stepper-item">
      <li
        className={`nc-doomsday-copy stepper-bg ${isActive ? 'active' : ''} ${
          isCompleted ? 'completed' : ''
        } ${isCompleted && currentStep - 1 === step - 1 ? 'animate__animated animate__flip' : ''}`}
        onClick={onClick}
        aria-hidden>
        {isCompleted ? <Icons name="checked" width={28} height={28} className="p-1" /> : step}
      </li>
    </div>
  );
}

export default NumbersCircle;
