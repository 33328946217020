/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

// import { useAlert } from '@context/Utils';
import { appPassCodes, appTariffOptionSuccessType } from '@utils/globalConstant';
import { useTariff } from '../Tariff';

export const SpeedOnContext = createContext({});

export function SpeedOnContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [passOffers, setPassOffers] = useState([]);
  const [dayFlat, setDayFlat] = useState([]);
  const [speedOns, setSpeedOns] = useState([]);
  // Context
  // const navigate = useNavigate();
  const { staticContentData } = useStaticContent();
  // const { showAlert } = useAlert();
  const { onPassoffer, onBookOption } = useMobileOne();
  const { getCustomerData, customerBalance } = useCustomer();
  const { setTariffOptionSuccess } = useTariff();
  // const { env } = useAppConfig();

  // Validations

  // Functions
  const onLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      // if (TDM_2) {
      const { data, success, status } = await onPassoffer();
      if (status === StatusCodes.OK || success) {
        let newData = data;
        for (let elem of newData) {
          let price = elem?.price?.grossAmount ? elem.price.grossAmount / 10000 : 0;
          // console.log('totalBalance----------------', customerBalance);
          if (price < customerBalance.totalBalance) {
            elem.hasLowBalance = false;
          } else {
            elem.hasLowBalance = true;
          }
          // elem.price = price;
        }
        setPassOffers(newData);
      }
      // }
      // hideLoader();
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Book speedOn if possible
  const bookSpeedOn = async (speedonId) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, status, success } = await onBookOption(speedonId);
      console.log({ data, success, status });

      await getCustomerData();
      if (success) {
        setTariffOptionSuccess({ isSuccessful: true, type: appTariffOptionSuccessType.SPEED_ON });
        // showAlert({
        //   type: appAlert.SUCCESS,
        //   message: 'Successfully updated tariff'
        // });
      } else {
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: 'Error in processing, try again after sometime.'
        // });
      }
      setIsLoading(false);
      // hideLoader();
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  const splitPassOffers = () => {
    const { speedOnsPassCodes, dayFlatPassCodes } =
      staticContentData?.nr_passCodesSettings || appPassCodes;
    const findDayFlat = passOffers.filter((offer) => dayFlatPassCodes.includes(offer.passCode));
    if (findDayFlat) {
      setDayFlat(findDayFlat);
    } else {
      setDayFlat([]);
    }

    const findSpeedOns = passOffers.filter((offer) => speedOnsPassCodes.includes(offer.passCode));
    if (findSpeedOns) {
      setSpeedOns(findSpeedOns);
    } else {
      setSpeedOns([]);
    }
  };

  // Hooks
  useEffect(() => {
    // const { msisdn, ...restCustomerData } = customerData;
    const totalBalance = customerBalance.totalBalance ? customerBalance.totalBalance : null;
    if (totalBalance !== null) {
      onLoad();
    }
  }, [customerBalance]);

  useEffect(() => {
    if (passOffers.length > 0 && staticContentData) {
      splitPassOffers();
    }
  }, [passOffers, staticContentData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      onLoad,
      passOffers,
      setPassOffers,
      dayFlat,
      setDayFlat,
      speedOns,
      setSpeedOns,

      bookSpeedOn
    }),
    [
      // States
      isLoading,
      setIsLoading,
      onLoad,
      passOffers,
      setPassOffers,
      dayFlat,
      setDayFlat,
      speedOns,
      setSpeedOns,

      bookSpeedOn
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <SpeedOnContext.Provider value={contextPayload}>{children}</SpeedOnContext.Provider>;
}
SpeedOnContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useSpeedOn = () => useContext(SpeedOnContext);

export default SpeedOnContext;
