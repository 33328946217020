import { createContext, useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { appAlert } from '@utils/globalConstant';

// A context to load all app configuration from server
const AlertContext = createContext();

// The top level component that will wrap our app's core features
export const AlertProvider = function ({ children }) {
  const [alert, setAlert] = useState({});
  // For showing generic error screen only
  const [isGenericError, setIsGenericError] = useState(false);
  
  const showAlert = (al) => {
    if (Object.keys(al).length > 0) {
      const newAlerts = { type: al?.type || appAlert.ERROR, message: al?.message };
      setAlert(newAlerts);
    }
  };

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      alert,
      setAlert,
      showAlert,
      isGenericError,
      setIsGenericError
    }),
    [alert, setAlert, showAlert, isGenericError, setIsGenericError]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <AlertContext.Provider value={contextPayload}>{children}</AlertContext.Provider>;
};

AlertProvider.propTypes = {
  children: PropTypes.node
};

AlertProvider.defaultProps = {
  children: null
};

export default AlertProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAlert = () => useContext(AlertContext);
