/**
 * All Context will be merged to child, to clear up app.
 */
import React, { createContext, useEffect, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { StaticProvider } from '@dom-digital-online-media/dom-static-content-sdk';
import { AppManager, useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';
import { AuthManager } from '@dom-digital-online-media/dom-auth-sdk';
import { MoManager } from '@dom-digital-online-media/dom-mo-sdk';
import { PaymentManager } from '@dom-digital-online-media/dom-alphacomm-sdk';

import { AlertProvider, LayoutProvider, LoaderProvider } from '@context/Utils';
import { MobileOneProvider } from '@context/MobileOne/default';
import { AlphaCommProvider } from '@context/AlphaComm/default';
import { generateRandomValue, storageKeys } from '@utils/globalConstant';
import { AxiosManager } from './AxiosManager';

// eslint-disable-next-line react/prop-types
export const ContextManagerContext = createContext({});

// eslint-disable-next-line react/prop-types
export function ContextLoader({ config, children }) {
  // Load configuration from server
  // Context
  const { env, loading } = useAppConfig();

  // State
  const [isEnvLoaded, setEnvLoaded] = useState(false);

  const contextPayload = useMemo(() => ({ config: { ...config, env } }), [config]);

  // Hooks

  // Validate environments are loaded correctly and then load children
  useEffect(() => {
    // console.log({ env, isEnvLoaded, loading });
    if (!loading && env.REACT_APP_MO_URL && env.REACT_APP_MO_USER_URL) {
      setEnvLoaded(true);
    }
  }, [env, loading]);

  return isEnvLoaded ? (
    <>
      {/* Global App Loader Context Provider */}
      <LoaderProvider>
        {/* Global App Alert Context Provider */}
        <AlertProvider>
          {/* TODO: Config it with proper props handling */}
          <ContextManagerContext.Provider value={contextPayload}>
            <StaticProvider
              {...{
                config: { ...config, env }
              }}>
              <PaymentManager
                {...{
                  config: { ...config, env }
                }}>
                {/* Global Authentication Context Loader */}
                <AuthManager
                  {...{
                    config: { ...config, env }
                  }}>
                  {/* Global MobileOne Provider */}
                  <MoManager
                    {...{
                      config: { ...config, env }
                    }}>
                    {/* Global Alphacomm Provider */}
                    <PaymentManager
                      {...{
                        config: { ...config, env }
                      }}>
                      {/* App API Request Manager */}
                      <AxiosManager
                        {...{
                          config: { ...config, env }
                        }}>
                        {/* App MobileOne Context Provider */}
                        <MobileOneProvider
                          {...{
                            config: { ...config, env }
                          }}>
                          {/* App Alphacomm Context Provider */}
                          <AlphaCommProvider
                            {...{
                              config: { ...config, env: { ...process.env, ...env } }
                            }}>
                            {/* Global App Layout Context Provider */}
                            <LayoutProvider>{children}</LayoutProvider>
                          </AlphaCommProvider>
                        </MobileOneProvider>
                      </AxiosManager>
                    </PaymentManager>
                  </MoManager>
                </AuthManager>
              </PaymentManager>
            </StaticProvider>
          </ContextManagerContext.Provider>
        </AlertProvider>
      </LoaderProvider>
    </>
  ) : (
    <>Loading...</>
  );
}

// eslint-disable-next-line react/prop-types
export function ContextManager({ children }) {
  // Constants
  // App Stroage & Env Config
  const config = {
    // env: appEnvMode.NODE_ENV_DEV === process.env.NODE_ENV ? { REACT_APP_SERVER: process.env.REACT_APP_SERVER } : { ...process.env },
    env: {
      REACT_APP_SERVER: process.env.REACT_APP_SERVER,
      REACT_APP_IS_CLIENT_LOGIN: process.env.REACT_APP_IS_CLIENT_LOGIN
    },
    storage: {
      getItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.getItem(key));
          } catch (e) {
            reject(e);
          }
        }),
      setItem: (key, value) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.setItem(key, value));
          } catch (e) {
            reject(e);
          }
        }),
      removeItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.removeItem(key));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedSetItem: (key, value) =>
        new Promise((resolve, reject) => {
          try {
            // resolve(setCookie(key, value));
            resolve(sessionStorage.setItem(key, value));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedGetItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            // resolve(getCookie(key));
            resolve(sessionStorage.getItem(key));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedRemoveItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            // if (key === appStorage.USER_AUTH_DATA) {
            //   removeCookie(key);
            //   // window.location.reload();
            // }
            resolve(sessionStorage.removeItem(key));
            // resolve(removeCookie(key));
          } catch (e) {
            reject(e);
          }
        })
    }
  };
  // #50218 - Load a new tag on every refresh
  useEffect(() => {
    async function generateLogTag() {
      const tag = generateRandomValue();
      await config.storage.setItem(storageKeys.X_LOG_TAG, tag);
    }
    generateLogTag();
  }, []);

  return (
    <>
      {/* Global App Context Loader */}
      <AppManager {...{ config }}>
        <ContextLoader {...{ config }}>{children}</ContextLoader>
      </AppManager>
    </>
  );
}

export const useConfig = () => useContext(ContextManagerContext);
ContextLoader.propTypes = {
  config: PropTypes.shape({
    env: PropTypes.shape({})
  }).isRequired
};

export default ContextManager;
