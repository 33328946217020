import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { PopupSupport } from '@core/PopupSupport';
import { appRoute } from '@utils/globalConstant';
import './style.scss';

export function ChangePasswordAlert({ open }) {
  // Constants

  // States
  const [openModal, setOpenModal] = useState(open);

  // Context
  const { t } = useStaticContent();
  const navigate = useNavigate();

  // Functions
  const onBackClick = () => {
    setOpenModal(!openModal);
    navigate(appRoute.ACCOUNT_PRIVATE_DATA_NEW_PASSWORD);

  };

  // Hooks

  return (
    <>
      <PopupSupport
        isOpen={openModal}
        // isCloseButton={false}
        onClose={() => setOpenModal(!openModal)}
        icon="passwordchangegreen"
        title={t('nc_login_otp_modal_hdl')}
        rightButtonLabel={t('nc_login_otp_modal_btn')}
        onRightClick={onBackClick}
        bodyText={t('nc_login_otp_modal_txt')}
      />
    </>
  );
}

export default ChangePasswordAlert;
