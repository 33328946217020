/**
 * This files includes default lookup calls data, as our api fails , we will serve this data into our apis
 * List of lookups api & its variable
 * /lookup/salutations      - DEFAULT_SALUTATION_DATA
 * /lookup/countries        - DEFAULT_COUNTRIES_DATA
 * /lookup/identity-types   - DEFAULT_IDENTITY_TYPES_DATA
 * /lookup/nationalities    - DEFAULT_NATIONALITIES_DATA
 * /lookup/mnp-prefixes     - DEFAULT_MPN_PROVIDER_DATA
 * /lookup/providers        - DEFAULT_PROVIDERS_DATA
 *
 *
 */

export const DEFAULT_SALUTATION_DATA = [
  {
    id: 3,
    value: 'Herr/Frau'
  },
  {
    id: 2,
    value: 'Frau'
  },
  {
    id: 1,
    value: 'Herr'
  }
];
export const DEFAULT_IDENTITY_TYPES_DATA = [
  {
    id: 11,
    value: 'Personalausweis'
  },
  {
    id: 15,
    value: 'Reisepass'
  },
  {
    id: 16,
    value: 'Aufenthaltserlaubnis'
  }
];
export const DEFAULT_COUNTRIES_DATA = [
  {
    id: 1,
    value: 'Deutschland'
  },
  {
    id: 2,
    value: 'Österreich'
  },
  {
    id: 1000,
    value: 'Afghanistan'
  },
  {
    id: 1001,
    value: 'Bahrein'
  },
  {
    id: 1002,
    value: 'Algerien'
  },
  {
    id: 1003,
    value: 'Kuwait'
  },
  {
    id: 1004,
    value: 'Libanon'
  },
  {
    id: 1005,
    value: 'Marokko'
  },
  {
    id: 1006,
    value: 'Saudi-Arabien'
  },
  {
    id: 1007,
    value: 'Ägypten'
  },
  {
    id: 1008,
    value: 'Jemen'
  },
  {
    id: 1009,
    value: 'Angola'
  },
  {
    id: 1010,
    value: 'Antigua und Barbuda'
  },
  {
    id: 1011,
    value: 'Argentinien'
  },
  {
    id: 1012,
    value: 'Armenien'
  },
  {
    id: 1013,
    value: 'Sudan'
  },
  {
    id: 1014,
    value: 'Australien'
  },
  {
    id: 1015,
    value: 'Aserbaidschan'
  },
  {
    id: 1016,
    value: 'Bahamas'
  },
  {
    id: 1017,
    value: 'Bangladesch'
  },
  {
    id: 1018,
    value: 'Barbados'
  },
  {
    id: 1019,
    value: 'Belize'
  },
  {
    id: 1020,
    value: 'Benin'
  },
  {
    id: 1021,
    value: 'Bhutan'
  },
  {
    id: 1022,
    value: 'Bolivien'
  },
  {
    id: 1023,
    value: 'Botsuana'
  },
  {
    id: 1024,
    value: 'Brasilien'
  },
  {
    id: 1025,
    value: 'Brunei Darussalam'
  },
  {
    id: 1026,
    value: 'Burkina Faso'
  },
  {
    id: 1027,
    value: 'Burundi'
  },
  {
    id: 1028,
    value: 'Kamerun'
  },
  {
    id: 1029,
    value: 'Kanada'
  },
  {
    id: 1030,
    value: 'Kap Verde'
  },
  {
    id: 1031,
    value: 'Chile'
  },
  {
    id: 1032,
    value: 'Nordkorea'
  },
  {
    id: 1033,
    value: 'Kolumbien'
  },
  {
    id: 1034,
    value: 'Komoren'
  },
  {
    id: 1036,
    value: 'Kongo'
  },
  {
    id: 1037,
    value: 'Costa Rica'
  },
  {
    id: 1038,
    value: 'Elfenbeinküste'
  },
  {
    id: 1039,
    value: 'Kuba'
  },
  {
    id: 1040,
    value: 'Vereinigte Arabische Emirate'
  },
  {
    id: 1041,
    value: 'Dschibuti'
  },
  {
    id: 1042,
    value: 'Dominica'
  },
  {
    id: 1043,
    value: 'Ecuador'
  },
  {
    id: 1044,
    value: 'El Salvador'
  },
  {
    id: 1045,
    value: 'Eritrea'
  },
  {
    id: 1046,
    value: 'Fidschi'
  },
  {
    id: 1047,
    value: 'Gabun'
  },
  {
    id: 1048,
    value: 'Gambia'
  },
  {
    id: 1049,
    value: 'Ghana'
  },
  {
    id: 1050,
    value: 'Grenada'
  },
  {
    id: 1051,
    value: 'Guatemala'
  },
  {
    id: 1052,
    value: 'Äquatorialguinea'
  },
  {
    id: 1053,
    value: 'Guinea-Bissau'
  },
  {
    id: 1054,
    value: 'Guinea'
  },
  {
    id: 1055,
    value: 'Guyana'
  },
  {
    id: 1056,
    value: 'Haiti'
  },
  {
    id: 1057,
    value: 'Honduras'
  },
  {
    id: 1058,
    value: 'Indien'
  },
  {
    id: 1059,
    value: 'Indonesien'
  },
  {
    id: 1060,
    value: 'Iran'
  },
  {
    id: 1061,
    value: 'Irak'
  },
  {
    id: 1062,
    value: 'Äthiopien'
  },
  {
    id: 1063,
    value: 'Jamaika'
  },
  {
    id: 1064,
    value: 'Kambodscha'
  },
  {
    id: 1065,
    value: 'Kasachstan'
  },
  {
    id: 1066,
    value: 'Kenia'
  },
  {
    id: 1067,
    value: 'Kiribati'
  },
  {
    id: 1068,
    value: 'Kirgisistan'
  },
  {
    id: 1069,
    value: 'Laos'
  },
  {
    id: 1070,
    value: 'Lesotho'
  },
  {
    id: 1071,
    value: 'Liberia'
  },
  {
    id: 1072,
    value: 'Libyen'
  },
  {
    id: 1073,
    value: 'Madagaskar'
  },
  {
    id: 1074,
    value: 'Malawi'
  },
  {
    id: 1075,
    value: 'Malaysia'
  },
  {
    id: 1076,
    value: 'Malediven'
  },
  {
    id: 1077,
    value: 'Mali'
  },
  {
    id: 1078,
    value: 'Marshallinseln'
  },
  {
    id: 1079,
    value: 'Mauritius'
  },
  {
    id: 1080,
    value: 'Mauretanien'
  },
  {
    id: 1081,
    value: 'Mexiko'
  },
  {
    id: 1082,
    value: 'Micronesien'
  },
  {
    id: 1083,
    value: 'Mosambik'
  },
  {
    id: 1084,
    value: 'Mongolei'
  },
  {
    id: 1085,
    value: 'Thailand'
  },
  {
    id: 1086,
    value: 'Myanmar'
  },
  {
    id: 1087,
    value: 'Namibia'
  },
  {
    id: 1088,
    value: 'Nauru'
  },
  {
    id: 1089,
    value: 'Türkei'
  },
  {
    id: 1090,
    value: 'Uganda'
  },
  {
    id: 1091,
    value: 'Vereinigte Staaten'
  },
  {
    id: 1092,
    value: 'Jordanien'
  },
  {
    id: 1093,
    value: 'Uruguay'
  },
  {
    id: 1094,
    value: 'Usbekistan'
  },
  {
    id: 1095,
    value: 'Vanuatu'
  },
  {
    id: 1096,
    value: 'Venezuela'
  },
  {
    id: 1097,
    value: 'Vietnam'
  },
  {
    id: 1098,
    value: 'Israel'
  },
  {
    id: 1099,
    value: 'Sambia'
  },
  {
    id: 1100,
    value: 'China'
  },
  {
    id: 1101,
    value: 'Simbabwe'
  },
  {
    id: 1102,
    value: 'Nepal'
  },
  {
    id: 1103,
    value: 'Neuseeland'
  },
  {
    id: 1104,
    value: 'Nicaragua'
  },
  {
    id: 1105,
    value: 'Niger'
  },
  {
    id: 1106,
    value: 'Nigeria'
  },
  {
    id: 1107,
    value: 'Japan'
  },
  {
    id: 1108,
    value: 'Pakistan'
  },
  {
    id: 1109,
    value: 'Palau'
  },
  {
    id: 1110,
    value: 'Panama'
  },
  {
    id: 1111,
    value: 'Papua-Neuguinea'
  },
  {
    id: 1112,
    value: 'Paraguay'
  },
  {
    id: 1113,
    value: 'Peru'
  },
  {
    id: 1114,
    value: 'Philippinen'
  },
  {
    id: 1115,
    value: 'Katar'
  },
  {
    id: 1116,
    value: 'Dominikanische Republik'
  },
  {
    id: 1117,
    value: 'Zentralafrikanische Republik'
  },
  {
    id: 1118,
    value: 'Ruanda'
  },
  {
    id: 1119,
    value: 'St. Kitts und Nevis'
  },
  {
    id: 1120,
    value: 'St. Lucia'
  },
  {
    id: 1121,
    value: 'Guernsey - Grossbritannien'
  },
  {
    id: 1122,
    value: 'Oman'
  },
  {
    id: 1123,
    value: 'Samoa American - USA'
  },
  {
    id: 1124,
    value: 'Sao Tomé und Principe'
  },
  {
    id: 1125,
    value: 'Senegal'
  },
  {
    id: 1126,
    value: 'Seychellen'
  },
  {
    id: 1127,
    value: 'Sierra Leone'
  },
  {
    id: 1128,
    value: 'Singapur'
  },
  {
    id: 1129,
    value: 'Salomonen'
  },
  {
    id: 1130,
    value: 'Somalia'
  },
  {
    id: 1131,
    value: 'Südafrika'
  },
  {
    id: 1132,
    value: 'Sri Lanka'
  },
  {
    id: 1133,
    value: 'Suriname'
  },
  {
    id: 1134,
    value: 'Syrien'
  },
  {
    id: 1135,
    value: 'Swasiland'
  },
  {
    id: 1136,
    value: 'Tadschikistan'
  },
  {
    id: 1137,
    value: 'Südkorea'
  },
  {
    id: 1138,
    value: 'Taiwan - Province Of China'
  },
  {
    id: 1139,
    value: 'Tansania'
  },
  {
    id: 1140,
    value: 'Tschad'
  },
  {
    id: 1141,
    value: 'Togo'
  },
  {
    id: 1142,
    value: 'Tonga'
  },
  {
    id: 1143,
    value: 'Trinidad und Tobago'
  },
  {
    id: 1144,
    value: 'Tunesien'
  },
  {
    id: 1145,
    value: 'Turkmenistan'
  },
  {
    id: 1146,
    value: 'Tuvalu'
  },
  {
    id: 1147,
    value: 'Andorra'
  },
  {
    id: 1148,
    value: 'Weißrußland'
  },
  {
    id: 1149,
    value: 'Belgien'
  },
  {
    id: 1150,
    value: 'Bosnien und Herzegowina'
  },
  {
    id: 1151,
    value: 'Bulgarien'
  },
  {
    id: 1152,
    value: 'Tschechische Republik'
  },
  {
    id: 1153,
    value: 'Vatikanstadt'
  },
  {
    id: 1154,
    value: 'Dänemark'
  },
  {
    id: 1155,
    value: 'Estland'
  },
  {
    id: 1156,
    value: 'Irland'
  },
  {
    id: 1157,
    value: 'Griechenland'
  },
  {
    id: 1158,
    value: 'Spanien'
  },
  {
    id: 1159,
    value: 'Frankreich'
  },
  {
    id: 1160,
    value: 'Georgien'
  },
  {
    id: 1161,
    value: 'Kroatien'
  },
  {
    id: 1162,
    value: 'Island'
  },
  {
    id: 1163,
    value: 'Italien'
  },
  {
    id: 1164,
    value: 'Zypern'
  },
  {
    id: 1165,
    value: 'Lettland'
  },
  {
    id: 1166,
    value: 'Liechtenstein'
  },
  {
    id: 1167,
    value: 'Litauen'
  },
  {
    id: 1168,
    value: 'Luxemburg'
  },
  {
    id: 1169,
    value: 'Ungarn'
  },
  {
    id: 1170,
    value: 'Mazedonien'
  },
  {
    id: 1171,
    value: 'Malta'
  },
  {
    id: 1172,
    value: 'Moldau'
  },
  {
    id: 1173,
    value: 'Monaco'
  },
  {
    id: 1174,
    value: 'Niederlande'
  },
  {
    id: 1175,
    value: 'Norwegen'
  },
  {
    id: 1176,
    value: 'Polen'
  },
  {
    id: 1177,
    value: 'Portugal'
  },
  {
    id: 1178,
    value: 'Rumänien'
  },
  {
    id: 1179,
    value: 'Russische Föderation'
  },
  {
    id: 1180,
    value: 'San Marino'
  },
  {
    id: 1181,
    value: 'Albanien'
  },
  {
    id: 1182,
    value: 'Slowenien'
  },
  {
    id: 1183,
    value: 'Slowakei'
  },
  {
    id: 1184,
    value: 'Schweiz'
  },
  {
    id: 1185,
    value: 'Finnland'
  },
  {
    id: 1186,
    value: 'Schweden'
  },
  {
    id: 1187,
    value: 'Ukraine'
  },
  {
    id: 1188,
    value: 'Grossbritannien'
  },
  {
    id: 1189,
    value: 'Antarktika'
  },
  {
    id: 1190,
    value: 'Bermuda'
  },
  {
    id: 1191,
    value: 'Brit.Geb.Ind.Oz'
  },
  {
    id: 1192,
    value: 'Britische Jungferninseln - Grossbri'
  },
  {
    id: 1193,
    value: 'Cayman Islands'
  },
  {
    id: 1194,
    value: 'Christmas Island - Australien'
  },
  {
    id: 1195,
    value: 'Cocos Islands - Australien'
  },
  {
    id: 1196,
    value: 'Mayotte - Frankreich'
  },
  {
    id: 1197,
    value: 'Kongo, Dem. Republik'
  },
  {
    id: 1198,
    value: 'Cookinseln'
  },
  {
    id: 1199,
    value: 'Faröer - Dänemark'
  },
  {
    id: 1200,
    value: 'Falklands - Grossbritannien'
  },
  {
    id: 1201,
    value: 'Französisch Guinea - Frankreich'
  },
  {
    id: 1202,
    value: 'St. Lucia (VC)'
  },
  {
    id: 1203,
    value: 'Französisch Polynesien - Frankreich'
  },
  {
    id: 1204,
    value: 'Palästina'
  },
  {
    id: 1205,
    value: 'Gibraltar'
  },
  {
    id: 1206,
    value: 'Grönland - Dänemark'
  },
  {
    id: 1207,
    value: 'Guadeloupe - Frankreich'
  },
  {
    id: 1208,
    value: 'Guam - USA'
  },
  {
    id: 1209,
    value: 'Heard/McDon.Ins'
  },
  {
    id: 1210,
    value: 'Hongkong - Province Of China'
  },
  {
    id: 1211,
    value: 'Macao - Province Of China'
  },
  {
    id: 1212,
    value: 'Martinique - Frankreich'
  },
  {
    id: 1213,
    value: 'Montenegro'
  },
  {
    id: 1214,
    value: 'Montserrat - Britische Gebiete West'
  },
  {
    id: 1215,
    value: 'Niederländische Antillen - Niederla'
  },
  {
    id: 1216,
    value: 'Aruba - Niederlande'
  },
  {
    id: 1217,
    value: 'Neu-Kaledonien - Frankreich'
  },
  {
    id: 1218,
    value: 'Niue'
  },
  {
    id: 1219,
    value: 'Norfolk Islands - Australien'
  },
  {
    id: 1220,
    value: 'Osttimor'
  },
  {
    id: 1221,
    value: 'Puerto Rico - USA'
  },
  {
    id: 1222,
    value: 'Reunion - Frankreich'
  },
  {
    id: 1223,
    value: 'St. Helena - Südatlantik - Grossbri'
  },
  {
    id: 1224,
    value: 'Anguilla - Britische Gebiete West.'
  },
  {
    id: 1225,
    value: 'Saint- Pierre et Miquelon - Frankre'
  },
  {
    id: 1226,
    value: 'Tokela'
  },
  {
    id: 1227,
    value: 'Turks and Caicos Islands - Britisch'
  },
  {
    id: 1228,
    value: 'Jersey - Großbritannien'
  },
  {
    id: 1229,
    value: 'Isle Of Man - Grossbritannien'
  },
  {
    id: 1230,
    value: 'Amerikanische Jungferninseln - USA'
  },
  {
    id: 1231,
    value: 'Wallis et Futuna - Frankreich'
  },
  {
    id: 1232,
    value: 'Samoa'
  },
  {
    id: 1233,
    value: 'Serbien'
  },
  {
    id: 1234,
    value: 'Euro/Mastercard'
  },
  {
    id: 1235,
    value: 'Eguine'
  },
  {
    id: 1236,
    value: 'Kosovo'
  }
];
export const DEFAULT_NATIONALITIES_DATA = [
  {
    id: 1,
    value: 'Deutschland'
  },
  {
    id: 2,
    value: 'Österreich'
  },
  {
    id: 1000,
    value: 'Afghanistan'
  },
  {
    id: 1001,
    value: 'Bahrein'
  },
  {
    id: 1002,
    value: 'Algerien'
  },
  {
    id: 1003,
    value: 'Kuwait'
  },
  {
    id: 1004,
    value: 'Libanon'
  },
  {
    id: 1005,
    value: 'Marokko'
  },
  {
    id: 1006,
    value: 'Saudi-Arabien'
  },
  {
    id: 1007,
    value: 'Ägypten'
  },
  {
    id: 1008,
    value: 'Jemen'
  },
  {
    id: 1009,
    value: 'Angola'
  },
  {
    id: 1010,
    value: 'Antigua und Barbuda'
  },
  {
    id: 1011,
    value: 'Argentinien'
  },
  {
    id: 1012,
    value: 'Armenien'
  },
  {
    id: 1013,
    value: 'Sudan'
  },
  {
    id: 1014,
    value: 'Australien'
  },
  {
    id: 1015,
    value: 'Aserbaidschan'
  },
  {
    id: 1016,
    value: 'Bahamas'
  },
  {
    id: 1017,
    value: 'Bangladesch'
  },
  {
    id: 1018,
    value: 'Barbados'
  },
  {
    id: 1019,
    value: 'Belize'
  },
  {
    id: 1020,
    value: 'Benin'
  },
  {
    id: 1021,
    value: 'Bhutan'
  },
  {
    id: 1022,
    value: 'Bolivien'
  },
  {
    id: 1023,
    value: 'Botsuana'
  },
  {
    id: 1024,
    value: 'Brasilien'
  },
  {
    id: 1025,
    value: 'Brunei Darussalam'
  },
  {
    id: 1026,
    value: 'Burkina Faso'
  },
  {
    id: 1027,
    value: 'Burundi'
  },
  {
    id: 1028,
    value: 'Kamerun'
  },
  {
    id: 1029,
    value: 'Kanada'
  },
  {
    id: 1030,
    value: 'Kap Verde'
  },
  {
    id: 1031,
    value: 'Chile'
  },
  {
    id: 1032,
    value: 'Nordkorea'
  },
  {
    id: 1033,
    value: 'Kolumbien'
  },
  {
    id: 1034,
    value: 'Komoren'
  },
  {
    id: 1036,
    value: 'Kongo'
  },
  {
    id: 1037,
    value: 'Costa Rica'
  },
  {
    id: 1038,
    value: 'Elfenbeinküste'
  },
  {
    id: 1039,
    value: 'Kuba'
  },
  {
    id: 1040,
    value: 'Vereinigte Arabische Emirate'
  },
  {
    id: 1041,
    value: 'Dschibuti'
  },
  {
    id: 1042,
    value: 'Dominica'
  },
  {
    id: 1043,
    value: 'Ecuador'
  },
  {
    id: 1044,
    value: 'El Salvador'
  },
  {
    id: 1045,
    value: 'Eritrea'
  },
  {
    id: 1046,
    value: 'Fidschi'
  },
  {
    id: 1047,
    value: 'Gabun'
  },
  {
    id: 1048,
    value: 'Gambia'
  },
  {
    id: 1049,
    value: 'Ghana'
  },
  {
    id: 1050,
    value: 'Grenada'
  },
  {
    id: 1051,
    value: 'Guatemala'
  },
  {
    id: 1052,
    value: 'Äquatorialguinea'
  },
  {
    id: 1053,
    value: 'Guinea-Bissau'
  },
  {
    id: 1054,
    value: 'Guinea'
  },
  {
    id: 1055,
    value: 'Guyana'
  },
  {
    id: 1056,
    value: 'Haiti'
  },
  {
    id: 1057,
    value: 'Honduras'
  },
  {
    id: 1058,
    value: 'Indien'
  },
  {
    id: 1059,
    value: 'Indonesien'
  },
  {
    id: 1060,
    value: 'Iran'
  },
  {
    id: 1061,
    value: 'Irak'
  },
  {
    id: 1062,
    value: 'Äthiopien'
  },
  {
    id: 1063,
    value: 'Jamaika'
  },
  {
    id: 1064,
    value: 'Kambodscha'
  },
  {
    id: 1065,
    value: 'Kasachstan'
  },
  {
    id: 1066,
    value: 'Kenia'
  },
  {
    id: 1067,
    value: 'Kiribati'
  },
  {
    id: 1068,
    value: 'Kirgisistan'
  },
  {
    id: 1069,
    value: 'Laos'
  },
  {
    id: 1070,
    value: 'Lesotho'
  },
  {
    id: 1071,
    value: 'Liberia'
  },
  {
    id: 1072,
    value: 'Libyen'
  },
  {
    id: 1073,
    value: 'Madagaskar'
  },
  {
    id: 1074,
    value: 'Malawi'
  },
  {
    id: 1075,
    value: 'Malaysia'
  },
  {
    id: 1076,
    value: 'Malediven'
  },
  {
    id: 1077,
    value: 'Mali'
  },
  {
    id: 1078,
    value: 'Marshallinseln'
  },
  {
    id: 1079,
    value: 'Mauritius'
  },
  {
    id: 1080,
    value: 'Mauretanien'
  },
  {
    id: 1081,
    value: 'Mexiko'
  },
  {
    id: 1082,
    value: 'Micronesien'
  },
  {
    id: 1083,
    value: 'Mosambik'
  },
  {
    id: 1084,
    value: 'Mongolei'
  },
  {
    id: 1085,
    value: 'Thailand'
  },
  {
    id: 1086,
    value: 'Myanmar'
  },
  {
    id: 1087,
    value: 'Namibia'
  },
  {
    id: 1088,
    value: 'Nauru'
  },
  {
    id: 1089,
    value: 'Türkei'
  },
  {
    id: 1090,
    value: 'Uganda'
  },
  {
    id: 1091,
    value: 'Vereinigte Staaten'
  },
  {
    id: 1092,
    value: 'Jordanien'
  },
  {
    id: 1093,
    value: 'Uruguay'
  },
  {
    id: 1094,
    value: 'Usbekistan'
  },
  {
    id: 1095,
    value: 'Vanuatu'
  },
  {
    id: 1096,
    value: 'Venezuela'
  },
  {
    id: 1097,
    value: 'Vietnam'
  },
  {
    id: 1098,
    value: 'Israel'
  },
  {
    id: 1099,
    value: 'Sambia'
  },
  {
    id: 1100,
    value: 'China'
  },
  {
    id: 1101,
    value: 'Simbabwe'
  },
  {
    id: 1102,
    value: 'Nepal'
  },
  {
    id: 1103,
    value: 'Neuseeland'
  },
  {
    id: 1104,
    value: 'Nicaragua'
  },
  {
    id: 1105,
    value: 'Niger'
  },
  {
    id: 1106,
    value: 'Nigeria'
  },
  {
    id: 1107,
    value: 'Japan'
  },
  {
    id: 1108,
    value: 'Pakistan'
  },
  {
    id: 1109,
    value: 'Palau'
  },
  {
    id: 1110,
    value: 'Panama'
  },
  {
    id: 1111,
    value: 'Papua-Neuguinea'
  },
  {
    id: 1112,
    value: 'Paraguay'
  },
  {
    id: 1113,
    value: 'Peru'
  },
  {
    id: 1114,
    value: 'Philippinen'
  },
  {
    id: 1115,
    value: 'Katar'
  },
  {
    id: 1116,
    value: 'Dominikanische Republik'
  },
  {
    id: 1117,
    value: 'Zentralafrikanische Republik'
  },
  {
    id: 1118,
    value: 'Ruanda'
  },
  {
    id: 1119,
    value: 'St. Kitts und Nevis'
  },
  {
    id: 1120,
    value: 'St. Lucia'
  },
  {
    id: 1121,
    value: 'Guernsey - Grossbritannien'
  },
  {
    id: 1122,
    value: 'Oman'
  },
  {
    id: 1123,
    value: 'Samoa American - USA'
  },
  {
    id: 1124,
    value: 'Sao Tomé und Principe'
  },
  {
    id: 1125,
    value: 'Senegal'
  },
  {
    id: 1126,
    value: 'Seychellen'
  },
  {
    id: 1127,
    value: 'Sierra Leone'
  },
  {
    id: 1128,
    value: 'Singapur'
  },
  {
    id: 1129,
    value: 'Salomonen'
  },
  {
    id: 1130,
    value: 'Somalia'
  },
  {
    id: 1131,
    value: 'Südafrika'
  },
  {
    id: 1132,
    value: 'Sri Lanka'
  },
  {
    id: 1133,
    value: 'Suriname'
  },
  {
    id: 1134,
    value: 'Syrien'
  },
  {
    id: 1135,
    value: 'Swasiland'
  },
  {
    id: 1136,
    value: 'Tadschikistan'
  },
  {
    id: 1137,
    value: 'Südkorea'
  },
  {
    id: 1138,
    value: 'Taiwan - Province Of China'
  },
  {
    id: 1139,
    value: 'Tansania'
  },
  {
    id: 1140,
    value: 'Tschad'
  },
  {
    id: 1141,
    value: 'Togo'
  },
  {
    id: 1142,
    value: 'Tonga'
  },
  {
    id: 1143,
    value: 'Trinidad und Tobago'
  },
  {
    id: 1144,
    value: 'Tunesien'
  },
  {
    id: 1145,
    value: 'Turkmenistan'
  },
  {
    id: 1146,
    value: 'Tuvalu'
  },
  {
    id: 1147,
    value: 'Andorra'
  },
  {
    id: 1148,
    value: 'Weißrußland'
  },
  {
    id: 1149,
    value: 'Belgien'
  },
  {
    id: 1150,
    value: 'Bosnien und Herzegowina'
  },
  {
    id: 1151,
    value: 'Bulgarien'
  },
  {
    id: 1152,
    value: 'Tschechische Republik'
  },
  {
    id: 1153,
    value: 'Vatikanstadt'
  },
  {
    id: 1154,
    value: 'Dänemark'
  },
  {
    id: 1155,
    value: 'Estland'
  },
  {
    id: 1156,
    value: 'Irland'
  },
  {
    id: 1157,
    value: 'Griechenland'
  },
  {
    id: 1158,
    value: 'Spanien'
  },
  {
    id: 1159,
    value: 'Frankreich'
  },
  {
    id: 1160,
    value: 'Georgien'
  },
  {
    id: 1161,
    value: 'Kroatien'
  },
  {
    id: 1162,
    value: 'Island'
  },
  {
    id: 1163,
    value: 'Italien'
  },
  {
    id: 1164,
    value: 'Zypern'
  },
  {
    id: 1165,
    value: 'Lettland'
  },
  {
    id: 1166,
    value: 'Liechtenstein'
  },
  {
    id: 1167,
    value: 'Litauen'
  },
  {
    id: 1168,
    value: 'Luxemburg'
  },
  {
    id: 1169,
    value: 'Ungarn'
  },
  {
    id: 1170,
    value: 'Mazedonien'
  },
  {
    id: 1171,
    value: 'Malta'
  },
  {
    id: 1172,
    value: 'Moldau'
  },
  {
    id: 1173,
    value: 'Monaco'
  },
  {
    id: 1174,
    value: 'Niederlande'
  },
  {
    id: 1175,
    value: 'Norwegen'
  },
  {
    id: 1176,
    value: 'Polen'
  },
  {
    id: 1177,
    value: 'Portugal'
  },
  {
    id: 1178,
    value: 'Rumänien'
  },
  {
    id: 1179,
    value: 'Russische Föderation'
  },
  {
    id: 1180,
    value: 'San Marino'
  },
  {
    id: 1181,
    value: 'Albanien'
  },
  {
    id: 1182,
    value: 'Slowenien'
  },
  {
    id: 1183,
    value: 'Slowakei'
  },
  {
    id: 1184,
    value: 'Schweiz'
  },
  {
    id: 1185,
    value: 'Finnland'
  },
  {
    id: 1186,
    value: 'Schweden'
  },
  {
    id: 1187,
    value: 'Ukraine'
  },
  {
    id: 1188,
    value: 'Grossbritannien'
  },
  {
    id: 1189,
    value: 'Antarktika'
  },
  {
    id: 1190,
    value: 'Bermuda'
  },
  {
    id: 1191,
    value: 'Brit.Geb.Ind.Oz'
  },
  {
    id: 1192,
    value: 'Britische Jungferninseln - Grossbri'
  },
  {
    id: 1193,
    value: 'Cayman Islands'
  },
  {
    id: 1194,
    value: 'Christmas Island - Australien'
  },
  {
    id: 1195,
    value: 'Cocos Islands - Australien'
  },
  {
    id: 1196,
    value: 'Mayotte - Frankreich'
  },
  {
    id: 1197,
    value: 'Kongo, Dem. Republik'
  },
  {
    id: 1198,
    value: 'Cookinseln'
  },
  {
    id: 1199,
    value: 'Faröer - Dänemark'
  },
  {
    id: 1200,
    value: 'Falklands - Grossbritannien'
  },
  {
    id: 1201,
    value: 'Französisch Guinea - Frankreich'
  },
  {
    id: 1202,
    value: 'St. Lucia (VC)'
  },
  {
    id: 1203,
    value: 'Französisch Polynesien - Frankreich'
  },
  {
    id: 1204,
    value: 'Palästina'
  },
  {
    id: 1205,
    value: 'Gibraltar'
  },
  {
    id: 1206,
    value: 'Grönland - Dänemark'
  },
  {
    id: 1207,
    value: 'Guadeloupe - Frankreich'
  },
  {
    id: 1208,
    value: 'Guam - USA'
  },
  {
    id: 1209,
    value: 'Heard/McDon.Ins'
  },
  {
    id: 1210,
    value: 'Hongkong - Province Of China'
  },
  {
    id: 1211,
    value: 'Macao - Province Of China'
  },
  {
    id: 1212,
    value: 'Martinique - Frankreich'
  },
  {
    id: 1213,
    value: 'Montenegro'
  },
  {
    id: 1214,
    value: 'Montserrat - Britische Gebiete West'
  },
  {
    id: 1215,
    value: 'Niederländische Antillen - Niederla'
  },
  {
    id: 1216,
    value: 'Aruba - Niederlande'
  },
  {
    id: 1217,
    value: 'Neu-Kaledonien - Frankreich'
  },
  {
    id: 1218,
    value: 'Niue'
  },
  {
    id: 1219,
    value: 'Norfolk Islands - Australien'
  },
  {
    id: 1220,
    value: 'Osttimor'
  },
  {
    id: 1221,
    value: 'Puerto Rico - USA'
  },
  {
    id: 1222,
    value: 'Reunion - Frankreich'
  },
  {
    id: 1223,
    value: 'St. Helena - Südatlantik - Grossbri'
  },
  {
    id: 1224,
    value: 'Anguilla - Britische Gebiete West.'
  },
  {
    id: 1225,
    value: 'Saint- Pierre et Miquelon - Frankre'
  },
  {
    id: 1226,
    value: 'Tokela'
  },
  {
    id: 1227,
    value: 'Turks and Caicos Islands - Britisch'
  },
  {
    id: 1228,
    value: 'Jersey - Großbritannien'
  },
  {
    id: 1229,
    value: 'Isle Of Man - Grossbritannien'
  },
  {
    id: 1230,
    value: 'Amerikanische Jungferninseln - USA'
  },
  {
    id: 1231,
    value: 'Wallis et Futuna - Frankreich'
  },
  {
    id: 1232,
    value: 'Samoa'
  },
  {
    id: 1233,
    value: 'Serbien'
  },
  {
    id: 1234,
    value: 'Euro/Mastercard'
  },
  {
    id: 1235,
    value: 'Eguine'
  },
  {
    id: 1236,
    value: 'Kosovo'
  }
];
export const DEFAULT_MPN_PROVIDER_DATA = [
  {
    id: 5450,
    value: '0150'
  },
  {
    id: 135,
    value: '0151'
  },
  {
    id: 136,
    value: '0152'
  },
  {
    id: 5451,
    value: '0153'
  },
  {
    id: 5452,
    value: '0154'
  },
  {
    id: 137,
    value: '0155'
  },
  {
    id: 138,
    value: '0156'
  },
  {
    id: 139,
    value: '0157'
  },
  {
    id: 5453,
    value: '0158'
  },
  {
    id: 140,
    value: '0159'
  },
  {
    id: 141,
    value: '0160'
  },
  {
    id: 143,
    value: '0162'
  },
  {
    id: 144,
    value: '0163'
  },
  {
    id: 166,
    value: '0170'
  },
  {
    id: 167,
    value: '0171'
  },
  {
    id: 168,
    value: '0172'
  },
  {
    id: 169,
    value: '0173'
  },
  {
    id: 170,
    value: '0174'
  },
  {
    id: 171,
    value: '0175'
  },
  {
    id: 172,
    value: '0176'
  },
  {
    id: 173,
    value: '0177'
  },
  {
    id: 174,
    value: '0178'
  },
  {
    id: 175,
    value: '0179'
  }
];
export const DEFAULT_PROVIDERS_DATA = [
  {
    id: 420,
    value: '1&1'
  },
  {
    id: 400,
    value: '1&1 Internet AG (D2-Netz)'
  },
  {
    id: 401,
    value: '1&1 Internet AG (E1-Netz)'
  },
  {
    id: 521,
    value: '4BRO Mobile'
  },
  {
    id: 370,
    value: '7,7 Cent (E1-Netz)'
  },
  {
    id: 150,
    value: '7,7 Cent (Versatel)'
  },
  {
    id: 71,
    value: 'AirBerlin'
  },
  {
    id: 469,
    value: 'Aldi Talk'
  },
  {
    id: 97,
    value: 'Alice'
  },
  {
    id: 371,
    value: 'Alphatel (D1-Netz)'
  },
  {
    id: 372,
    value: 'Alphatel (D2-Netz)'
  },
  {
    id: 373,
    value: 'Alphatel (E1-Netz)'
  },
  {
    id: 322,
    value: 'Alphatel GmbH (D1-Netz)'
  },
  {
    id: 323,
    value: 'Alphatel GmbH (D2-Netz)'
  },
  {
    id: 324,
    value: 'Alphatel GmbH (E1-Netz)'
  },
  {
    id: 325,
    value: 'Alphatel GmbH (O2-Netz)'
  },
  {
    id: 98,
    value: 'AOL'
  },
  {
    id: 437,
    value: 'ATU'
  },
  {
    id: 63,
    value: 'Ay Yildiz'
  },
  {
    id: 61,
    value: 'BASE'
  },
  {
    id: 465,
    value: 'BASE'
  },
  {
    id: 156,
    value: 'bendock'
  },
  {
    id: 157,
    value: 'BILD'
  },
  {
    id: 159,
    value: 'Blau'
  },
  {
    id: 48,
    value: 'Blauworld'
  },
  {
    id: 470,
    value: 'Bunte Mobil (Tarif Blau)'
  },
  {
    id: 471,
    value: 'Bunte Mobil (Tarif o2)'
  },
  {
    id: 160,
    value: 'cablefon'
  },
  {
    id: 161,
    value: "Call 'n Go"
  },
  {
    id: 326,
    value: 'Callmobile (D1-Netz)'
  },
  {
    id: 327,
    value: 'Callmobile (D2-Netz)'
  },
  {
    id: 342,
    value: 'Cellway (D1-Netz)'
  },
  {
    id: 343,
    value: 'Cellway (D2-Netz)'
  },
  {
    id: 344,
    value: 'Cellway (E1-Netz)'
  },
  {
    id: 345,
    value: 'Cellway (O2-Netz)'
  },
  {
    id: 472,
    value: 'Chip Mobile (Tarif Blau)'
  },
  {
    id: 473,
    value: 'Chip Mobile (Tarif o2)'
  },
  {
    id: 51,
    value: 'Congstar'
  },
  {
    id: 301,
    value: 'Congstar Services'
  },
  {
    id: 346,
    value: 'debitel AG (D1-Netz)'
  },
  {
    id: 347,
    value: 'debitel AG (D2-Netz)'
  },
  {
    id: 348,
    value: 'debitel AG (E1-Netz)'
  },
  {
    id: 349,
    value: 'debitel AG (O2-Netz)'
  },
  {
    id: 328,
    value: 'debitel-light (D1-Netz)'
  },
  {
    id: 329,
    value: 'debitel-light (D2-Netz)'
  },
  {
    id: 165,
    value: 'DemCom'
  },
  {
    id: 275,
    value: 'DeutschlandSIM (D2-Netz)'
  },
  {
    id: 274,
    value: 'DeutschlandSIM (O2-Netz)'
  },
  {
    id: 277,
    value: 'discoPLUS (D2-Netz)'
  },
  {
    id: 276,
    value: 'discoPLUS (O2-Netz)'
  },
  {
    id: 374,
    value: 'discoTEL (D2-Netz)'
  },
  {
    id: 306,
    value: 'discotel (maxxim)'
  },
  {
    id: 375,
    value: 'discoTEL (O2-Netz)'
  },
  {
    id: 464,
    value: 'DOKOM21 Dortmund'
  },
  {
    id: 330,
    value: 'Drilisch GmbH (D1-Netz)'
  },
  {
    id: 331,
    value: 'Drilisch GmbH (D2-Netz)'
  },
  {
    id: 332,
    value: 'Drilisch GmbH (E1-Netz)'
  },
  {
    id: 333,
    value: 'Drilisch GmbH (O2-Netz)'
  },
  {
    id: 376,
    value: 'Drillisch (D1-Netz)'
  },
  {
    id: 377,
    value: 'Drillisch (D2-Netz)'
  },
  {
    id: 378,
    value: 'Drillisch (E1-Netz)'
  },
  {
    id: 15,
    value: 'E-Plus'
  },
  {
    id: 494,
    value: 'easyTel Prepaid 2 Cent'
  },
  {
    id: 493,
    value: 'easyTel Prepaid 9 Cent'
  },
  {
    id: 434,
    value: 'Ecofon'
  },
  {
    id: 175,
    value: 'Ecotel'
  },
  {
    id: 46,
    value: 'Edeka Mobil'
  },
  {
    id: 496,
    value: 'EDEKA smart'
  },
  {
    id: 444,
    value: 'EnoCom Karlsruhe'
  },
  {
    id: 523,
    value: 'ENTEGA (D2-Netz)'
  },
  {
    id: 515,
    value: 'eSIM.me'
  },
  {
    id: 281,
    value: 'eteleon (D2-Netz)'
  },
  {
    id: 280,
    value: 'eteleon (O2-Netz)'
  },
  {
    id: 178,
    value: 'EWE TEL'
  },
  {
    id: 438,
    value: 'Express'
  },
  {
    id: 498,
    value: 'FCB Mobil'
  },
  {
    id: 411,
    value: 'fiotel (D1-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 404,
    value: 'Fiotel (D1-Netz, simply Communication GmbH)'
  },
  {
    id: 181,
    value: 'Flexmobil'
  },
  {
    id: 182,
    value: 'FonEze'
  },
  {
    id: 421,
    value: 'Fonic'
  },
  {
    id: 522,
    value: 'Freenet FUNK (O2-Netz)'
  },
  {
    id: 336,
    value: 'freenetmobile (D1-Netz)'
  },
  {
    id: 337,
    value: 'freenetmobile (D2-Netz)'
  },
  {
    id: 338,
    value: 'freenetmobile (O2-Netz)'
  },
  {
    id: 185,
    value: 'Freephone'
  },
  {
    id: 186,
    value: 'FYVE'
  },
  {
    id: 95,
    value: 'Gesmo Wholesale'
  },
  {
    id: 282,
    value: 'getsmart'
  },
  {
    id: 435,
    value: 'Globus'
  },
  {
    id: 189,
    value: 'Hansenet'
  },
  {
    id: 191,
    value: 'helloMobil'
  },
  {
    id: 446,
    value: 'HFO Telecom Hof'
  },
  {
    id: 308,
    value: 'HITmobile'
  },
  {
    id: 448,
    value: 'htp Hannover'
  },
  {
    id: 195,
    value: 'Igge&Ko'
  },
  {
    id: 196,
    value: 'ja! mobil'
  },
  {
    id: 449,
    value: 'JKR Bad Kreuznach'
  },
  {
    id: 197,
    value: 'Kabel BW'
  },
  {
    id: 475,
    value: 'Kaufland (K-Classic)'
  },
  {
    id: 259,
    value: 'Kaufland mobil '
  },
  {
    id: 450,
    value: 'KEVAG Telekom Koblenz'
  },
  {
    id: 451,
    value: 'KielNET/Versatel Kiel'
  },
  {
    id: 339,
    value: 'Klarmobil.de (D1-Netz)'
  },
  {
    id: 340,
    value: 'Klarmobil.de (D2-Netz)'
  },
  {
    id: 341,
    value: 'Klarmobil.de (O2-Netz)'
  },
  {
    id: 202,
    value: 'Lebara'
  },
  {
    id: 440,
    value: 'LIDL (D2-Netz)'
  },
  {
    id: 141,
    value: 'Lycamobile'
  },
  {
    id: 100,
    value: 'M-net Mobil'
  },
  {
    id: 518,
    value: 'MAINGAU Energie GmbH'
  },
  {
    id: 517,
    value: 'MAINGAU Mobilfunk'
  },
  {
    id: 101,
    value: 'Maxi Mobil'
  },
  {
    id: 379,
    value: 'maXXim (D1-Netz)'
  },
  {
    id: 413,
    value: 'maXXim (D1-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 405,
    value: 'Maxxim (D1-Netz, simply Communication GmbH)'
  },
  {
    id: 380,
    value: 'maXXim (D2-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 383,
    value: 'maXXim (D2-Netz, MS Mobile Services GmbH)'
  },
  {
    id: 381,
    value: 'maXXim (O2-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 382,
    value: 'maXXim (O2-Netz, MS Mobile Services GmbH)'
  },
  {
    id: 208,
    value: 'MCSIM'
  },
  {
    id: 476,
    value: 'Media Markt Super Select'
  },
  {
    id: 318,
    value: 'mobil09'
  },
  {
    id: 350,
    value: 'Mobilcom (D1-Netz)'
  },
  {
    id: 351,
    value: 'Mobilcom (D2-Netz)'
  },
  {
    id: 352,
    value: 'Mobilcom (E1-Netz)'
  },
  {
    id: 353,
    value: 'Mobilcom (O2-Netz)'
  },
  {
    id: 478,
    value: 'Mobilka (Postpaid)'
  },
  {
    id: 477,
    value: 'Mobilka (Prepaid)'
  },
  {
    id: 303,
    value: 'MoBlack'
  },
  {
    id: 211,
    value: 'MoWoTel'
  },
  {
    id: 436,
    value: 'mp3'
  },
  {
    id: 214,
    value: 'N24-Webstick'
  },
  {
    id: 319,
    value: 'NetCologne Dienste'
  },
  {
    id: 296,
    value: 'Netcologne Dienste'
  },
  {
    id: 432,
    value: 'NetCologne GmbH (NEMO)'
  },
  {
    id: 453,
    value: 'NetCom BW Ellwangen'
  },
  {
    id: 454,
    value: 'Netcom Kassel'
  },
  {
    id: 216,
    value: 'NettoKOM'
  },
  {
    id: 467,
    value: 'Netzclub'
  },
  {
    id: 497,
    value: 'newsim'
  },
  {
    id: 516,
    value: 'newsim GmbH'
  },
  {
    id: 72,
    value: 'Nordcom'
  },
  {
    id: 500,
    value: 'NORMA Connect'
  },
  {
    id: 503,
    value: 'Norma Mobil'
  },
  {
    id: 502,
    value: 'Nova Mobil'
  },
  {
    id: 217,
    value: 'o2 Germany'
  },
  {
    id: 109,
    value: 'OnePhone'
  },
  {
    id: 218,
    value: 'OrtelMobile'
  },
  {
    id: 73,
    value: 'Osnatel'
  },
  {
    id: 384,
    value: 'otelo (D2-Netz, otelo (Vodafone GmbH))'
  },
  {
    id: 367,
    value: 'otelo (D2-Netz, Vodafone D2 GmbH)'
  },
  {
    id: 505,
    value: 'paketsparer'
  },
  {
    id: 220,
    value: 'PENNY MOBIL'
  },
  {
    id: 221,
    value: 'Phonex'
  },
  {
    id: 222,
    value: 'PHONEX.de'
  },
  {
    id: 223,
    value: 'PicCell'
  },
  {
    id: 422,
    value: 'PremiumSIM'
  },
  {
    id: 506,
    value: 'primacall'
  },
  {
    id: 455,
    value: 'primacom (primaholding) Berlin'
  },
  {
    id: 507,
    value: 'primamobile'
  },
  {
    id: 508,
    value: 'primastrom'
  },
  {
    id: 225,
    value: 'Pro7-Webstick'
  },
  {
    id: 110,
    value: 'PTT-Minex'
  },
  {
    id: 309,
    value: 'Ratiophone'
  },
  {
    id: 456,
    value: 'Regio Plus Dresden'
  },
  {
    id: 295,
    value: 'Rossmann'
  },
  {
    id: 468,
    value: 'Rossmann mobil powered by otelo'
  },
  {
    id: 227,
    value: 'RTL Surfstick'
  },
  {
    id: 228,
    value: 'Sat.1'
  },
  {
    id: 479,
    value: 'Saturn Super Select'
  },
  {
    id: 43,
    value: 'Schlecker Mobil (smobil)'
  },
  {
    id: 106,
    value: 'Schlecker Prepaid'
  },
  {
    id: 232,
    value: 'sdt.net'
  },
  {
    id: 457,
    value: 'sdt.net Aalen'
  },
  {
    id: 534,
    value: 'Share Mobile'
  },
  {
    id: 424,
    value: 'SimDiscount (D2-Netz)'
  },
  {
    id: 423,
    value: 'SimDiscount (O2-Netz)'
  },
  {
    id: 480,
    value: 'simfinity'
  },
  {
    id: 520,
    value: 'SIMon mobile'
  },
  {
    id: 385,
    value: 'simply (D1-Netz)'
  },
  {
    id: 415,
    value: 'simply (D1-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 406,
    value: 'Simply (D1-Netz, simply Communication GmbH)'
  },
  {
    id: 386,
    value: 'simply (D2-Netz)'
  },
  {
    id: 387,
    value: 'simply (O2-Netz)'
  },
  {
    id: 235,
    value: 'simply free'
  },
  {
    id: 236,
    value: 'SimSay'
  },
  {
    id: 425,
    value: 'SimTim'
  },
  {
    id: 481,
    value: 'Simyo (O2-Netz)'
  },
  {
    id: 237,
    value: 'sipgate Wireless'
  },
  {
    id: 238,
    value: 'Sky'
  },
  {
    id: 389,
    value: 'smartmobil.de (D2-Netz)'
  },
  {
    id: 388,
    value: 'smartmobil.de (O2-Netz)'
  },
  {
    id: 320,
    value: 'SmileMobile'
  },
  {
    id: 242,
    value: 'solomo'
  },
  {
    id: 299,
    value: 'Sparhandy'
  },
  {
    id: 243,
    value: 'Speach'
  },
  {
    id: 519,
    value: 'Spice Mobile'
  },
  {
    id: 244,
    value: 'Sport.1'
  },
  {
    id: 458,
    value: 'Stadtwerke Schwedt (D2-Netz)'
  },
  {
    id: 247,
    value: 'SunSim'
  },
  {
    id: 483,
    value: 'Super Illu Mobil (Tarif Blau)'
  },
  {
    id: 482,
    value: 'Super Illu Mobil (Tarif o2)'
  },
  {
    id: 248,
    value: 'SurfSpot'
  },
  {
    id: 459,
    value: 'SWU Telenet Ulm'
  },
  {
    id: 1,
    value: 'T-Mobile'
  },
  {
    id: 354,
    value: 'Talkline GmbH (D1-Netz)'
  },
  {
    id: 355,
    value: 'Talkline GmbH (D2-Netz)'
  },
  {
    id: 356,
    value: 'Talkline GmbH (E1-Netz)'
  },
  {
    id: 357,
    value: 'Talkline GmbH (O2-Netz)'
  },
  {
    id: 485,
    value: 'Tarifhaus (Tarif Blau)'
  },
  {
    id: 484,
    value: 'Tarifhaus (Tarif o2)'
  },
  {
    id: 426,
    value: 'Tchibo mobil'
  },
  {
    id: 107,
    value: 'Tchibo mobil (Postpaid)'
  },
  {
    id: 105,
    value: 'Tchibo mobil (Prepaid)'
  },
  {
    id: 514,
    value: 'Teams'
  },
  {
    id: 391,
    value: 'Telco (D1-Netz)'
  },
  {
    id: 392,
    value: 'Telco (D2-Netz)'
  },
  {
    id: 393,
    value: 'Telco (E1-Netz)'
  },
  {
    id: 394,
    value: 'Telco (O2-Netz)'
  },
  {
    id: 358,
    value: 'Telco Services (D1-Netz)'
  },
  {
    id: 359,
    value: 'Telco Services (D2-Netz)'
  },
  {
    id: 360,
    value: 'Telco Services (E1-Netz)'
  },
  {
    id: 361,
    value: 'Telco Services (O2-Netz)'
  },
  {
    id: 510,
    value: 'TelcoVillage'
  },
  {
    id: 362,
    value: 'TelDaFax (VS-Netz)'
  },
  {
    id: 292,
    value: 'Tele2'
  },
  {
    id: 74,
    value: 'Teleos'
  },
  {
    id: 513,
    value: 'The DOT'
  },
  {
    id: 504,
    value: 'Thueringer Netkom GmbH'
  },
  {
    id: 466,
    value: 'TKS easyMobile'
  },
  {
    id: 460,
    value: 'TNG Stadnetz Kiel'
  },
  {
    id: 257,
    value: 'TouristMobile'
  },
  {
    id: 428,
    value: 'Truphone'
  },
  {
    id: 258,
    value: 'TUI-Surfstick'
  },
  {
    id: 487,
    value: 'TV Spielfilm talk & surf (Tarif Blau)'
  },
  {
    id: 486,
    value: 'TV Spielfilm talk & surf (Tarif o2)'
  },
  {
    id: 488,
    value: 'Türkei SIM'
  },
  {
    id: 298,
    value: 'UMMS'
  },
  {
    id: 321,
    value: 'Unitymedia'
  },
  {
    id: 363,
    value: 'VictorVox (D1-Netz)'
  },
  {
    id: 395,
    value: 'VICTORVOX (D1-Netz)'
  },
  {
    id: 416,
    value: 'VICTORVOX (D1-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 407,
    value: 'VictorVox (D1-Netz, VictorVox)'
  },
  {
    id: 364,
    value: 'VictorVox (D2-Netz)'
  },
  {
    id: 396,
    value: 'VICTORVOX (D2-Netz)'
  },
  {
    id: 417,
    value: 'VICTORVOX (D2-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 408,
    value: 'VictorVox (D2-Netz, VictorVox)'
  },
  {
    id: 365,
    value: 'VictorVox (E1-Netz)'
  },
  {
    id: 397,
    value: 'VICTORVOX (E1-Netz)'
  },
  {
    id: 418,
    value: 'VICTORVOX (E1-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 409,
    value: 'VictorVox (E1-Netz, VictorVox)'
  },
  {
    id: 398,
    value: 'VICTORVOX (O2-Netz)'
  },
  {
    id: 366,
    value: 'VictorVox (O2-Netz)'
  },
  {
    id: 419,
    value: 'VICTORVOX (O2-Netz, Drillisch Telecom GmbH)'
  },
  {
    id: 410,
    value: 'VictorVox (O2-Netz, VictorVox)'
  },
  {
    id: 427,
    value: 'Vintage Wireless'
  },
  {
    id: 2,
    value: 'Vodafone'
  },
  {
    id: 509,
    value: 'Voxenergie'
  },
  {
    id: 489,
    value: 'WhatsApp SIM'
  },
  {
    id: 490,
    value: 'Whitelabel (aetka smart)'
  },
  {
    id: 463,
    value: 'wilhelm.tel (D2-Netz)'
  },
  {
    id: 368,
    value: 'wilhelm.tel (VS-Netz)'
  },
  {
    id: 264,
    value: 'winSIM'
  },
  {
    id: 265,
    value: 'WKW Mobil'
  },
  {
    id: 266,
    value: 'WKW-Surfstick'
  },
  {
    id: 462,
    value: 'WOBCOM Wolfsburg'
  },
  {
    id: 294,
    value: 'Woodman'
  },
  {
    id: 512,
    value: 'WorkSide'
  },
  {
    id: 511,
    value: 'XA.AX'
  },
  {
    id: 431,
    value: 'yourfone.de (E1-Netz)'
  },
  {
    id: 433,
    value: 'yourfone.de (O2-Netz)'
  },
  {
    id: 123,
    value: 'Zapcell'
  }
];

export default {
  DEFAULT_SALUTATION_DATA,
  DEFAULT_COUNTRIES_DATA,
  DEFAULT_IDENTITY_TYPES_DATA,
  DEFAULT_NATIONALITIES_DATA,
  DEFAULT_MPN_PROVIDER_DATA,
  DEFAULT_PROVIDERS_DATA
};
