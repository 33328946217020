/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import './ButtonRadio.scss';

export function ButtonRadio({
  value = false,
  // shadow = false,
  onChange = () => { },
  isDefaultChecked,
  name = '',
  id = '',
  ...restProps
}) {
  // State
  const [isActive, setIsActive] = useState(value);

  // Hooks
  useEffect(() => {
    if (value !== isActive) {
      onChange(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (value !== isActive) {
      setIsActive(value);
    }
  }, [value]);

  return (
    <div role="button" aria-hidden onClick={() => setIsActive(!isActive)}>
      <div className="radio">
        {/* <button
          type="button"
          className={`btn btn-radio ${(isDefaultChecked || isActive) ? 'active' : 'inactive'} ${shadow ? 'shadow' : ''}`}
          aria-label='radio-button'
        >
          <div className="handle" />
        </button> */}
        <input
          id={id}
          name={name}
          checked={isActive || isDefaultChecked}
          onChange={() => { }}
          value={isActive}
          type="radio"
          {...{ ...restProps }}
        // className={`${isDefaultChecked || isActive ? 'active' : 'inactive'} ${
        //   shadow ? 'shadow' : ''
        // }`}
        />
        <div className="radio-icon" />
      </div>
    </div>
  );
}

export default ButtonRadio;
