import React from 'react';
import { Icons } from '@core/Utils';
import { displayPrice } from '@utils/globalConstant';
import './DashboradCredit.scss';

export function DashboradCredit({ credit, text, onClick, isCreditLow = false, showPlusIcon}) {
  // Functions
  const showCredit = (amount) => (credit ? `${displayPrice(amount)} €`.replace('.', ',') : '');

  return (
    <div className="d-flex justify-content-between align-items-center dashboard-credit animate__animated animate__zoomIn">
      <div className="up-div">
        <Icons name="walletdark" width={24} height={24} />

        <div className="d-inline-flex text align-items-baseline">
          <p
            className="nc-realtextpro-copy m-0 credit-text text-gray-100"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <h3 className={`nc-doomsday-h3 mb-0 credit-price ${isCreditLow ? 'text-danger' : ''}`}>
            {credit ? showCredit(credit) : "-"}
          </h3>
        </div>
      </div>
      {showPlusIcon && (
        <div className="plus-icon">
          <button
            type="button"
            className="border-0 bg-transparent p-0"
            aria-label="Plus Icon"
            onClick={onClick}>
            <Icons name="plusdarkgreen" width={28} height={28} />
          </button>
        </div>
      )}
    </div>
  );
}

export default DashboradCredit;
