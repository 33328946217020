import React from 'react';
import './FaqTab.scss';

export function FaqTab({ title, isActive = false }) {
  return (
    <div
      className={`faq-tab d-flex align-items-center justify-content-center py-6 px-4 ${
        isActive ? 'active' : ''
      }`}
      role="button"
      aria-hidden>
      <div className="faq-tab-title nc-doomsday-h4" dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
}

export default FaqTab;
