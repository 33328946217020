/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable max-classes-per-file */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { format, getDaysInMonth } from 'date-fns';
import { de } from 'date-fns/locale';
import { appAlert } from '@utils/globalConstant';
// eslint-disable-next-line import/no-cycle
import { InfoNotification, Icons, ButtonPrimary } from '../index';
import './FormDatePicker.scss';
// import React, { useState, useEffect, useRef, useCallback, Component } from 'react';
// import './FormDatePicker.scss';

// const MONTHS = [
//   'Januar',
//   'Februar',
//   'März',
//   'April',
//   'Mai',
//   'Juni',
//   'Juli',
//   'August',
//   'September',
//   'Oktober',
//   'November',
//   'Dezember'
// ];
// const YEARS = new Array(201).fill(1900).map((value, index) => value + index);

// class CustomWheel extends Component {
//   constructor({ selected }) {
//     super();
//     this.state = { position: selected ? -(selected - 1) * 50 : 0 };
//     this.offset = 0;
//     this.dragging = false;
//   }

//   componentDidUpdate() {
//     const selectedPosition = -(this.props.selected - 1) * 50;

//     if (!this.dragging && this.state.position !== selectedPosition) {
//       this.setState({ position: selectedPosition });
//     }
//   }

//   onMouseDown = (event) => {
//     this.previousY = event.touches ? event.touches[0].clientY : event.clientY;
//     this.dragging = true;

//     document.addEventListener('mousemove', this.onMouseMove);
//     document.addEventListener('mouseup', this.onMouseUp);
//     document.addEventListener('touchmove', this.onMouseMove);
//     document.addEventListener('touchend', this.onMouseUp);
//   };

//   onMouseMove = (event) => {
//     const clientY = event.touches ? event.touches[0].clientY : event.clientY;

//     this.offset = clientY - this.previousY;

//     const maxPosition = -this.props.data.length * 50;
//     const position = this.state.position + this.offset;

//     this.setState({ position: Math.max(maxPosition, Math.min(50, position)) });

//     this.previousY = event.touches ? event.touches[0].clientY : event.clientY;
//   };

//   onMouseUp = () => {
//     // calculate closeset snap
//     const maxPosition = -(this.props.data.length - 1) * 50;
//     const rounderPosition = Math.round((this.state.position + this.offset * 5) / 50) * 50;
//     const finalPosition = Math.max(maxPosition, Math.min(0, rounderPosition));

//     this.dragging = false;
//     this.setState({ position: finalPosition });

//     document.removeEventListener('mousemove', this.onMouseMove);
//     document.removeEventListener('mouseup', this.onMouseUp);
//     document.removeEventListener('touchmove', this.onMouseMove);
//     document.removeEventListener('touchend', this.onMouseUp);

//     this.props.onDateChange(this.props.type, -finalPosition / 50);
//   };

//   render() {
//     const inlineStyle = {
//       willChange: 'transform',
//       transition: `transform ${Math.abs(this.offset) / 100 + 0.1}s`,
//       transform: `translateY(${this.state.position}px)`
//     };
//     console.log(this.props.selected);

//     return (
//       <div
//         className={`dragdealer ${this.props.type}`}
//         onMouseDown={this.onMouseDown}
//         onTouchStart={this.onMouseDown}>
//         <ul className="handle p-0 m-0 text-center" style={inlineStyle}>
//           {this.props.data.map((item, index) => (
//             <li
//               className={
//                 this.props.selected === index + 1
//                   ? `w-100 picker-item-active ${this.props.type}`
//                   : (this.props.selected === index + 2 || this.props.selected === index)
//                   ? `w-100 picker-item-list-last ${this.props.type}`
//                   : `w-100 picker-item-list ${this.props.type}`
//               }
//               onClick={this.onMouseMove}
//               key={`${this.props.type}-${item}`}>
//               {item}
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   }
// }

// class DatePicker extends Component {
//   dateChanged = (type, changedData) => {
//     let newDate;

//     if (type === 'day') {
//       newDate = new Date(
//         this.props.date.getFullYear(),
//         this.props.date.getMonth(),
//         changedData + 1
//       );
//     } else if (type === 'month') {
//       let maxDayInSelectedMonth = new Date(
//         this.props.date.getFullYear(),
//         changedData + 1,
//         0
//       ).getDate();
//       let day = Math.min(this.props.date.getDate(), maxDayInSelectedMonth);

//       newDate = new Date(this.props.date.getFullYear(), changedData, day);
//     } else if (type === 'year') {
//       let maxDayInSelectedMonth = new Date(
//         1900 + changedData,
//         this.props.date.getMonth() + 1,
//         0
//       ).getDate();
//       let day = Math.min(this.props.date.getDate(), maxDayInSelectedMonth);

//       newDate = new Date(1900 + changedData, this.props.date.getMonth(), day);
//     }

//     this.props.onDateChange(newDate);
//   };

//   render() {
//     this.days = new Array(
//       new Date(this.props.date.getFullYear(), this.props.date.getMonth() + 1, 0).getDate()
//     )
//       .fill(1)
//       .map((value, index) => value + index);

//     this.months = MONTHS;
//     this.years = YEARS;

//     return (
//       <div className="date-picker">
//         <CustomWheel
//           type="day"
//           data={this.days}
//           selected={this.props.date.getDate()}
//           onDateChange={this.dateChanged}
//         />
//         <CustomWheel
//           type="month"
//           data={this.months}
//           selected={this.props.date.getMonth() + 1}
//           onDateChange={this.dateChanged}
//         />
//         <CustomWheel
//           type="year"
//           data={this.years}
//           selected={this.props.date.getYear() + 1}
//           onDateChange={this.dateChanged}
//         />
//       </div>
//     );
//   }
// }

// export function FormDatePicker({ date = new Date(), onDateChange = () => {} }) {
//   return (
//     <>
//       <div className="bg-white px-4 pt-4 rounded-4 my-1">
//         <DatePicker date={date} onDateChange={onDateChange} />
//       </div>
//     </>
//   );
// }

// export default FormDatePicker;

// export function FormDatePicker({
//   id,
//   type,
//   label,
//   startIcon,
//   hint,
//   invalid,
//   invalidMessage,
//   value: valueProp,
//   validMessage,
//   valid,
//   placeholder,
//   options = [
//     {
//       id: 0,
//       label: 'Date'
//     }
//   ],

//   onChange,
//   onBlur = (() => {}),
//   ...restProps
// }) {
//   // State & Refs
//   const dropMenu = useRef(null);
//   const [value, setValue] = useState(valueProp);

//   const [isOpen, setIsOpen] = useState(false);
//   const [isFocus, setIsFocus] = useState(false);
//   const [isBlur, setIsBlur] = useState(false);
//   const [isTouched, setIsTouched] = useState(false);
//   const [isOutsideClick, setIsOutsideClick] = useState(false);
//   // Functions
//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   const triggerChange = (event) => {
//     if (onChange) {
//       onChange(event);
//       onBlur({
//         type: 'blur',
//         target: {
//           id: id,
//           name: id,
//           value: isBlur
//         }
//       });
//     }
//     setValue(event.target.value);
//     setIsTouched(true);
//     setIsBlur(true);
//   };

//   const placeholderText = () => {
//     if (value) {
//       const findValue = options.find((option) => option.id === value);
//       if (findValue) {
//         return findValue.label;
//       }
//       return placeholder;
//     } else {
//       return placeholder;
//     }
//   };

//   const checkOutSideClick = (e) => {
//     setIsOutsideClick(dropMenu.current && !dropMenu.current.contains(e.target));
//   };

//   // Hooks
//   useEffect(() => {
//     if (valueProp) {
//       setValue(valueProp);
//     }
//   }, [valueProp]);

//   useEffect(() => {
//     if (isBlur || (isOutsideClick && isOpen)) {
//       onBlur({
//         type: 'blur',
//         target: {
//           id: id,
//           name: id,
//           value: isBlur
//         }
//       });
//     }

//     if (isOutsideClick && isOpen) {
//       setIsOpen(false);
//     }
//   }, [isBlur, isOutsideClick, isOpen]);

//   useEffect(() => {
//     document.addEventListener('mousedown', checkOutSideClick);
//     return () => {
//       document.removeEventListener('mousedown', checkOutSideClick);
//     };
//   }, []);

export function FormDatePicker({
  id,
  label,
  startIcon,
  hint,
  invalid,
  invalidMessage,
  value: valueProp,
  validMessage,
  valid,
  placeholder,
  disabled = false,
  isNextYears = false,
  popUpPicker = false,
  // options = [
  //   {
  //     id: 0,
  //     label: 'Date'
  //   }
  // ],
  customClass = '',

  onChange,
  onBlur = () => {}
  //   ...restProps
}) {
  // Generate arrays for days, months, and years
  const dropMenu = useRef(null);
  const dateRefs = useRef([]);
  const monthRefs = useRef([]);
  const yearRefs = useRef([]);

  const [days, setDays] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
  const [value, setValue] = useState(valueProp);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  // const [isOutsideClick, setIsOutsideClick] = useState(false);

  const months = Array.from({ length: 12 }, (_, i) =>
    format(new Date(0, i), 'LLLL', { locale: de })
  );
  const prevYears = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
  const nextYears = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() + i);

  // State for the selected day, month, and year
  const [selectedDay, setSelectedDay] = useState(1); // new Date().getDate()
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // Functions
  // Update the days array based on the selected month and year
  const updateDays = (monthIndex, year) => {
    const newDaysInMonth = getDaysInMonth(new Date(year, monthIndex));
    setDays(Array.from({ length: newDaysInMonth }, (_, i) => i + 1));
  };

  const toggleOpen = () => {
    if (!disabled) {
      if (isOpen) {
        setIsTouched(true);
      }
      setIsOpen(!isOpen);
      setIsFocus(!isFocus);
      setIsBlur(!isBlur);
    }
  };

  // Handlers for selection
  const handleDayChange = (day) => setSelectedDay(day);

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    // setSelectedDay(null); // Reset day selection
    if (month && selectedYear) {
      updateDays(month, selectedYear);
    }
  };
  const handleYearChange = (year) => {
    setSelectedYear(year);
    // setSelectedDay(null); // Reset day selection
    if (selectedMonth && year) {
      updateDays(selectedMonth, year);
    }
  };

  const placeholderText = () => {
    if (value !== '') {
      return moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
    }
    return placeholder;
  };

  const onSelectDate = () => {
    const date = new Date(selectedYear, selectedMonth, selectedDay);
    setValue(date);
    setIsOpen(false);
    setIsFocus(false);
    setIsTouched(true);
    setIsBlur(true);
    onChange(date);
  };

  // const checkOutSideClick = (e) => {
  //   setIsOutsideClick(dropMenu.current && !dropMenu.current.contains(e.target));
  // };

  //  Hooks

  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event) => {
      if (dropMenu.current && !dropMenu.current.contains(event.target)) {
        // console.log('Clicked outside the div');
        // console.log('isOpen', isOpen);
        if (isOpen) toggleOpen();
      } else {
        // console.log('Clicked inside the div');
        // Do nothing
      }
    };

    // Add event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // To scroll into selected date, month & year
  // useEffect(() => {
  //   if (isOpen && popUpPicker && selectedDay && dateRefs.current[selectedDay - 1]) {
  //     dateRefs.current[selectedDay - 1].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'start'
  //     });
  //   }
  // }, [selectedDay, dateRefs, isOpen]);

  // useEffect(() => {
  //   if (isOpen && popUpPicker && selectedMonth && monthRefs.current[selectedMonth - 1]) {
  //     monthRefs.current[selectedMonth - 1].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'start'
  //     });
  //   }
  // }, [selectedMonth, monthRefs, isOpen]);

  // useEffect(() => {
  //   if (isOpen && popUpPicker && selectedYear && yearRefs.current[selectedYear - 1]) {
  //     yearRefs.current[selectedYear - 1].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'start'
  //     });
  //   }
  // }, [selectedYear, yearRefs, isOpen]);

  // useEffect(() => {
  //   document.addEventListener('mousedown', checkOutSideClick);
  //   return () => {
  //     document.removeEventListener('mousedown', checkOutSideClick);
  //   };
  // }, []);

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
      setIsFocus(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (isBlur) {
      onBlur({
        type: 'blur',
        target: {
          id,
          name: id,
          value: isBlur
        }
      });
    }
  }, [isBlur]);

  // useEffect(() => {
  // if (isBlur || (isOutsideClick && isOpen)) {
  //   onBlur({
  //     type: 'blur',
  //     target: {
  //       id: id,
  //       name: id,
  //       value: isBlur
  //     }
  //   });
  // }

  //   if (isOutsideClick && isOpen) {
  //     setIsOpen(false);
  //   }
  // }, [isBlur, isOutsideClick, isOpen]);

  // Render the component
  return (
    <div ref={dropMenu}>
      <div className="mb-3">
        {label && (
          <label htmlFor={id} className="form-label ps-5 ms-1">
            {label}
          </label>
        )}
        <div
          className={`
        input-group input-group-dropdown
        ${valid ? 'valid' : ''}
        ${
          invalid && (!isOpen ? !isOpen : isTouched)
            ? 'invalid animate__animated animate__shakeX'
            : ''
        }
        ${isFocus ? 'focus' : ''}
        ${isBlur ? 'blur' : ''}
        ${isTouched ? 'touched' : ''}
        ${disabled ? 'disabled' : ''}
        ${customClass}
      `}
          onClick={toggleOpen}>
          {startIcon && (
            <span className="input-group-text pe-0 px-6">
              <Icons name={startIcon} />
            </span>
          )}
          <div
            className="form-control form-control-placeholder d-flex align-items-center justify-content-start"
            style={{ color: value ? '#074D52' : '#83A6A8' }}>
            {placeholderText()}
          </div>
          <span className="input-group-text ps-0 px-6">
            <Icons name={isOpen ? 'arrowupmint' : 'arrowdownmint'} />
          </span>
        </div>
      </div>
      {isOpen && !popUpPicker && (
        <div className="bg-white px-4 pt-4 rounded-4 my-1">
          <div className="input-group-datepicker-list date-picker">
            <div className="row date-picker-row h-100">
              <div className="col-4 text-center date-picker-day">
                <div>
                  {days.map((day) => (
                    <button
                      key={day}
                      className={`dropdown-item py-1 ${
                        day === selectedDay && 'bg-mint-100 rounded-pill text-white'
                      }`}
                      type="button"
                      onClick={() => handleDayChange(day)}>
                      {day}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-4 text-center date-picker-month">
                <div>
                  {months.map((month, index) => (
                    <button
                      key={month}
                      className={`dropdown-item py-1 ${
                        index === selectedMonth && 'bg-mint-100 rounded-pill text-white'
                      }`}
                      type="button"
                      onClick={() => handleMonthChange(index)}>
                      {month}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-4 text-center date-picker-year">
                <div>
                  {isNextYears
                    ? nextYears.map((year, index) => (
                        <button
                          key={year}
                          className={`dropdown-item py-1 ${
                            year === selectedYear && 'bg-mint-100 rounded-pill text-white'
                          }`}
                          type="button"
                          onClick={() => handleYearChange(year)}
                          ref={yearRefs[index]}>
                          {year}
                        </button>
                      ))
                    : prevYears.map((year, index) => (
                        <button
                          key={year}
                          className={`dropdown-item py-1 ${
                            year === selectedYear && 'bg-mint-100 rounded-pill text-white'
                          }`}
                          type="button"
                          onClick={() => handleYearChange(year)}
                          ref={yearRefs[index]}>
                          {year}
                        </button>
                      ))}
                </div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center">
                <ButtonPrimary
                  shadow={false}
                  onClick={onSelectDate}
                  type="button"
                  label="Bestätigen"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && popUpPicker && (
        <div className="popup-date-picker d-flex justify-content-center align-items-start pt-4">
          <div className="bg-white p-4 rounded-4 position-relative">
            {/* <button
              type="button"
              className="btn close pb-2 position-absolute border-0"
              aria-label="Close"
              onClick={toggleOpen}>
              <Icons className="modal-close-icon" name="closecolor" height={24} width={24} />
            </button> */}
            <div className="input-group-datepicker-list date-picker">
              <div className="row date-picker-row h-100">
                <div className="col-4 text-center date-picker-day">
                  <div>
                    {days.map((day, index) => (
                      <button
                        key={day}
                        className={`dropdown-item py-1 ${
                          day === selectedDay && 'bg-mint-100 rounded-pill text-white'
                        }`}
                        type="button"
                        onClick={() => handleDayChange(day)}
                        ref={(el) => (dateRefs.current[index] = el)}>
                        {day}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="col-4 text-center date-picker-month">
                  <div>
                    {months.map((month, index) => (
                      <button
                        key={month}
                        className={`dropdown-item py-1 ${
                          index === selectedMonth && 'bg-mint-100 rounded-pill text-white'
                        }`}
                        type="button"
                        onClick={() => handleMonthChange(index)}
                        ref={(el) => (monthRefs.current[index] = el)}>
                        {month}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="col-4 text-center date-picker-year">
                  <div>
                    {isNextYears
                      ? nextYears.map((year, index) => (
                          <button
                            key={year}
                            className={`dropdown-item py-1 ${
                              year === selectedYear && 'bg-mint-100 rounded-pill text-white'
                            }`}
                            type="button"
                            onClick={() => handleYearChange(year)}
                            ref={(el) => (yearRefs.current[index] = el)}>
                            {year}
                          </button>
                        ))
                      : prevYears.map((year, index) => (
                          <button
                            key={year}
                            className={`dropdown-item py-1 ${
                              year === selectedYear && 'bg-mint-100 rounded-pill text-white'
                            }`}
                            type="button"
                            onClick={() => handleYearChange(year)}
                            ref={(el) => (yearRefs.current[index] = el)}>
                            {year}
                          </button>
                        ))}
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <ButtonPrimary
                    shadow={false}
                    onClick={onSelectDate}
                    type="button"
                    label="Bestätigen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {hint && !valid && !invalid && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification message={hint} />
        </div>
      )}
      {invalid && (!isOpen ? !isOpen : isTouched) && invalidMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
}

export default FormDatePicker;

// /* eslint-disable no-new */
// import React, { useEffect, useRef, useState } from 'react';

// const months = [
//   'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
//   'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
// ];

// const currentYear = new Date().getFullYear();
// const years = Array.from({ length: 10 }, (_, i) => currentYear - 5 + i);

// export function FormDatePicker() {

//   const [selectedDate, setSelectedDate] = useState({
//     month: months[new Date().getMonth()],
//     year: currentYear
//   });

//   const selectDate = (month, year) => {
//     setSelectedDate({ month, year });
//   };

//   return (
//     <div className="date-picker">
//       <div className="dates-list">
//         {months.map((month, index) => (
//           years.map((year) => (
//             <div
//               key={`${month}-${year}`}
//               className={`date-item ${selectedDate.month === month && selectedDate.year === year ? 'selected' : ''}`}
//               onClick={() => selectDate(month, year)}
//             >
//               <div className="month">{month}</div>
//               <div className="year">{year}</div>
//             </div>
//           ))
//         ))}
//       </div>
//     </div>
//   );
// }

// export default FormDatePicker;
