import React from 'react';
import PropTypes from 'prop-types';

export function CardBody({ sx, children, customClass }) {
  return (
    <div className={`card-body ${customClass}`} {...{ style: sx }}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string
};

CardBody.defaultProps = {
  sx: {},
  customClass: ''
};

export default CardBody;
