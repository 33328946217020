/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { appButtonType } from '@utils/globalConstant';

// @import './style.scss';

// TODO: Extend Buttons properties
// Type = Default = Contained, Outline, Text, Flat
// Icon = True | False,
// Icon Position = START | END,
// Default things to include
// Color, Disable, IconComponent, Size, sx<InlineStyle>

// export const PrimaryButton(props) {
//   return <button {...props} className={appButtonType.PRIMARY} >{isLoading ? <BSpinner size="sm" animation="border" role="status" /> : children}</button>
// }
// export const SecondaryButton(props) {
//   return <button {...props} className={appButtonType.SECONDARY} >{props.children}</button>
// }
// export const DangerButton(props) {
//   return <button {...props} className={appButtonType.DANGER} >{props.children}</button>
// }

export function Button({
  buttonClass = appButtonType.PRIMARY,
  isLoading,
  icon,
  isIcon,
  disabled,
  children,
  customClass,
  ...props
}) {
  let buttonComponent = <></>;

  const spinner = (
    <div style={{ width: '1rem', height: '1rem' }} className="spinner-border" role="status" />
  );

  switch (buttonClass) {
    case appButtonType.PRIMARY:
      buttonComponent = (
        <button
          disabled={disabled}
          {...props}
          className={`${appButtonType.PRIMARY} ${customClass}`}
        >
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.SECONDARY:
      buttonComponent = (
        <button
          disabled={disabled}
          {...props}
          className={`${appButtonType.SECONDARY} ${customClass}`}
        >
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.DANGER:
      buttonComponent = (
        <button disabled={disabled} {...props} className={`${appButtonType.DANGER} ${customClass}`}>
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.SUCCESS:
      buttonComponent = (
        <button
          disabled={disabled}
          {...props}
          className={`${appButtonType.SUCCESS} ${customClass}`}
        >
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.LINK:
      buttonComponent = (
        <button disabled={disabled} {...props} className={`${appButtonType.LINK} ${customClass}`}>
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    default:
      buttonComponent = (
        <button
          disabled={disabled}
          {...props}
          className={`${appButtonType.PRIMARY} ${customClass}`}
        >
          {isLoading ? spinner : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;
  }

  return buttonComponent;

  // return (

  //     <button {...props} disabled={disabled || isLoading}>
  //       {isLoading ? <BSpinner size="sm" animation="border" role="status" /> : children}
  //     </button>

  // );
}

export default Button;
