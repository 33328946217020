import React from 'react';
import Icon from '@core/Utils/Icons';
import './NavigationPageTitle.scss';

export function NavigationPageTitle({ title, isBackButton, onButtonClick, variant = 'lg' }) {
  return (
    <div className="navigate-title animate__animated animate__fadeInDown">
      {isBackButton && (
        <div className="icon-back mb-4 pb-4">
          <button
            type="button"
            className="border-0 rounded bg-transparent p-0"
            aria-label="back"
            onClick={onButtonClick}
          >
            <Icon className="modal-close-icon" name="backcolor" height={24} width={24} />
          </button>
        </div>
      )}
      <div className="title">
        {variant === 'lg' && <h1 className="m-0 nc-doomsday-h2 lg" dangerouslySetInnerHTML={{ __html: title }} />}
        {variant === 'sm' && <h3 className="m-0 nc-doomsday-h3 sm" dangerouslySetInnerHTML={{ __html: title }} />}
        {variant === 'xs' && <h4 className="m-0 nc-realheadpro-h4 xs" dangerouslySetInnerHTML={{ __html: title }} />}
      </div>
    </div>
  );
}

export default NavigationPageTitle;
