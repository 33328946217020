/* eslint-disable no-nested-ternary */
import { appBubbleTextOpenPosition, appBubbleTextType, appIcons } from '@utils/globalConstant';
import React from 'react';
import './BubbleText.scss';

export function BubbleText({
  text = '',
  cmsHtml,
  openPosition = appBubbleTextOpenPosition.BOTTOM, // 2 open position variants: top, bottom
  bgColorClass, //  3 bg variants: bg-white, bg-mint-60, bg-mint-80
  textColorClass // 2 text color variants: text-gray-100, white
  // type = appBubbleTextType.PRIMARY,
}) {
  const iconPosition =
    openPosition === appBubbleTextOpenPosition.BOTTOM ? 'bottom-0 start-50' : 'top-0 end-50';

  let bubbleIconVariant;
  if (openPosition === appBubbleTextOpenPosition.BOTTOM) {
    if (bgColorClass === 'bg-mint-80') {
      bubbleIconVariant = appIcons.bubbletopmint; // TODO change icon name in globalConstant // this is bottom icon

    }
    if (bgColorClass === 'bg-mint-60') {
      bubbleIconVariant = appIcons.bubblemint60bottom;
    }
    if (bgColorClass === 'bg-white') {
      bubbleIconVariant = appIcons.bubbletop; // TODO change icon name in globalConstant // this is bottom icon

    }
  } else if (openPosition === appBubbleTextOpenPosition.TOP) {
    if (bgColorClass === 'bg-mint-80') {
      bubbleIconVariant = appIcons.bubblemint;
    }
    if (bgColorClass === 'bg-mint-60') {
      bubbleIconVariant = appIcons.bubblemint60top;
    }
    if (bgColorClass === 'bg-white') {
      bubbleIconVariant = appIcons.bubble;
    }
  }

  return (
    <div className="py-4 text-center position-relative">
      <div className={`rounded-pill p-4 pt-5 ${bgColorClass} ${textColorClass} shadow-sm`}>
        <h4 className="nc-realtextpro-copy mb-0">{text}</h4>
        <h4
          className="bubble-text nc-realtextpro-copy mb-0"
          dangerouslySetInnerHTML={{ __html: cmsHtml }}
        />
        <div className={`bubble-icon position-absolute ${iconPosition}`}>
          <img src={bubbleIconVariant} alt="" srcSet="" />
        </div>
      </div>
    </div>
  );
}

/*   // const bubbleIcon = type === appBubbleTextType.PRIMARY ? appIcons.bubblemint : appIcons.bubblewhite;
  const iconPosition =
    openPosition === appBubbleTextOpenPosition.BOTTOM ? 'top-0 start-50' : 'bottom-0 end-50';
  return (
    // <div className="p-6 py-4 text-center position-relative">
    <div className="py-4 text-center position-relative">
      <div className={`rounded-pill p-4 pt-5 ${bgColor} ${textColor} shadow-sm`}>
        <h4 className="nc-realtextpro-copy mb-0">{text}</h4>
        <h4
          className="bubble-text nc-realtextpro-copy mb-0"
          dangerouslySetInnerHTML={{ __html: cmsHtml }}
        />
        <div className={`bubble-icon position-absolute ${iconPosition}`}>
          <img src={bubbleIcon} alt="" srcSet="" />
        </div>
      </div>
    </div>
  ); 
}*/

export default BubbleText;
