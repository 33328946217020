import React, { useState } from 'react';
import { appAlert } from '@utils/globalConstant';
import { Icons } from '../Utils';
import { InfoNotification } from '../InfoNotification';

import './FormVoucher.scss';

export function FormVoucher({
  id,
  type,
  label,
  value,
  startIcon,
  hint,
  invalid,
  invalidMessage,
  validMessage,
  valid,
  onBlur,
  onChange,
  ...restProps
}) {
  // State
  const [showPassword, setShowPassword] = useState(type !== 'password');
  const [inputType, setInputType] = useState(type);
  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  // Functions
  const viewPassword = () => {
    setInputType('text');
    setShowPassword(true);
  };

  const hidePassword = () => {
    setInputType('password');
    setShowPassword(false);
  };

  const triggerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setIsTouched(true);
  };

  const deleteCode = (val) => {
    if (val && val.length > 0) {
      const newVal = val.slice(0, -1);
      onChange({ target: { value: newVal } });
      setIsFocus(true);
    } else {
      setIsFocus(false);
    }
  };

  const triggerBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    // setIsFocus(false);

    setIsBlur(true);
    setIsFocus(false);
    setIsTouched(false);
  };

  // Hooks

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label ps-5 ms-1">
          {label}
        </label>
      )}
      <div
        className={`
        input-group input-group-lg
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}>
        {startIcon && (
          <span className="input-group-text pe-0 ps-6">
            <Icons name={startIcon} />
          </span>
        )}
        <input
          className={`px-6 form-control form-code
             
            ${isFocus ? 'focus' : ''} 
            ${isBlur ? 'blur' : ''} 
            ${isTouched ? 'touched' : ''}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...{ id, type: inputType, value, ...restProps }}
          onBlur={triggerBlur}
          onFocus={() => setIsFocus(true)}
          onChange={triggerChange}
        />{' '}
        {type === 'code' && (
          <span
            className="input-group-text ps-0 pe-6"
            role="button"
            aria-hidden
            onClick={() => deleteCode(value)}>
            <Icons name="deletemint" />
          </span>
        )}
        {type === 'password' && showPassword && (
          <span
            className="input-group-text ps-0 pe-6"
            role="button"
            aria-hidden
            onClick={hidePassword}>
            <Icons name="show" />
          </span>
        )}
        {type === 'password' && !showPassword && (
          <span
            className="input-group-text ps-0 pe-6"
            role="button"
            aria-hidden
            onClick={viewPassword}>
            <Icons name="hide" />
          </span>
        )}
      </div>
      {hint && !valid && !invalid && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification message={hint} />
        </div>
      )}
      {invalid && invalidMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text mt-6px ps-5">
          <InfoNotification type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
}

export default FormVoucher;
