import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useAlert } from '@context/Utils';
import {
  // DEFAULT_FIELD_VALIDATION_TYPE,
  StatusCodes
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { formValidation, appAlert } from '@utils/globalConstant';

export const AlphaVoucherContext = createContext();

export function AlphaVoucherProvider({ children }) {
  // Context
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const { onVoucherPaysafe, onVoucherTDM } = useMobileOne();
  const { showAlert, setIsGenericError } = useAlert();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isVoucherSuccess, setIsVoucherSuccess] = useState(false);
  const [voucherFailedError, setVoucherFailedError] = useState(''); // API Error: Failed to redeem voucher.
  const [voucherCodeForm, setVoucherCodeForm] = useState({ voucherCode: '' });

  // Validation
  const voucherValidations = Yup.object().shape({
    voucherCode: formValidation({
      required: t('nc_topup_voucher_input_code_err'),
      regex: /^[0-9]{13,16}$/,
      validErrorMessage: t('nc_topup_voucher_input_code_err')
    })
  });

  // Function
  const onLoad = () => {
    // TODO: Exten it with api call to fetch all voucher history.
    // console.log("Requesting");
  };

  const onSubmitTDM1 = async (code) => {
    try {
      const { data, success, status } = await onVoucherPaysafe(code);
      console.log({ data, success, status });
      if (success || status === StatusCodes.OK) {
        // showAlert({ type: appAlert.SUCCESS, message: 'Voucher redeemed successfull.' });
        setVoucherCodeForm('');
      }
    } catch (error) {
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
    }
  };

  const onSubmitTDM2 = async (values) => {
    try {
      // showLoader();
      setIsLoading(true);
      const { voucherCode } = values;
      const { data, success, status } = await onVoucherTDM(voucherCode);
      // console.log('{ data, success, status }----------------------', { data, success, status });
      if (data || success || status === StatusCodes.OK) {
        setIsVoucherSuccess(true);
        setVoucherFailedError('');
        setVoucherCodeForm('');
      }
      setIsLoading(false);
      // hideLoader();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setVoucherFailedError(error?.error[0]?.messageBody);

      setIsGenericError(true);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt') });
    }
  };


  // Hooks
  useEffect(() => {
    if (isUserLoggedIn) {
      onLoad();
    }
    return () => {
      // setHistory([]);
    };
  }, [isUserLoggedIn]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      voucherCodeForm,
      setVoucherCodeForm,
      isVoucherSuccess,
      setIsVoucherSuccess,
      voucherFailedError,
      setVoucherFailedError,

      // Validation
      voucherValidations,

      // Functions
      onLoad,
      // onSubmit,
      onSubmitTDM1,
      onSubmitTDM2
    }),
    [
      // States
      isLoading,
      setIsLoading,
      voucherCodeForm,
      setVoucherCodeForm,
      isVoucherSuccess,
      setIsVoucherSuccess,
      voucherFailedError,
      setVoucherFailedError,

      // Validation
      voucherValidations,

      // Functions
      onLoad,
      // onSubmit,
      onSubmitTDM1,
      onSubmitTDM2
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <AlphaVoucherContext.Provider value={contextPayload}>{children}</AlphaVoucherContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAlphaVoucher = () => useContext(AlphaVoucherContext);

export default AlphaVoucherProvider;
