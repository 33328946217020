/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { appAlert, appStorage, appTariffOptionSuccessType } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

export const TariffContext = createContext({});

export function TariffContextProvider({ children }) {
  // Context
  const { showAlert, setIsGenericError } = useAlert();
  const { staticContentData, onStaticContentCall, t } = useStaticContent();
  const { customerData, getCustomerData, customerProducts } = useCustomer();
  const { onActiveProductCall, onBookableTariffCall, onChangeTariffCall, onVviDocuments } =
    useMobileOne();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [allTariff, setAllTariff] = useState([]);
  const [activeTariff, setActiveTariff] = useState([]);
  const [bookableTariff, setBookableTariff] = useState([]);
  const [tariffOptionSuccess, setTariffOptionSuccess] = useState({ isSuccessful: false, type: '' });
  const [showTariffBanner, setShowTariffBanner] = useState(false);
  const [changeTariffName, setChangeTariffName] = useState('');
  const [vviDocuments, setVviDocuments] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState({});

  const [selectedAdditionalOption, setSelectedAdditionalOption] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedBookableOption, setSelectedBookableOption] = useState({});
  const [selectedSpeedOn, setSelectedSpeedOn] = useState({});

  const [tariffFailModal, setTariffFailModal] = useState('');
  const [tarifPdfModal, setTariffPdfModal] = useState(false);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Functions

  // eslint-disable-next-line consistent-return
  const staticTariffManipulation = (tariffApiData) => {
    if (
      staticContentData &&
      staticContentData.tariff_norma &&
      staticContentData.tariff_norma.length > 0
    ) {
      return staticContentData.tariff_norma.filter((staticContentTariffItem) => {
        const tariff = tariffApiData.find(
          (tariffItem) => tariffItem.id === staticContentTariffItem.id
        );
        if (tariff) {
          return tariff;
        }
        return false;
      });
    }
  };

  const tariffManipulationUnsort = (tariffApiData) => {
    const staticApiData = staticContentData;
    let filterArray = [];

    if (staticApiData != null) {
      if (staticApiData.tariff_norma != null && staticApiData.tariff_norma.length > 0) {
        const staticContentTariff = staticApiData.tariff_norma;
        const apiTariffData = tariffApiData;

        filterArray = staticContentTariff.filter((staticContentTariffItem) =>
          apiTariffData.some(
            (apiTariffDataitem) => staticContentTariffItem.id === apiTariffDataitem.id
          )
        );
        return filterArray;
      }
      return filterArray;
    }
    return filterArray;
  };

  // Manipulate the single tariff data.
  const staticTariffManipulate = (tariffApiData) => {
    const staticApiData = staticContentData;

    if (staticApiData != null) {
      if (staticApiData.tariff_norma != null && staticApiData.tariff_norma.length > 0) {
        const staticContentTariff = staticApiData.tariff_norma;
        const apiTariffData = tariffApiData;
        let filterArray = [];

        filterArray = staticContentTariff.find(
          (staticContentTariffItem) => staticContentTariffItem.id === apiTariffData.id
        );

        return filterArray;
      }
      return tariffApiData;
    }
    return tariffApiData;
  };

  const getStaticContentTariff = (tariffId) => {
    const { tariff_norma: tariffNorma = [] } = staticContentData;
    return tariffNorma.find((tariff) => tariff.id === tariffId);
  };

  // Get customer active tariff and storing it in state
  const getActiveProductCall = async () => {
    try {
      let activeTariffDetails = [];
      if (customerProducts && staticContentData && staticContentData.tariff_norma) {
        customerProducts.forEach((elem, i) => {
          let localTariff = elem;
          let tariffDetails = getStaticContentTariff(elem?.tariff?.id);
          localTariff.tariff = tariffDetails;
          activeTariffDetails.push(localTariff);
        });
        setActiveTariff(activeTariffDetails);
      }
      return customerProducts;
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return error;
    }
  };

  // Get customer bookable tariff and storing it in state
  const getBookableTariffCall = async () => {
    try {
      const { data } = await onBookableTariffCall();
      setBookableTariff(staticTariffManipulation(data));
      return data;
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      // }
      return error;
    }
  };

  // Function loads after we get customer data, and returns active and bookable tariff
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      // await getActiveProductCall();
      await getBookableTariffCall();
      setIsLoading(false);
      // hideLoader();
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      return error;
    }
  };

  // Find tariff status name to display in tariff product card.
  const getTariffStatusName = (tariffId) => {
    const tariff = activeTariff.find((trf) => trf.tariff.id.toString() === tariffId.toString());
    if (tariff) {
      return tariff.status.name;
    }
    return false;
  };

  // Switch Active Tariff
  const onSubmit = async ({ tariffId }) => {
    try {
      setIsLoading(true);
      // showLoader();
      const selectedTariffObj = bookableTariff.find(
        ({ id }) => parseInt(id, 10) === parseInt(tariffId, 10)
      );
      const { data, success, status } = await onChangeTariffCall({ tariffId });
      console.log({ data, success, status });
      if (success) {
        setTariffOptionSuccess({ isSuccessful: true, type: appTariffOptionSuccessType.TARIFF });
      } else {
        setIsLoading(false);
        // In case 200 & Success = false
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t('nc_generic_err_txt')
        });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return error;
    }
  };

  // Call this function to get VVI documents for tariff change page.
  const getVviDocuments = async (id) => {
    try {
      // setIsLoading(true);
      const { data, success } = await onVviDocuments({ tariffId: id, mnp: true });
      if (success) {
        setVviDocuments(data);
      }
      // setIsLoading(false);
      return false;
    } catch (error) {
      // console.log(error);
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return error;
    }
  };

  // Hooks
  useEffect(() => {
    const { msisdn, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn && staticContentData) {
      afterLoad();
    }
  }, [customerData, staticContentData]);

  useEffect(() => {
    if (bookableTariff && customerProducts) {
      const activeProduct = customerProducts.map(({ tariff }) => tariff).flat(1);
      const bookableProduct = bookableTariff;

      // Get unique bookable tariffs
      const bookableTariffs = bookableProduct.filter(
        (bookable) => !activeProduct.some((active) => active.id === bookable.id)
      );

      // Get unique active tariffs
      const allTariffs = [
        ...customerProducts.map(({ tariff }) => ({
          ...tariff,
          additionalInfo: tariff.additionalInfo || {
            primaryColor: '#fff',
            secondaryColor: '#fff',
            bullets: tariff.additionalInfo?.bullets || []
          }
        })),
        ...bookableTariffs.map((tariff) => ({
          ...tariff,
          additionalInfo: tariff.additionalInfo || {
            primaryColor: '#fff',
            secondaryColor: '#fff',
            bullets: tariff.bullets || []
          }
        }))
      ];

      // Get final unique active tariffs
      const finalAllTariff = allTariffs.filter(
        (tariff, index, self) => index === self.findIndex((ta) => ta.id === tariff.id)
      );
      setAllTariff(finalAllTariff);
    }

    return () => {
      setAllTariff([]);
    };
  }, [bookableTariff, customerProducts]);

  useEffect(() => {
    getActiveProductCall();
  }, [staticContentData, customerProducts]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      tariffOptionSuccess,
      setTariffOptionSuccess,
      showTariffBanner,
      changeTariffName,
      setChangeTariffName,
      vviDocuments,

      allTariff,
      setAllTariff,
      activeTariff,
      bookableTariff,
      termsValidations,
      selectedTariff,
      setSelectedTariff,
      selectedAdditionalOption,
      setSelectedAdditionalOption,
      selectedOption,
      setSelectedOption,
      selectedSpeedOn,
      setSelectedSpeedOn,
      tariffFailModal,
      setTariffFailModal,
      tarifPdfModal,
      setTariffPdfModal,
      selectedBookableOption,
      setSelectedBookableOption,

      // Functions
      getStaticContentTariff,
      getTariffStatusName,
      getVviDocuments,
      onSubmit,
      staticTariffManipulation,
      tariffManipulationUnsort,
      staticTariffManipulate
    }),
    [
      // States
      isLoading,
      setIsLoading,
      tariffOptionSuccess,
      setTariffOptionSuccess,
      showTariffBanner,
      changeTariffName,
      setChangeTariffName,
      vviDocuments,

      allTariff,
      setAllTariff,
      activeTariff,
      bookableTariff,
      termsValidations,
      selectedTariff,
      setSelectedTariff,
      selectedAdditionalOption,
      setSelectedAdditionalOption,
      selectedOption,
      setSelectedOption,
      selectedSpeedOn,
      setSelectedSpeedOn,
      tariffFailModal,
      setTariffFailModal,
      tarifPdfModal,
      setTariffPdfModal,
      selectedBookableOption,
      setSelectedBookableOption,

      // Functions
      getStaticContentTariff,
      getTariffStatusName,
      getVviDocuments,
      onSubmit,
      staticTariffManipulation,
      tariffManipulationUnsort,
      staticTariffManipulate
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <TariffContext.Provider value={contextPayload}>{children}</TariffContext.Provider>;
}
TariffContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useTariff = () => useContext(TariffContext);

export default TariffContext;
