import React, { useState } from 'react';

export function SemiCircleProgressBar({
  headlineText,
  headlineText2,
  subHeadLineText,
  noData,
  percentage,
  strokeWidth = 20,
  strokeColor
}) {
  const [isSafariBrowser, setIsSafariBrowser] = useState(
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );

  // SVG dimensions
  const width = 265;
  const height = 130;

  // Arc calculations
  const radius = (width - strokeWidth) / 2;
  const radiusOffset = strokeWidth / 1.5;

  const circumference = Math.PI * radius;
  const offset = ((100 - percentage) / 100) * circumference;

  // Semi-circle path
  const d = `M ${strokeWidth / 2}, ${height}
              A ${radius},${radius} 0 0 1 ${width - radiusOffset},${height}`;

  return (
    <svg
      width={width + radiusOffset}
      height={height + radiusOffset}
      viewBox={`0 0 ${width + radiusOffset} ${height + radiusOffset}`}>
      <g>
        <path
          d={d}
          fill="none"
          stroke="#eee"
          strokeOpacity="1"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d={d}
          fill="none"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeOpacity="0.7"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={offset + radiusOffset * 1.5}
          transform={`rotate(0 ${width / 2} ${height})`}
        />
        <text
          x={width / 2}
          y={height / 2 + (noData ? 30 : 45)}
          className={`${noData ? 'nc-doomsday-h4' : 'nc-doomsday-h1'} ${
            isSafariBrowser ? '' : 'animate__animated animate__slideInUp animate__delay-1s'
          }`}
          color={strokeColor}
          fill={strokeColor}
          textAnchor="middle"
          alignmentBaseline={'middle'}>
          <tspan x={width / 2}>{headlineText}</tspan>
          {noData && <tspan x={width / 2} dy="1em">{headlineText2}</tspan>}
        </text>
        <text
          x={width / 2}
          y={height}
          className={`nc-realheadpro-h4 text-gray-800  ${
            isSafariBrowser ? '' : 'animate__animated animate__slideInUp animate__delay-1s'
          }`}
          fill="#575c61"
          textAnchor="middle"
          alignmentBaseline={'middle'}>
          {`${subHeadLineText}`}
        </text>
      </g>
    </svg>
  );
  // // SVG dimensions
  // const width = 255;
  // const height = 127;

  // // Arc calculations
  // // const strokeWidth = 10;
  // const radius = (width - strokeWidth) / 2;
  // const circumference = Math.PI * radius;
  // const offset = ((50 - percentage) / 100) * circumference;

  // // Semi-circle path
  // const d = `M${strokeWidth / 2}, ${height} A${radius},${radius} 0 0 1 ${width - (strokeWidth / 2)},${height}`;

  // return (
  //   <svg width={width} height={height+30} viewBox={`0 0 ${width} ${height+30}`} fill="none">
  //     <path
  //       d={d}
  //       fill="none"
  //       stroke="#EAEBEC"
  //       strokeWidth={strokeWidth}
  //       strokeLinecap="round"
  //     />
  //     <path
  //       d={d}
  //       fill="none"
  //       strokeWidth={strokeWidth}
  //       strokeLinecap="round"
  //       strokeDasharray={circumference}
  //       strokeDashoffset={offset}
  //       stroke={strokeColor}
  //       transform={`rotate(-90 ${width / 2} ${height})`}
  //     />
  //     <text
  //       x={width / 2}
  //       y={height / 2}
  //       fontSize="20"
  //       fill="black"
  //       textAnchor="middle"
  //       alignmentBaseline="middle"
  //     >
  //       {text}
  //     </text>
  //   </svg>
  // );
}

export default SemiCircleProgressBar;
