import React, { useEffect, useRef, useState } from 'react';
import { appAlert } from '@utils/globalConstant';

import { InfoNotification } from '../InfoNotification';

import './FormCode.scss';

export function FormCode({
  id,
  label,
  startIcon,
  hint,
  invalid,
  invalidMessage,
  validMessage,
  valid,
  value,

  onBlur: onBlurProp,
  onChange,
  onKeyChange,
  ...restProps
}) {
  // State & refs
  const inputRefs = useRef([]);

  const [inputValue0, setInputValue0] = useState('');
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');

  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  // Functions
  // On bakspace key press move focus to previous input
  const triggerBackspace = (event, currentIndex) => {
    if (event.key === 'Backspace' && !event.target.value && currentIndex > 0) {
      inputRefs.current[currentIndex - 1]?.focus();
    }
  };

  // On change
  const triggerChange = (event, currentIndex) => {
    const { value } = event.target;
    const validateInput =
      `${value}`.length > 1 ? `${value}`.split('')[`${value}`.length - 1] : value;
    switch (currentIndex) {
      case 0:
        setInputValue0(validateInput);
        break;
      case 1:
        setInputValue1(validateInput);
        break;
      case 2:
        setInputValue2(validateInput);
        break;
      case 3:
        setInputValue3(validateInput);
        break;
    }
    setIsTouched(true);
    if (validateInput && currentIndex < 3) {
      inputRefs.current[currentIndex + 1]?.focus();
    }
    // setIsTouched(true);
  };

  const triggerBlur = (event) => {
    if (onBlurProp) {
      onBlurProp(event);
    }
    // setIsFocus(false);

    setIsBlur(true);
    setIsFocus(false);
    setIsTouched(false);
  };

  // Hooks
  useEffect(() => {
    onKeyChange && onKeyChange(`${inputValue0}${inputValue1}${inputValue2}${inputValue3}`);
  }, [inputValue0, inputValue1, inputValue2, inputValue3]);

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label text-center">
          {label}
        </label>
      )}
      <div className="d-flex align-item-center justify-content-between">
        <div
          className={`
        input-group input-group-lg input-group-code
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}
        >
          <input
            className={`form-control
             code-input-1
            ${isFocus ? 'focus' : ''} 
            ${isBlur ? 'blur' : ''} 
            ${isTouched ? 'touched' : ''}`}
            {...{ id, ...restProps }}
            min={0}
            type="number"
            max={9}
            maxLength={1}
            value={inputValue0}
            ref={(el) => (inputRefs.current[0] = el)}
            onBlur={triggerBlur}
            onFocus={() => setIsFocus(true)}
            onKeyDown={triggerBackspace}
            onChange={(event) => triggerChange(event, 0)}
          />
        </div>
        <div
          className={`
        input-group input-group-lg input-group-code
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}
        >
          <input
            className={`form-control
             code-input-2
            ${isFocus ? 'focus' : ''} 
            ${isBlur ? 'blur' : ''} 
            ${isTouched ? 'touched' : ''}`}
            {...{ id, ...restProps }}
            min={0}
            type="number"
            max={9}
            maxLength={1}
            value={inputValue1}
            ref={(el) => (inputRefs.current[1] = el)}
            onBlur={triggerBlur}
            onFocus={() => setIsFocus(true)}
            onKeyDown={(event) => triggerBackspace(event, 1)}
            onChange={(event) => triggerChange(event, 1)}
          />
        </div>
        <div
          className={`
        input-group input-group-lg input-group-code
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}
        >
          <input
            className={`form-control
             code-input-3
            ${isFocus ? 'focus' : ''} 
            ${isBlur ? 'blur' : ''} 
            ${isTouched ? 'touched' : ''}`}
            {...{ id, ...restProps }}
            min={0}
            type="number"
            max={9}
            maxLength={1}
            value={inputValue2}
            ref={(el) => (inputRefs.current[2] = el)}
            onBlur={triggerBlur}
            onFocus={() => setIsFocus(true)}
            onKeyDown={(event) => triggerBackspace(event, 2)}
            onChange={(event) => triggerChange(event, 2)}
          />
        </div>
        <div
          className={`
        input-group input-group-lg input-group-code
        ${valid ? 'valid' : ''} 
        ${invalid ? 'invalid animate__animated animate__shakeX' : ''} 
        ${isFocus ? 'focus' : ''} 
        ${isBlur ? 'blur' : ''} 
        ${isTouched ? 'touched' : ''}
      `}
        >
          <input
            className={`form-control
             code-input-4
            ${isFocus ? 'focus' : ''} 
            ${isBlur ? 'blur' : ''} 
            ${isTouched ? 'touched' : ''}`}
            {...{ id, ...restProps }}
            min={0}
            type="number"
            max={9}
            value={inputValue3}
            maxLength={1}
            ref={(el) => (inputRefs.current[3] = el)}
            onBlur={triggerBlur}
            onFocus={() => setIsFocus(true)}
            onKeyDown={(event) => triggerBackspace(event, 3)}
            onChange={(event) => triggerChange(event, 3)}
          />
        </div>
      </div>
      {hint && !valid && !invalid && (
        <div className="form-text text-center pt-4">
          <InfoNotification position="center" message={hint} />
        </div>
      )}
      {invalid && (
        <div className="form-text text-center pt-4">
          <InfoNotification position="center" type={appAlert.ERROR} message={invalidMessage} />
        </div>
      )}
      {valid && validMessage && (
        <div className="form-text text-center pt-4">
          <InfoNotification position="center" type={appAlert.SUCCESS} message={validMessage} />
        </div>
      )}
    </div>
  );
}

export default FormCode;
