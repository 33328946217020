/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
// import { useAlert } from '@context/Utils';
import { appPdfList, appTariffStatus, birthdayBonus } from '@utils/globalConstant';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';
import { ChangePasswordAlert } from '@part/ChangePasswordAlert';

export const CustomerContext = createContext({});

export function CustomerContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [personalData, setPersonalData] = useState({});
  const [customerProducts, setCustomerProducts] = useState([]);
  const [customerBalance, setCustomerBalance] = useState({});
  const [customerUsage, setCustomerUsage] = useState({ usage: { counters: [] } });
  const [tariffChangeActive, setTariffChangeActive] = useState(false); // eslint-disable-line no-unused-vars
  const [tariffChangeInProgress, setTariffChangeInProgress] = useState(); // TODO: remove this
  const [mediaPdfs, setMediaPdfs] = useState([]);
  const [productInfoPdfs, setProductInfoPdfs] = useState();
  const [termsInfoPdfs, setTermsInfoPdfs] = useState();
  const [privacyInfoPdfs, setPrivacyInfoPdfs] = useState();
  const [evnPdfs, setEvnPdfs] = useState();
  const [mnpPdfs, setMnpPdfs] = useState();
  const [explainerVideos, setExplainerVideos] = useState();
  const [mediaImages, setMediaImages] = useState([]);
  const [appPopupImage, setAppPopupImage] = useState();
  const [loginBgImage, setLoginBgImage] = useState({});
  const [dashboardModalImage, setDashboardModalImage] = useState({});
  const [birthdayBonusStatus, setBirthdayBonusStatus] = useState();
  const [isBirthdayBonus, setIsBirthdayBonus] = useState(true);
  const [screenSize, setScreenSize] = useState(
    window && window.innerWidth ? window.innerWidth : 1080
  );
  const [logoutPopupImage, setLogoutPopupImage] = useState({});
  const [evnData, setEvnData] = useState({});
  const [evnPdfData, setEvnPdfData] = useState([]);
  const [testVideo, setTestVideo] = useState({});
  const [isCounterAPIError, setIsCounterAPIError] = useState(false);

  // Context
  // const { showAlert } = useAlert();
  const { staticContentData, t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const {
    onDOMWithProductCall,
    onCustomerBalanceCall,
    onUsagesCall,
    onBirthdayBonusStatusCall,
    setDomWithProductData,
    onEvnCall,
    onEvnPdfCall
  } = useMobileOne();

  // Validations

  // Functions
  // Get customer data and storing it in individual state
  const getCustomerData = async () => {
    try {
      setIsLoading(true);
      const {
        data: {
          customerData: resCustomerData = {},
          customerData: { msisdn = false },
          personalData: resPersonalData = {},
          products: resProducts = [],
          tariff_change_active = false
        }
      } = await onDOMWithProductCall();
      if (resCustomerData && msisdn) {
        setCustomerData(resCustomerData);
        setPersonalData(resPersonalData);
        setCustomerProducts(resProducts);
        setTariffChangeActive(tariff_change_active);
      }

      return msisdn;
    } catch (error) {
      console.log(error);
      setIsCounterAPIError(true);
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  const onDOMWithProduct = async () => {
    try {
      setIsLoading(true);

      const { data, success } = await onDOMWithProductCall();
      if (data && success) {
        setDomWithProductData(data);
      }
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsCounterAPIError(true);
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  // Get customer balance and storing it in state
  const getCustomerBalance = async () => {
    try {
      const { data } = await onCustomerBalanceCall();
      if (data) setCustomerBalance(data);
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }

      return error;
    }
  };

  // Get customer usage and storing it in state
  const getCustomerUsage = async () => {
    try {
      const { data } = await onUsagesCall();
      // console.log('data', data);
      if (data) {
        setCustomerUsage(data);
        const {
          usage: { counters } = { usage: { counters: [] } },
          counters: { DATA, SMS, VOICE } = {}
        } = data;
        if (
          Object.keys(DATA).length > 0 ||
          Object.keys(SMS).length > 0 ||
          Object.keys(VOICE).length > 0
        ) {
          setIsCounterAPIError(false);
        } else {
          setIsCounterAPIError(true);
        }
      } else {
        setIsCounterAPIError(true);
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setIsCounterAPIError(true);
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  const getBirthBonusStatus = async () => {
    try {
      const { data, success } = await onBirthdayBonusStatusCall();
      if (data && success) {
        setBirthdayBonusStatus(data);
        setIsBirthdayBonus(data?.available !== birthdayBonus.AVAILABLE);
      }

      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  const getEvnPdfData = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onEvnPdfCall();
      if (success) {
        // console.log('evnPdfData----------', data);
        setEvnPdfData(data);
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  const getEvnData = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onEvnCall();
      if (success) {
        console.log('evnData----------', data);
        setEvnData(data);
        if (data && data.evnAllowed) {
          await getEvnPdfData();
        }
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return false;
    }
  };

  // when function loads will return customer data
  const onLoad = async () => {
    try {
      setIsLoading(true);
      await getCustomerData();
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      return error;
    }
  };

  // Function loads after we get customer data, and returns balance & usage
  const afterLoad = async () => {
    try {
      await getCustomerBalance();
      await getCustomerUsage();
      // await getBirthBonusStatus();
      // await getEvnPdfData();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getExplainerVideos = () => {
    if (staticContentData) {
      const videoIds = staticContentData.ek_explanationVideos
        ? staticContentData.ek_explanationVideos
        : [];
      const mediaVideo = staticContentData.media_video ? staticContentData.media_video : [];
      const imagePreview = staticContentData.media_image ? staticContentData.media_image : [];
      // eslint-disable-next-line prefer-const
      let finalVideos = [];

      videoIds.forEach((elem, i) => {
        const { image_ref } = elem;
        const videoPreviewId = image_ref;
        let videoPreviewImg;

        imagePreview.forEach((img, j) => {
          // eslint-disable-next-line eqeqeq
          if (videoPreviewId == img.image_ref) {
            videoPreviewImg = img;
          }
        });

        mediaVideo.forEach((vid, k) => {
          if (elem.id === vid.id) {
            finalVideos.push({ ...elem, ...vid, previewImage: videoPreviewImg });
          }
        });
      });

      setExplainerVideos(finalVideos);
    }
  };

  // To get EVN category PDF
  const getEvnPdf = () => {
    if (staticContentData) {
      const evnPdfids = staticContentData.evn ? staticContentData.evn : '';
      const allMediaPdfs = staticContentData.media_pdf ? staticContentData.media_pdf : '';
      const evnPdfsList = [];

      mediaPdfs.forEach((pdf, i) => {
        // eslint-disable-next-line eqeqeq
        if (evnPdfids.id == pdf.id) {
          evnPdfsList.push(pdf);
        }
      });

      setEvnPdfs(evnPdfsList[0]);
    }
  };

  // To get EVN category PDF
  const getMnpPdf = () => {
    if (staticContentData) {
      const mnpPdfids = staticContentData.ek_pdfMnp ? staticContentData.ek_pdfMnp : [];
      const allMediaPdfs = staticContentData.media_pdf ? staticContentData.media_pdf : [];
      const mnpPdfsList = [];

      mediaPdfs.forEach((pdf, i) => {
        // eslint-disable-next-line eqeqeq
        if (mnpPdfids.id == pdf.id) {
          mnpPdfsList.push(pdf);
        }
      });

      setMnpPdfs(mnpPdfsList[0]);
    }
  };

  // To get different category PDFs
  const getPdfs = () => {
    if (staticContentData) {
      const pdfsList = staticContentData.nr_pdfList ? staticContentData.nr_pdfList : [];
      const allMediaPdfs = staticContentData.media_pdf ? staticContentData.media_pdf : [];
      let productPdfs = [];
      let privacyPdfs = [];
      let termsPdfs = [];
      let productPdfIds = [];
      let termsPdfIds = [];
      let privacyPdfIds = [];

      // console.log('pdfsList', pdfsList);
      // console.log('allMediaPdfs', allMediaPdfs);

      pdfsList.forEach((elem, i) => {
        if (elem.listName === appPdfList.PRODUCT_SHEET) {
          elem.listContent.forEach((id, j) => {
            productPdfIds.push(id.id);
          });
        } else if (elem.listName === appPdfList.TERMS) {
          elem.listContent.forEach((id, j) => {
            termsPdfIds.push(id.id);
          });
        } else if (elem.listName === appPdfList.PRIVACY) {
          elem.listContent.forEach((id, j) => {
            privacyPdfIds.push(id.id);
          });
        }
      });

      allMediaPdfs.forEach((pdf, i) => {
        productPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            productPdfs.push(pdf);
          }
        });
        privacyPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            privacyPdfs.push(pdf);
          }
        });
        termsPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            termsPdfs.push(pdf);
          }
        });
      });

      setMediaPdfs(allMediaPdfs);
      setProductInfoPdfs(productPdfs);
      setTermsInfoPdfs(termsPdfs);
      setPrivacyInfoPdfs(privacyPdfs);
    }
  };

  const getMediaImgs = () => {
    if (staticContentData) {
      if (staticContentData.media_image) {
        const images = staticContentData.media_image ? staticContentData.media_image : '';
        if (images) {
          setMediaImages(images);
          const appImage = images.find((img) => img.image_ref === 'ek_web_popup_app_bg');
          const bgimg = images.find(({ image_ref }) => image_ref === 'ek_web_login_bg');
          const dashboardmodalimg = images.find(
            // ({ image_ref }) => image_ref === 'ek_web_popup_new_tariff_image'
            ({ image_ref }) => image_ref === 'ek_web_popup_new-tariff_content-1'
          );
          setLoginBgImage(bgimg);
          setAppPopupImage(appImage);
          setDashboardModalImage(dashboardmodalimg);
        }
      }
    }
  };

  const getLogoutPopupImage = () => {
    if (staticContentData) {
      const images = staticContentData.media_image ? staticContentData.media_image : [];
      const logoutImage = images.find((img) => img.image_ref === 'nc-img-modal-woman');

      setLogoutPopupImage(logoutImage);
    }
    // console.log('logoutPopupImage', logoutPopupImage);
  };

  const getVideoPreview = (imageRef) => {
    let videoPreview = '';
    if (staticContentData) {
      if (staticContentData.media_image) {
        const images = staticContentData.media_image;
        const videoImg = images.find((img) => img.image_ref === imageRef);
        videoPreview = videoImg?.media_url_display;
      }
    }
    return videoPreview;
  };

  const downloadDocPDF = (link) => {
    window.location.href = link;
  };

  const getTestVideo = () => {
    if (staticContentData && staticContentData.media_video) {
      const videos = staticContentData.media_video;
      const video = videos.find(({ name }) => name === 'test_video_for_player_component');
      setTestVideo(video || {});
    }
  };

  // Hooks
  // when user logged in get customer data
  useEffect(() => {
    if (isUserLoggedIn) {
      onLoad();
    }
  }, [isUserLoggedIn]);

  // auth functionality
  // useEffect(() => {
  //   if (isUserLogoutProcessing && isUserLoggedIn) {
  //     onLogout();
  //   }
  // }, [isUserLogoutProcessing, isUserLoggedIn]);

  // to check the customerBalance and usaCustcustomerUmerge
  useEffect(() => {
    const { msisdn = false, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn) {
      afterLoad();
    }
  }, [customerData]);

  // To find tariff change in progress
  useEffect(() => {
    if (customerProducts.length > 0) {
      const pendingTariffStatus = customerProducts.find(
        ({ status }) =>
          status.id === appTariffStatus.IN_CHANGE ||
          status.id === appTariffStatus.ACTIVATION_PENDING ||
          status.id === appTariffStatus.ACTIVATION_REQUESTED
      );
      if (pendingTariffStatus) {
        setTariffChangeInProgress(true);
      }
    }
    // Clean up
    return () => {
      setTariffChangeInProgress(false);
    };
  }, [customerProducts]);

  useEffect(() => {
    if (staticContentData) {
      getMnpPdf();
      getPdfs();
      getMediaImgs();
      getLogoutPopupImage();
    }
  }, [staticContentData]);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', () => {
        setScreenSize(window.innerWidth);
      });
    }
  }, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      customerData,
      personalData,
      customerProducts,
      customerBalance,
      customerUsage,
      tariffChangeInProgress,
      productInfoPdfs,
      termsInfoPdfs,
      privacyInfoPdfs,
      evnPdfs,
      screenSize,
      setEvnPdfs,
      explainerVideos,
      mnpPdfs,
      setMnpPdfs,
      mediaImages,
      appPopupImage,
      loginBgImage,
      dashboardModalImage,
      logoutPopupImage,
      birthdayBonusStatus,
      isBirthdayBonus,
      evnData,
      evnPdfData,
      testVideo,
      setTestVideo,
      mediaPdfs,
      setMediaPdfs,
      tariffChangeActive,
      setTariffChangeActive,
      isCounterAPIError,
      setIsCounterAPIError,

      // API Calls
      getCustomerData,
      getCustomerUsage,
      onDOMWithProduct,
      getPdfs,
      getEvnPdf,
      getExplainerVideos,
      getVideoPreview,
      downloadDocPDF,
      getEvnData,
      getEvnPdfData,
      getTestVideo
    }),
    [
      // States
      isLoading,
      setIsLoading,
      customerData,
      personalData,
      customerProducts,
      customerBalance,
      customerUsage,
      tariffChangeInProgress,
      productInfoPdfs,
      termsInfoPdfs,
      privacyInfoPdfs,
      evnPdfs,
      screenSize,
      setEvnPdfs,
      explainerVideos,
      mnpPdfs,
      setMnpPdfs,
      mediaImages,
      appPopupImage,
      loginBgImage,
      dashboardModalImage,
      logoutPopupImage,
      birthdayBonusStatus,
      isBirthdayBonus,
      evnData,
      evnPdfData,
      testVideo,
      setTestVideo,
      mediaPdfs,
      setMediaPdfs,
      tariffChangeActive,
      setTariffChangeActive,
      isCounterAPIError,
      setIsCounterAPIError,

      // API Calls
      getCustomerData,
      getCustomerUsage,
      onDOMWithProduct,
      getPdfs,
      getEvnPdf,
      getExplainerVideos,
      getVideoPreview,
      downloadDocPDF,
      getEvnData,
      getEvnPdfData,
      getTestVideo
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <CustomerContext.Provider value={contextPayload}>
      {Object.keys(customerData).length > 0 && customerData.forcePasswordReset && (
        <ChangePasswordAlert open={customerData.forcePasswordReset} />
      )}

      {children}
    </CustomerContext.Provider>
  );
}

CustomerContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useCustomer = () => useContext(CustomerContext);

export default CustomerContext;
