/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import Icon from '@core/Utils/Icons';
import './ButtonMisc.scss';
import { appButtonTypes } from '@utils/globalConstant';

export function ButtonMisc({
  icon,
  label,
  type = appButtonTypes.SECONDARY.DEFAULT,
  shadow = false,
  onClick: onClickProp,
  ...restProps
}) {
  // State & Refs
  const btnRef = useRef(null);

  // Functions
  const onClick = (event) => {
    if (onClickProp) {
      onClickProp(event);
    }
    // Add animation class to button
    btnRef.current.classList.add('animate__animated', 'animate__headShake', 'animate__slow');
    // Remove animation class after animation ends
    btnRef.current.addEventListener('animationend', () => {
      btnRef.current.classList.remove('animate__animated', 'animate__headShake');
    });
  };
  return (
    <button
      type="button"
      className={`${type} ${shadow ? 'shadow' : ''}`}
      ref={btnRef}
      onClick={onClick}
      {...restProps}
    >
      {icon && <Icon className="btn-icon" name={icon} height={25} width={25} />}
      {label && <div className="btn-label ps-1">{label}</div>}
    </button>
  );
}

export default ButtonMisc;
