import React from 'react';
import { Icons } from '@core/Utils/Icons';
import './Counter.scss';

export function Counter({
  id,
  containerLabel,
  customClass = '',
  customConitainerClass = '',
  value,
  label,
  type,
  isDisabled,
  onClickMinus: onClickMinusProp,
  onClickPlus: onClickPlusProp,
  onSelect
}) {
  // console.log('Counter', value);
  // Functions
  const onClickMinus = () => {
    if (onClickMinusProp) {
      onClickMinusProp(Number(value) - 1);
    }
  };

  const onClickPlus = () => {
    if (onClickMinusProp) {
      onClickPlusProp(Number(value) + 1);
    }
  };

  return (
    <div className={`${customConitainerClass}`}>
      <div>
        {containerLabel && <span className="container-label pb-1">{containerLabel}</span>}
      </div>
      <div
        className={`w-100 d-inline-flex justify-content-between plus-counter ${type}${containerLabel ? " mt-1" : ""} ${customClass}`}
        aria-hidden
        onClick={() => {
          if (onSelect) {
            onSelect(id)
          }
        }}
      >
        <button
          type="button"
          className="border-0 bg-transparent p-0"
          onClick={onClickMinus}
          label="minus"
          disabled={isDisabled}
        >
          <Icons name="minusmint" width={24} height={24} />
        </button>
        {label && <div className="counter-label nc-doomsday-h3 text-center py-3">{label}</div>}
        <button
          className="border-0 bg-transparent p-0"
          type="button"
          onClick={onClickPlus}
          label="plus"
          disabled={isDisabled}
        >
          <Icons name="plusmint" width={24} height={24} />
        </button>
      </div>
    </div>
  );
}

export default Counter;
