/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import React, { useState } from 'react';

import './TarifTabsNavigation.scss';
import { BubbleTabs } from '../BubbleTabs/BubbleTabs';

function ActiveTab({ index, bubbleColor = '#33B6AE', text, ...props }) {
  let justifyContent;
  if (index === 0) {
    justifyContent = 'start';
  } else if (index === 2) {
    justifyContent = 'end';
  } else {
    justifyContent = 'center';
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-${justifyContent} animate__animated animate__flipInY`}
      style={{ scale: 1.25 }}>
      <div className="position-absolute">
        <BubbleTabs text={text} bubbleColor={bubbleColor} index={index} />
      </div>
    </div>
  );
}

function Tab({ index, onClick, text }) {
  let justifyContent;
  if (index === 0) {
    justifyContent = 'start';
  } else if (index === 2) {
    justifyContent = 'end';
  } else {
    justifyContent = 'center';
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-${justifyContent} px-5 pb-1`}
      onClick={onClick}
      role="button">
      <div className="">
        <p className="p-0 m-0 ps-1 pt-1 nc-doomsday-copy text-gray-100">{text}</p>
      </div>
    </div>
  );
}

export function TarifTabsNavigation({ defaultActive = 0, bubbleColor, onChange }) {
  const { t } = useStaticContent();

  const [active, setActive] = useState(defaultActive);

  const handleClick = (i) => {
    setActive(i);
    if (onChange) {
      onChange(i);
    }
  };

  return (
    <div className="px-3">
      <div className="row bg-gray-10 py-1 rounded-pill shadow-xs">
        <div className="col-4 p-0 align-self-center justify-self-start">
          {active === 0 ? (
            <ActiveTab index={0} bubbleColor={bubbleColor} text={t('nc_tarif_tab_tarif')} />
          ) : (
            <Tab index={0} onClick={() => handleClick(0)} text={t('nc_tarif_tab_tarif')} />
          )}
        </div>
        <div className="col-4 p-0 align-self-center justify-self-center">
          {active === 1 ? (
            <ActiveTab index={1} bubbleColor={bubbleColor} text={t('nc_tarif_tab_options')} />
          ) : (
            <Tab index={1} onClick={() => handleClick(1)} text={t('nc_tarif_tab_options')} />
          )}
        </div>
        <div className="col-4 p-0 align-self-center justify-self-center">
          {active === 2 ? (
            <ActiveTab index={2} bubbleColor={bubbleColor} text={t('nc_tarif_tab_abroad')} />
          ) : (
            <Tab index={2} onClick={() => handleClick(2)} text={t('nc_tarif_tab_abroad')} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TarifTabsNavigation;
