import React from 'react';
import Lottie from 'react-lottie';
import IconLoader from '@assets/icons/nc-loading-darkgreen.json';

export function LoadingScreen({ cmsHtml, text }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: IconLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="container-md container-page1">
      <div className="row">
        <div className="mx-auto text-center col-xs-12 col-sm-7 col-md-8 col-lg-4 col-xl-4 col-xxl-3 px-6 px-sm-0 px-md-3 px-xxl-13">
          <p className="nc-doomsday-h3 mb-0" dangerouslySetInnerHTML={{ __html: cmsHtml }} />
          <p className="nc-doomsday-h3 mb-0">{text}</p>
          <div className="pt-12">
            <Lottie options={defaultOptions} width={57} height={57} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DashboardLoadingScreen({ cmsHtml }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: IconLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="container-md container-page1">
      <div className="row">
        <div className="mx-auto text-center col-xs-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 px-lg-4 px-xxl-10">
          <p
            className="nc-doomsday-h2 mb-0"
            dangerouslySetInnerHTML={{ __html: cmsHtml }}
          />
          {/* <div className="pt-12">
            <Lottie options={defaultOptions} width={57} height={57} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
