import React from 'react';
import PropTypes from 'prop-types';

import BootstrapCard from 'react-bootstrap/Card';

export function CardImgOverlay({ sx, children }) {
  return <BootstrapCard.ImgOverlay {...{ style: sx }}>{children}</BootstrapCard.ImgOverlay>;
}

CardImgOverlay.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardImgOverlay.defaultProps = {
  sx: {}
};

export default CardImgOverlay;
