import React from 'react';
import { appAlert, appIcons } from '@utils/globalConstant';
import Icons from '@core/Utils/Icons';
import './InfoNotification.scss';

export const InfoNotification = ({ type, message, position = 'start' }) => {
  const icon =
    type === appAlert.ERROR || type === appAlert.DANGER
      ? 'error'
      : type === appAlert.SUCCESS
      ? 'success'
      : type === appAlert.DISABLED
      ? 'success'
      : type === appAlert.WARNING
      ? 'warning'
      : type === appAlert.INFO
      ? 'info'
      : false;

  const textColor =
    type === appAlert.ERROR || type === appAlert.DANGER
      ? 'text-red'
      : type === appAlert.SUCCESS
      ? 'text-success'
      : type === appAlert.WARNING
      ? 'text-warning'
      : type === appAlert.DISABLED
      ? 'text-gray-80'
      : type === appAlert.INFO
      ? 'text-info'
      : 'text-gray-80';
  return (
    <div>
      <div
        className={`d-flex flex-col align-items-center 
        ${position === 'start' && 'justify-content-start'} 
        ${position === 'center' && 'justify-content-center'}`}
      >
        {icon && (
          <div className="info-icon">
            <Icons
              className="d-flex flex-col align-items-center justify-content-start py-0 m-0 me-2 "
              name={icon}
              width={14}
              height={14}
            />
          </div>
        )}
        <div className={`info-text-body ${textColor} py-0 m-0 text-start`}>{message}</div>
      </div>
    </div>
  );
};

export default InfoNotification;
