import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

// TODO: Extend Select properties
// Type = Default = Single Select, MultiSelect, DropDown,
// Icon = True | False,
// Icon Position = START | END,
// Default things to include
// List | Items, Disable, IconComponent, Size, sx<InlineStyle>

export function Select({
  label,
  name,
  options,
  value,
  getOptionId,
  getOptionName,
  onChange,
  onBlur,
  isInvalid,
  error,
  isDisabled,
  defaultOption,
  wantValue
}) {
  const getId = (option) => option.id || getOptionId(option);
  const getName = (option) => option.name || getOptionName(option);
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        aria-label="Default select example"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        name={name}
        disabled={isDisabled}
      >
        <option value="">{defaultOption}</option>
        {options.map((item) => (
          <option key={getId(item)} value={wantValue ? item.value : getId(item)}>
            {getName(item)}
          </option>
        ))}
      </Form.Select>
      {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </>
  );
}
Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getOptionId: PropTypes.func,
  getOptionName: PropTypes.func,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func
};
Select.defaultProps = {
  label: '',
  value: '',
  name: '',
  getOptionId: (option) => option.id,
  getOptionName: (option) => option.name,
  onChange: () => {},
  isInvalid: null,
  onBlur: () => {}
};
export default Select;
