import React from 'react';
import Icon from '@core/Utils/Icons';
import './Badge.scss';

export function Badge({ title, shadow = false }) {
  return (
    <div
      className={`badge rounded-pill bg-mint-100 d-inline-flex align-items-center ${
        shadow ? 'shadow' : ''
      }`}
    >
      <Icon name="like" width={18} height={18} />
      <span className="title nc-doomsday-medium ms-1">{title}</span>
    </div>
  );
}

export default Badge;
