import React from 'react';
import PropTypes from 'prop-types';

import BootstrapCard from 'react-bootstrap/Card';

export function CardText({ sx, children }) {
  return <BootstrapCard.Text {...{ style: sx }}>{children}</BootstrapCard.Text>;
}

CardText.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node
};

CardText.defaultProps = {
  sx: {},
  children: ''
};

export default CardText;
