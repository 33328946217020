import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@core/Utils/Tooltip';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import { InputFeedback } from './InputFeedback';
import './style.scss';

export function TextArea({
  label,
  labelCustomClass,
  inputCustomClass,
  toolTip,
  id,
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  isValid,
  isInvalid,
  isDisabled,
  error,
  isErrorIcon,
  help,
  maxLength,
  rows,
  cols,
  // iconDivClass,
  validMsg
}) {
  // State

  const inptutControl = (
    <div className="mb-2 input-mb">
      {label && (
        <label htmlFor={id} className={`form-label ${labelCustomClass}`}>
          {label}{' '}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
            </Tooltip>
          ) : (
            <></>
          )}{' '}
        </label>
      )}
      <textarea
        className={`form-control ${isInvalid ? ' is-invalid' : ''} ${
          isValid ? ' is-valid' : ''
        } ${inputCustomClass}`}
        aria-describedby={`${id}Block`}
        {...{
          name,
          placeholder,
          onChange,
          value: value || '',
          isValid,
          isInvalid,
          onBlur,
          maxLength,
          rows,
          cols
        }}
        disabled={isDisabled}
      />
      {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}
      {error && (
        <InputFeedback id={id} isErrorIcon={isErrorIcon} isInvalid feedBackMessage={error} />
      )}
      {isValid && <InputFeedback isValid feedBackMessage={validMsg} />}
    </div>
  );
  return inptutControl;
}

TextArea.propTypes = {
  label: PropTypes.string,
  labelCustomClass: PropTypes.string,
  inputCustomClass: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  validMsg: PropTypes.string,
  isErrorIcon: PropTypes.bool,
  help: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string
};

TextArea.defaultProps = {
  label: '',
  labelCustomClass: '',
  inputCustomClass: '',
  id: '',
  placeholder: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  maxLength: '',
  isValid: null,
  isInvalid: null,
  isDisabled: null,
  error: '',
  validMsg: '',
  isErrorIcon: false,
  help: '',
  rows: '',
  cols: ''
};

export default TextArea;
