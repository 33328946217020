import React from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationActivationBar } from '@part/NavigationActivationBar';
import { NavigationFooter } from '@part/NavigationFooter';
import { useLayout } from '@context/Utils';
import { LoadingScreen } from '@core/LoadingScreen';
import { Alert } from '@core/Utils';

export function ActivationLayout() {
  const { isLoading } = useLayout();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-mint-40 min-vh-100 d-flex flex-column">
      <NavigationActivationBar />
      <main className="flex-grow-1">
        <Alert />
        <Outlet />
      </main>

      <NavigationFooter />
    </div>
  );
}

export default ActivationLayout;
