/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';
// import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import {
  appAlert,
  appOptionIds,
  appTariffOptionSuccessType,
  appUsageMeasurementsUnits
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

import { useTariff } from '@context/MobileOne/TariffOption/Tariff';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

// import { useAlert, useLoader } from '@context/Utils';
// import { appAlert } from '@utils/globalConstant';

export const OptionContext = createContext({});

export function OptionContextProvider({ children }) {
  // Context
  const { t, staticContentData } = useStaticContent();
  const { showAlert, setIsGenericError } = useAlert();
  const { getCustomerData, customerBalance, customerUsage } = useCustomer();

  // const { showLoader, hideLoader } = useLoader();
  const { onAllOptions, onBookableOptions, onBookedOptions, onBookOption } = useMobileOne();
  const { setTariffFailModal, setTariffOptionSuccess } = useTariff();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [bookOptionSuccess, setBookOptionSuccess] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [bookableOptions, setBookableOptions] = useState([]);
  const [bookedOptions, setBookedOptions] = useState([]);
  const [bookedOptionsWithCounters, setBookedOptionsWithCounters] = useState([]);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Functions

  const onOptionfilteredData = (data) => {
    let finalFiltered = [];
    let comingData = [...data];

    for (let [index, value] of comingData.entries()) {
      // Main Array for Whole Data

      let currentGroupID = value?.group?.id;

      if (currentGroupID) {
        // Checking conditions for avilablibily for Group ID

        if (
          finalFiltered.length > 0 &&
          !finalFiltered.find((elem) => {
            return elem.id == value?.group?.id;
          })
        ) {
          let NewData = comingData.filter((elem, index) => {
            return value.group.id === elem.group.id;
          });

          finalFiltered[finalFiltered.length] = {
            ...NewData[0].group,
            ListData: NewData
          };
        } else if (finalFiltered.length === 0) {
          let NewData = comingData.filter((elem, index) => {
            return value.group.id === elem.group.id;
          });

          finalFiltered[finalFiltered.length] = {
            ...NewData[0].group,
            ListData: NewData
          };
        }
      }
    }
    return finalFiltered;
  };

  // Get all options and storing it in state
  const getAllOptions = async () => {
    try {
      const { data } = await onAllOptions();
      // if (data) setAllOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      setIsLoading(false);
      return error;
    }
  };

  // Get bookable options and storing it in state
  const getBookableOptions = async () => {
    let bookableData = [];
    try {
      // showLoader();
      const { data } = await onBookableOptions();
      if (data) {
        setBookableOptions(data);
        bookableData = onOptionfilteredData(data);
        // console.log('bookableData---1234', bookableData);
        for (let elem of bookableData) {
          for (let elem2 of elem.ListData) {
            if (elem2.price < customerBalance.totalBalance) {
              elem2.hasLowBalance = false;
            } else {
              elem2.hasLowBalance = true;
            }
          }
        }
      }
      setAllOptions(bookableData);
      // hideLoader();
      return data;
    } catch (error) {
      console.log(error);
      // hideLoader();
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  // Get booked options and storing it in state
  const getBookedOptions = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data } = await onBookedOptions();
      if (data) {
        const { etcOptions = [] } = staticContentData?.nr_etcOptionSettings || appOptionIds;
        const bookedOptionsData = data.filter((item) => item && etcOptions.includes(item.id));
        setBookedOptions(bookedOptionsData);
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t('nc_generic_err_txt') });
      }
      return error;
    }
  };

  // Function loads after we get customer data, and returns all options, bookable and booked options
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      await getAllOptions();
      await getBookableOptions();
      await getBookedOptions();
      // hideLoader();
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      // hideLoader();
      setIsLoading(false);
      return error;
    }
  };

  // Book an option if possible
  const bookAnOption = async (optionId) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, status, success } = await onBookOption(optionId);
      console.log({ data, success, status });
      if (success) {
        setTariffOptionSuccess({ isSuccessful: true, type: appTariffOptionSuccessType.OPTION });
      } else {
        setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t('nc_generic_err_txt')
        });
      }
      await getCustomerData();
      setIsLoading(false);
      // hideLoader();
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });

      return error;
    }
  };

  const isCounterOrFreeUnitsAvailable = (option) => {
    const {
      usage: { counters: allCounters = [], freeUnits = [] }
    } = customerUsage;

    const findCountesrAvailable = allCounters.filter(
      (item) => item && option && item.productId === option.id
    );
    const findFreeUnitsAvailable = freeUnits.filter(
      (item) => item && option && item.productId === option.id
    );

    return findCountesrAvailable.length > 0 || findFreeUnitsAvailable.length > 0;
  };

  const findCounters = (option) => {
    const {
      usage: { counters: allCounters = [], freeUnits = [] }
    } = customerUsage;

    const findCountesrAvailable = allCounters.filter(
      (item) => item && option && item.productId === option.id
    );

    const { additionalInfo } = staticContentData?.nr_etcOptionSettings || appOptionIds;

    const findFreeUnitsAvailable = freeUnits
      .filter((item) => item && option && item.productId === option.id)
      .map((item) => ({
        ...item,
        priority: item.unit === appUsageMeasurementsUnits.Minuten ? 1 : 0
      }));

    const allCountersList = [...findCountesrAvailable, ...findFreeUnitsAvailable];

    return allCountersList.map((item) => ({
      ...item,
      ...option,
      flatrate: additionalInfo[option.id]?.flatrate || false
    }));
  };

  // Hooks
  // to check the all options, bookable and booked options
  useEffect(() => {
    const totalBalance = customerBalance.totalBalance ? customerBalance.totalBalance : null;
    if (totalBalance !== null) {
      afterLoad();
    }
  }, [customerBalance]);

  useEffect(() => {
    let allBookedOptions = [];

    if (bookedOptions && bookedOptions.length > 0) {
      bookedOptions.forEach((option, index) => {
        const { etcOptions = [], additionalInfo } =
          staticContentData?.nr_etcOptionSettings || appOptionIds;
        if (etcOptions && etcOptions.length > 0 && etcOptions.includes(option.id)) {
          if (isCounterOrFreeUnitsAvailable(option)) {
            const findCountersListData = findCounters(option);
            allBookedOptions = [
              ...allBookedOptions,
              ...findCountersListData.map((item) => ({
                ...item,
                flatrate: additionalInfo[option.id]?.flatrate,
                name: additionalInfo[option.id]?.additionalTitle
                  ? `${option.name} ${additionalInfo[option.id]?.additionalTitle}`
                  : option.name
              }))
            ];
          } else {
            allBookedOptions = [
              ...allBookedOptions,
              {
                ...option,
                flatrate: additionalInfo[option.id]?.flatrate,
                name: additionalInfo[option.id]?.additionalTitle
                  ? `${option.name} ${additionalInfo[option.id]?.additionalTitle}`
                  : option.name
              }
            ];
          }
        }
      });
    }

    // console.log('allBookedOptions', allBookedOptions);
    // nr_noDisplayInTariffCard
    // nr_etcOptionSettings
    setBookedOptionsWithCounters(
      allBookedOptions.sort((a, b) => {
        return (b?.priority || 0) - (a?.priority || 0);
      })
    );
  }, [staticContentData, customerUsage, bookedOptions]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,
      bookOptionSuccess,
      setBookOptionSuccess,
      bookedOptionsWithCounters,
      setBookedOptionsWithCounters,

      bookAnOption,
      termsValidations,
      getAllOptions,
      getBookableOptions,
      getBookedOptions
    }),
    [
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,
      bookOptionSuccess,
      setBookOptionSuccess,
      bookedOptionsWithCounters,
      setBookedOptionsWithCounters,

      bookAnOption,
      termsValidations,
      getAllOptions,
      getBookableOptions,
      getBookedOptions
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <OptionContext.Provider value={contextPayload}>{children}</OptionContext.Provider>;
}
OptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOption = () => useContext(OptionContext);

export default OptionContext;
